.vacancy-list {
    $this: '.vacancy-list';

    &__title {
        @include alternate-default-font;
        @include font-size($font-size-xxlarge);
        margin-bottom: spacing(12);
    }
    
    &__vacancies {
        display: grid;
        column-gap: $column-gutter-default * 2;
        row-gap: spacing(24);

        @include respond-from($site__desktop-breakpoint) {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__vacancy {
        border-left: 1px solid $colour-grey-xdark;
        align-items: stretch;
        display: flex;
        flex-direction: column;
        padding-left: spacing(2);
        padding-right: spacing(5);
        
        &__title {                
            @include alternate-default-font;
            @include font-size($font-size-large);
        }
        
        &__location {                
            @include light-font;
            @include font-size($font-size-large);
            color: $colour-grey-xdark;
            line-height: 1;
        }
        
        &__body-text {
            @include light-font;
            @include font-size($font-size-small);

            p {
                margin: calc(1em * var(--line-height)) 0;
                max-width: size(760px);
            }
        }
        
        &__document-link {
            @include o-button--ghost-black($defaultTypography: false);
            align-items: center;

            &__icon {
                height: size(20px);
                margin-right: 1ch;
                width: size(20px);
            }
        }

        &__footer {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-top: auto;
            padding-top: spacing(24);
        }
        
        &__apply-link {
            @include o-button--ghost-default($defaultTypography: false);
            @include alternate-default-font;
            @include font-size($font-size-small);
        }            

        &__triangle {
            display: block;
            fill: $colour-grey-xdark;
            height: size(10px);
            margin-left: auto;
            width: size(20px);
        }
    }
    
    &__no-vacancies {
        
        &__message {
            @include alternate-default-font;
            @include font-size($font-size-large);
            max-width: 60ch;
        }

        &__apply-link {
            @include o-button--ghost-default($defaultTypography: false);
            @include alternate-default-font;
            @include font-size($font-size-medium);
            margin-top: spacing(10);
        }
    }

    .dialog-backdrop {
        @include dialog__backdrop;
    }

    &--theme {
        &-white {
            color: $colour-black;

            #{$this} {
                &__vacancy__document-link {
                    @include o-button--ghost-black($defaultTypography: false);
                }
            }
        }
        &-black {
            color: $colour-white;

            #{$this} {
                &__vacancy__document-link {
                    @include o-button--ghost-white($defaultTypography: false);
                }
            }
        }
    }

    .dialog-backdrop {
        @include dialog__backdrop;
    }
    
    &__modal {
        display: none;
        width: 100%;

        .active & {
            display: flex;
            justify-content: center;
        }
    }
}