.grid-image {
    $this: '.grid-image';
    display: block;
    position: relative;

    &__inner {
        width: 100%;

        @include backend-specific {
            background-color: #fefefe;
            background-image: linear-gradient(45deg, #bbbbbb 25%, transparent 25%, transparent 75%, #bbbbbb 75%, #bbbbbb),
                linear-gradient(45deg, #bbbbbb 25%, transparent 25%, transparent 75%, #bbbbbb 75%, #bbbbbb);
            background-size: 20px 20px;
            background-position: 0 0, 10px 10px;
        }
    }

    &--triangle {

        &-left,
        &-right {

            &::before {
                content: '';
                display: block;
                height: 150%;
                aspect-ratio: 1 / 2;
                background-color: $colour-red;
                position: absolute;
                top: -25%;
                z-index: -1;
            }
        }
    }

    &--triangle-left {
        &::before {
            @include o-triangle('bottom-left');
            left: calc(-1 * var(--container-gutter-margin));
        }
    }

    &--triangle-right {
        &::before {
            @include o-triangle('bottom-right');
            right: calc(-1 * var(--container-gutter-margin));
        }
    }
}

.grid-image-triangle {}