.property-detail-content {
    $text-margin: 1.4em;
    @include o-scroll-margin-top(calc(var(--site-header-height) + var(--property-detail-nav-height)));
    
    &__text {
        @include u-ellipsis-lines(var(--lines));

        // Undo on Safari only
        @media not all and (min-resolution:.001dpcm){ @supports (-webkit-appearance:none) {
            -webkit-line-clamp: unset;    
        }}

        &__content {
            @include light-font;

            @include respond-upto($site__desktop-breakpoint) {
                @include font-size($font-size-xxsmall);
            }
            @include respond-from($site__desktop-breakpoint) {
                @include font-size($font-size-small);
            }
            
            h3 {
                @include default-font;
                @include font-size($font-size-small);
            }
        
            ul {
                list-style-type: disc;
                list-style-type: "-" + css-character(space) + css-character(space) + css-character(space);
                list-style-position: outside;
        
                li {
                    margin-left: 2.5ch;
                }
            }

            ul,
            p {
                &:not(:last-child) {
                    margin-bottom: 1.4em;
                }
            }
        }
    }
    
    &:not(.is-truncated) &__continue-label {
        display: none;
    }
    &.is-expanded &__text {
        -webkit-line-clamp: unset;
    }

    &__continue-label {
        @include default-font;
        color: $colour-red;
        display: flex;
        align-items: center;
        margin-top: spacing(6);
        
        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-xxxsmall);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-small);
        }

        &__icon {
            stroke: currentColor;

            @include respond-upto($site__desktop-breakpoint) {
                height: spacing(3);
                margin-left: spacing(3);
                width: spacing(3);
            }
            @include respond-from($site__desktop-breakpoint) {
                height: spacing(4);
                margin-left: spacing(4);
                width: spacing(4);
            }
        }
    }
}

.property-detail-virtual-tour{
    margin-top: 2rem;
    h3 {
        @include default-font;
        @include font-size($font-size-small);
        margin-bottom: 0.5rem;
    }
}