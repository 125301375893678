// Page root class is applied to html- on live, not within body as is in defintion
.page {
    color: $colour-black;
    
    &__main {
        &--padded {
            padding-top: var(--site-header-height);
        }
    }

    &--dark {
        background-color: $colour-black;

        @media (prefers-color-scheme: dark) {
            color-scheme: dark;
        }
    }
    &--light {
        background-color: $colour-white;
    }
}