.page-404 {
    $custom404breakpoint: size(1700px,$unit-em);
    align-items: center;
    display: flex;
    min-height: unquote('min(((var(--vh, 1vh) * 100) - var(--site-header-height)), #{size(1350px)})');
    padding: calc(var(--site-header-height) * 2) 0;

    &__text,
    &__journeys {
        @include respond-upto($media-query-large) {
            grid-column: 1 / -1;
        }
        @include respond-between($media-query-large, $custom404breakpoint) {
            grid-column: 1 / span 7;
        }
    }

    &__text {
        color: $colour-white;

        @include respond-from($custom404breakpoint) {
            grid-column-end: span 5;
        }
    }

    &__title {
        @include alternate-default-font;
        max-width: 20ch;

        @include respond-upto($site__tablet-breakpoint) {
            @include font-size($font-size-xlarge);
        }
        @include respond-between($site__tablet-breakpoint, $site__desktop-breakpoint) {
            @include font-size($font-size-xxlarge);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xxxxlarge);
        }

        &:not(:last-child) {
            margin-bottom: calc(var(--line-height-em) / 2);
        }

        span {
            color: $colour-red;
            margin-right: 0.5ch;
        }
    }
    &__body-text {
        @include light-font;

        @include respond-upto($site__tablet-breakpoint) {
            @include font-size($font-size-medium);
        }
        @include respond-from($site__tablet-breakpoint) {
            @include font-size($font-size-large);
        }
    }

    &__journeys {
        border-top: 1px solid $colour-grey-xdark;
        padding-top: spacing(4);
        
        @include respond-from($custom404breakpoint) {
            grid-column-end: span 7;
            margin-top: spacing(-4);
        }
    }
}