.text-and-image {
    $this: '.text-and-image';

    column-gap: var(--column-gap);
    display: grid;
    position: relative;
    grid-template-columns: repeat(12, minmax(0, 1fr));

    &--text {
        &-left {

            grid-template-areas:
            '.  .    .    image    image    image       image       image   image   image   image   image'
            'title  title   title   title   title   title   title   title   title   title   title   title'
            'text    text    text    text   text   text   text   text   text   text   text text';

            @include respond-from($site__tablet-breakpoint) {
                grid-template-areas:
                '.  text    text    text    text    text    text   image   image   image   image   image'
                '.  title   title   title   title   title   title   title   title   title   title   title';
            }

            @include respond-from($site__desktop-breakpoint) {
                grid-template-areas:
                '.  text    text    text    text    .       .       image   image   image   image   image'
                '.  title   title   title   title   title   title   title   title   title   title   title';
            }

            #{$this} {
                &__text {
                    align-self: end;
                }
                &__support-title {
                    --translate-x-distance: -20%;

                    position: relative;
                    top: size(-20px);
                    justify-self: end;

                    @include respond-from($site__tablet-breakpoint) {
                        position: initial;
                        top: 0;
                        margin-top: spacing(16);
                        text-align: right;
                    }
                }
            }
        }
        &-right {

            grid-template-areas:
            'image    image    image       image       image   image   image   image   image  .  .  .'
            'title  title   title   title   title   title   title   title   title   title   title   title'
            'text    text    text    text   text   text   text   text   text   text   text text';

            @include respond-from($media-query-small) {
                grid-template-areas:
                'image    image    image   image   image   image   image   image   .  .  .  .'
                'title  title   title   title   title   title   title   title   title   .   .   .'
                'text    text    text    text   text   text   text   text   .   .   . .';
            }

            @include respond-from($media-query-medium) {
                grid-template-areas:
                    '.  image   image   image   image   .   text   text    text    text    text    .'
                    '.  .       .       title   title   title   title   title   title   title   title   title';
            }

            @include respond-from($media-query-mediumlarge) {
                grid-template-areas:
                    '.  image   image   image   image   image   .   text    text    text    text    .'
                    '.  .       .       title   title   title   title   title   title   title   title   title';
            }

            #{$this} {
                &__text {
                    align-self: center;
                }
                &__section-tag {
                    top: 0;
                }
                &__support-title {
                    --translate-x-distance: 20%;

                    @include respond-upto($media-query-medium) {
                        position: relative;
                        top: size(-10px);
                        text-align: right;
                    }
                }
            }
        }
    }

    &__text {
        grid-area: text;
    }

    &__section-tag {
        @include default-font;
        @include font-size($font-size-xxsmall);

        color: $colour-white;
        display: inline-block;
        padding-bottom: spacing(3);

        @include respond-from($site__tablet-breakpoint) {
            @include font-size($font-size-small);
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            position: absolute;
            left: 0;
            padding-bottom: 0;
        }

        &::before {
            content: '- ';
        }
    }
    &__image {
        grid-area: image;
        @include respond-upto($site__tablet-breakpoint) {            
            @include o-notched-corner($corner: 'top-left', $height: size(32px));
        }
        @include respond-from($site__tablet-breakpoint) {
            @include o-notched-corner($corner: 'top-left', $height: size(64px));
        }
    }
    &__rte {
        @include respond-upto($site__tablet-breakpoint) {
            .rte__text p {
                @include font-size($font-size-xxsmall);
            }
        }
    }
    &__support-title {
        @include header--support-title;        
        @include o-support-title-animation;

        color: rgba($colour-white, 0.3);
        grid-area: title;
    }
    &__alignment {
    }

    &__svg {
        &.is-in-view:not(.is-tab-defocused) > .northern-logo-pattern {
            animation-play-state: running;
        }
    }    
    
    .northern-logo-pattern {
        animation-play-state: paused;
        position: absolute;
        z-index: -1;        

        @include respond-upto($site__desktop-breakpoint) {
            display: none;
        }
        @include respond-from($site__desktop-breakpoint) {
            max-height: 100%;
            width: 1200px;
            max-width: 65%;
            right: -8%;
            top: 43px;
        }

        path {
            --filled-colour: #{$colour-white};
            --empty-colour: #{rgba($colour-white, 0)};
            animation-play-state: inherit!important;
            fill: var(--empty-colour);
            stroke: $colour-white;
            stroke-width: 0.23;
            stroke-miterlimit: 10;
            
            &.is-animating {
                animation: northern-logo-background-fill 5s forwards 1;
            }
        }
    }
}
