@mixin site-footer__text {
    @include light-font;
    @include respond-upto($site-footer__desktop-breakpoint) {
        @include font-size($font-size-xxxsmall);
    }
    @include respond-from($site-footer__desktop-breakpoint) {
        @include font-size($font-size-xsmall);
    }
}
@mixin site-footer__link {
    @include site-footer__text;
    @include o-link--red;
    
    display: block;
    text-decoration: none;
}

$site-footer__desktop-breakpoint: $site__desktop-breakpoint;

//
// LAYOUT ONLY
//
.site-footer {
    $paddingBottomMobile: spacing(56);

    @include container($mobileGutter: 0, $desktopGutter: $column-gutter-default * 3);
    display: grid;
    position: relative;
    
    @include respond-upto($media-query-smallmedium) {
        grid-template-columns: 2fr 1fr;
    }
    @include respond-between($media-query-smallmedium, $site-footer__desktop-breakpoint) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include respond-upto($site-footer__desktop-breakpoint) {
        grid-auto-rows: min-content;
        padding-bottom: $paddingBottomMobile;
    }
    @include respond-from($site-footer__desktop-breakpoint) {
        background-repeat: no-repeat;
        background-image: var(--background-image);
        background-size: size(800px) auto;
        background-size: unquote('min(') 50%, size(1000px) unquote(')') auto;
        background-position: right bottom;
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    &::before {
        content: '';
        grid-column: 1 / -1;

        @include respond-from($site-footer__desktop-breakpoint) {
            border-top: 1px solid var(--divider-color);
            margin-bottom: spacing(40);
            margin-left: $column-gutter-default;
            margin-right: $column-gutter-default;
        }
    }

    &::after {
        @include respond-upto($site-footer__desktop-breakpoint) {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            left: 0;
            grid-column: 1 / -1;
            height: $paddingBottomMobile;
            background-image: var(--background-image);
            background-size: cover;
            background-position: center top;
        }
    }

    &__address,
    &__nav,
    &__document-links,
    &__social-links,
    &__copyright-message {
        @include column-gutters;
    }

    @include respond-between($media-query-smallmedium, $site-footer__desktop-breakpoint) {
        &__address {
            padding-right: spacing(20);
            justify-self: end;
        }
        &__social-links {
            padding-left: spacing(20);
        }
    }

    @include respond-upto($site-footer__desktop-breakpoint) {
        &__social-links {
            grid-column-start: 2;
            justify-content: space-between;
        }
        &__address,
        &__social-links {
            grid-row: 3 / span 2;
        }
        &__social-link:not(:last-child) {
            margin-bottom: 0.98em;
        }
        &__document-links,
        &__nav {
            display: none!important;
        }
        &__scroll-top {
            grid-row-start: 2;
            grid-column: 1 / span 2;            
            margin-bottom: spacing(12);
        }
        &__copyright-message {
            grid-column: 1 / -1;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: spacing(20);
        }
    }

    @include respond-from($site-footer__desktop-breakpoint) {
        &__copyright-message {
            margin-top: spacing(56);
            margin-bottom: spacing(16);
        }

        &__scroll-top {
            @include column-gutter($side: 'right');
            grid-column-start: -2;
        }

        &__address {
            grid-column: 1 / span 3;
            grid-row: span 2;
        }

        &__document-links,
        &__nav {
            grid-column-end: span 3;
        }

        &__copyright-message {
            grid-column: 4 / span 3;
        }

        &__social-links {
            grid-column: 10 / span 2;
        }
    }

    &__address,
    &__nav,
    &__social-links,
    &__document-links {        
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__address__address-lines:not(:last-child),
    &__nav {
        @include respond-upto($site-footer__desktop-breakpoint) {
            margin-bottom: spacing(5);
        }
        @include respond-from($site-footer__desktop-breakpoint) {
            margin-bottom: spacing(16);
        }
    }
}

//
// STYLING ONLY
//
.site-footer {
    background-color: var(--background-color);
    color: var(--text-color);    
   
    &__address {
        
        &__email,
        &__telephone {
            @include site-footer__link;
        }

        &__email {
            word-break: break-word;
        }

        &__telephone {
            @include respond-from($site-footer__desktop-breakpoint) {
                @include bold-font;
            }
        }

        &__address-lines {
            &__line {
                @include site-footer__text;
                display: block;
            }
        }
    }    

    &__nav,
    &__document-links {
        &__link {
            @include site-footer__link;
        }
    }

    &__social-links {
        &__title {
            @include u-visually-hide;
        }
        &__social-link {
            @include site-footer__link;
            align-items: center;
            display: flex;

            svg {
                height: size(16px);
                margin-right: size(18px);
                vertical-align: middle;
                width: size(16px);
            }
        }
    }

    &__copyright-message {
        @include site-footer__text;
        color: $colour-grey-xdark;
    }

    &__arla {
        display: block;
        margin-bottom: spacing(6);
        width: max-content;
        
        svg {
            display: block;
            fill: var(--arla-colour);            
            width: 100%;

            @include respond-upto($site-footer__desktop-breakpoint) {
                max-height: size(50px);
                max-width: size(150px);
            }
            @include respond-from($site-footer__desktop-breakpoint) {
                max-width: size(200px);
                max-height: size(73px);
            }
        }
        
    }

    &__scroll-top {
        align-self: start;
        
        @include respond-upto($site-footer__desktop-breakpoint) {
            justify-self: center;
        }
        
        span {
            @include u-visually-hide;
        }

        svg {
            stroke: currentColor;
            transition: stroke-width 0.2s ease-in-out;
            width: 100%;

            @include respond-upto($site-footer__desktop-breakpoint) {
                height: size(20px);
                justify-self: center;
                stroke-width: 2px;
                width: spacing(16);
            }
            @include respond-from($site-footer__desktop-breakpoint) {
                height: size(48px);
                stroke-width: 1px;
            }
        }

        &:hover {
            svg {
                stroke-width: 3px;
            }
        }
    }

    &--dark {
        --text-color: #{$colour-white};
        --background-color: #{$colour-black};
        --divider-color: #{$colour-grey-xdark};
        --background-image: url(/_dev/_source/images/skyline-background-black.svg);
        --arla-colour: #{$colour-grey-xxdark};
    }
    &--light {
        --text-color: #{$colour-black};
        --background-color: #{$colour-white};
        --divider-color: #{$colour-grey-xdark};
        --background-image: url(/_dev/_source/images/skyline-background-white.svg);
        --arla-colour: #{$colour-grey-mid-dark};
    }
}