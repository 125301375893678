//*------------------------------------
//  Utility : Clip to viewport
//  ------------------------------------*/

////
/// @group -Library-
////

///
/// Limits size of element to viewport size & prevents scrolling
///
@mixin u-clip-to-viewport {
    @include u-fill-viewport(height);
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100vw;
}
