///*------------------------------------
//  #Settings : Colours
//  ------------------------------------*/
$colour-black:      #000000;
$colour-white:      #ffffff;
$colour-transparent: transparent;

$colour-grey-light: #f7f7f7; // site-header secondary-content triangles (light theme) relies on this colour via filter
$colour-grey-mid-light: #eaeaea;
$colour-grey-mid: #e3e3e3;
$colour-grey-mid-dark: #cbcbcb;
$colour-grey-dark: #b3b3b3;
$colour-grey-xdark: #999999;
$colour-grey-xxdark: #707070;
$colour-grey-xxxdark: #1A1A1A;

$colour-grey-pinkish: #bebebe;
$colour-grey-brownish: #666666;

$colour-grey-new-0: #f2f2f2;
$colour-grey-new-1: #eeeeee;
$colour-grey-new-2: #DCDCDC;
$colour-grey-new-3: #4C4C4C;
$colour-grey-new-4: #808080;

$colour-red: #bf0d45;
$colour-orange: #ff5500;

$box-shadow:    0px 10px 15px -3px rgba($colour-black, 0.1),
                0px 4px 6px -2px rgba($colour-black, 0.05);