body {
    &.is-page-nav-open {
        @include respond-upto($site__desktop-breakpoint) {
            @include u-clip-to-viewport;
        }
    }
}
.page-nav {
    $this: '.page-nav';

    // For JS
    --mobile-width: #{map-get($breakpoints, $site__desktop-breakpoint)};

    $paddingTop: spacing(1);
    $headerMinHeight: size(35px); //Height of button

    $mobilePageNavTotalHeight: $paddingTop + $headerMinHeight;

    @include container;
    @include alternate-default-font;
    align-items: baseline;
    background-color: var(--transparent-background-colour);
    color: var(--text-colour);
    display: flex;
    left: 0;
    right: 0;
    padding-top: $paddingTop;
    padding-bottom: spacing(1);
    position: fixed;
    top: var(--site-header-height);
    transition: transform 0.3s linear;
    z-index: 2;

    @include respond-upto($site__desktop-breakpoint) {
        flex-wrap: wrap;
    }
    
    &::before {
        border-top: 1px solid $colour-grey-xdark;
        content: '';
        position: absolute;
        left: var(--container-gutter-width, 0);
        right: var(--container-gutter-width, 0);
        top: 0;
    }

    &__mobile-toggle,
    &__title {
        margin-right: auto;

        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-xsmall);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-medium);
        }
    }

    &__mobile-toggle {
        @include respond-from($site__desktop-breakpoint) {
            display: none;
        }

        svg {
            height: spacing(3);
            margin-left: spacing(2);
            stroke: currentColor;
            width: spacing(3);
        }
    }

    &__internal-links {
        @include respond-upto($site__desktop-breakpoint) {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            width: 100%;
        }
        @include respond-from($site__desktop-breakpoint) {
            order: 2;
        }
        
        &__internal-link {
            @include font-size($font-size-xsmall);
            text-decoration: none;

            // &.is-active {
            //     color: $colour-red;
            // }

            &:hover {
                @include is-not-touch {
                    color: $colour-red;
                }
            }

            @include respond-upto($site__desktop-breakpoint) {
                padding: calc((var(--line-height) / 2) * 1em) 0;

                &:not(:last-child) {
                    border-bottom: 1px solid $colour-grey-xdark;
                }
            }

            @include respond-from($site__desktop-breakpoint) {
                &:not(:last-child) {
                    margin-right: spacing(10);
                }
            }
        }
    }

    &__external-links {
        &:only-child {
            margin-left: auto!important;
        }

        @include respond-from($site__desktop-breakpoint) {
            margin-left: spacing(12);
            order: 3;
        }

        &__external-link {
            @include o-button--default($defaultTypography: false);
            @include alternate-default-font;
            @include font-size($font-size-xsmall);
            padding: $button-mobile-padding!important;

            &:not(:last-child) {
                @include respond-upto($site__desktop-breakpoint) {
                    margin-right: spacing(2);
                }
                @include respond-from($site__desktop-breakpoint) {
                    margin-right: spacing(4);
                }
            }
        }
    }

    .site-header-hide & {
        transform: translate3d(0, calc(-1 * var(--site-header-height)), 0);
    }
    
    .is-initial-scroll-position & {
        background-color: var(--transparent-background-colour);
    }

    &.is-open {
        @include respond-upto($site__desktop-breakpoint) {
            background-color: var(--background-colour);
            
            #{$this} {
                &__mobile-toggle svg {
                    transform: rotate(180deg);
                }
                &__internal-links {
                    height: calc((var(--vh, 1vh) * 100) - (var(--site-header-height) + #{$mobilePageNavTotalHeight}));
                }
            }
        }
    }

    &:not(.is-open) {
        .is-initial-scroll-position & {
            background-color: transparent;
        }
        @include respond-upto($site__desktop-breakpoint) {
            height: $mobilePageNavTotalHeight;
        }

        #{$this} {
            &__internal-links {
                @include respond-upto($site__desktop-breakpoint) {
                    display: none;
                }
            }
        }
    }


    &--has-internal {
        #{$this} {
            &__title {
                @include respond-upto($site__desktop-breakpoint) {
                    display: none;
                }
            }
        }
    }

    &--dark {
        --transparent-background-colour: #{rgba($colour-black, 0.4)};
        --background-colour: #{$colour-black};
        --text-colour: #{$colour-white};
    }

    &--light {
        --transparent-background-colour: #{rgba($colour-white, 0.4)};
        --background-colour: #{$colour-white};
        --text-colour: #{$colour-black};
    }
}