.image-text-list {
    @include font-size($font-size-xsmall);

    display: grid;
    column-gap: $column-gutter-default * 2;
    row-gap: spacing(10);

    @include respond-from($media-query-small) {
        grid-auto-rows: 1fr;
    }

    @include respond-from($media-query-mediumlarge) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &__item {
        @include respond-from($media-query-small) {
            display: grid;
            column-gap: $column-gutter-default * 2;
            grid-template-columns: repeat(2, 1fr);
        }

        &__image-wrapper {}

        &__image {
            display: block;
            position: relative;
            min-height: size(275px);

            &__inner {
                @include u-image-cover;
            }

            @include respond-upto($media-query-small) {
                margin-bottom: spacing(3);
            }
        }

        &__text {
            @include respond-from($media-query-small) {
                margin-bottom: spacing(10);
            }
        }

        &__title {
            @include bold-font;
        }

        &__sub-title,
        &__body-text,
        &__link {
            @include light-font;
        }

        &__sub-title {
            color: $colour-red;
        }

        &__body-text {
            ul {

                &::before,
                &::after {
                    content: '-' + css-character(newLine);
                }
            }
        }

        &__link {
            @include o-link--red;
            text-underline-position: under;
        }
    }
}