.google-reviews {
    @include backend-preview-padding;
    align-items: start;
    display: grid;
    grid-template-columns: 1fr max-content;

    &__link {
        @include font-size($font-size-xxsmall);
        align-items: center;
        display: inline-flex;
        flex-wrap: wrap;
        text-decoration: none;
        margin-bottom: size(50px);
    }
    
    &__logo {
        color: $colour-red;
        height: size(30px);
        margin-right: size(14px);
        width: size(90px);
    }
    
    &__average-rating {
        @include bold-font;
        align-items: center;
        color: $colour-red;
        display: inline-flex;
        
        &__star {
            height: size(20px);
            width: size(20px);
        }
        
        &__label {
            margin-left: 1ch;
        }
    }

    &__review-count {
        @include light-font;
        margin-left: 1ch;

        &::before {
            content: '|';
            margin-right: 1ch;
        }
    }

    &__reviews {
        display: contents;

        .splide {
            &__track {
                grid-column: 1 / -1;
            }

            &__arrows {
                margin-left: auto;
                display: flex;
                justify-content: space-between;
                pointer-events: none;

                &.is-arrows-disabled {
                    display: none;
                }
            }

            &__arrow {            
                align-items: center;
                padding: spacing(2);
                position: relative;
                pointer-events: auto;
                
                &--prev {
                    --degrees: 90deg;
                }
                &--next {
                    --degrees: -90deg;
                }
        
                svg {
                    height: spacing(4);
                    stroke-width: 2px;
                    stroke: $colour-grey-xdark;
                    transition: all 0.3s ease-in-out;
                    transform: rotate(var(--degrees));
                    opacity: 1;
                    width: spacing(4);
                }
    
                &[disabled] {
                    cursor: default;
                    
                    svg {
                        opacity: 0.3;
                    }
                }

                @include is-not-touch {
                    &:not([disabled]):hover {
                        svg {
                            stroke: currentColor;
                        }
                    }
                }
            }
        }

        &__review {
            @include backend-specific {
                &:not(:first-child) {
                    display: none;
                }
            }
            @include respond-upto($site__desktop-breakpoint) {
                @include font-size($font-size-xxsmall);
            }

            &__text {
                @include light-font;

                @include respond-from($site__desktop-breakpoint) {
                    @include font-size($font-size-small);
                }
            }
            
            &__author {
                @include default-font;
                
                @include respond-from($site__desktop-breakpoint) {
                    @include font-size($font-size-small);
                }
                
                &::before {
                    @include light-font;
                    content: '–';
                    display: block;
                }
            }

            &__rating {
                svg {
                    fill: $colour-red;
                    height: size(14px);
                    width: size(14px);
                }

                &__text {
                    @include u-visually-hide;
                }
            }
        }
    }

    &--theme {
        &-white {
            color: $colour-black;
        }
        &-black {
            color: $colour-white;

            @include backend-specific {
                background-color: $colour-black!important;
            }
        }
    }
}