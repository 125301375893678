.takeover-gallery {
    $this: '.takeover-gallery';
    color: $colour-white;

    $takeover-gallery-breakpoint: $site__tablet-breakpoint;

    column-gap: var(--column-gap);
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));

    // For splide arrows
    position: relative;
    --inner-container-width: calc((var(--vw, 1vw) * 100) - ((var(--container-gutter-width) * 2)));
    
    &__section-tag {
        @include o-section-tag($breakpoint: $takeover-gallery-breakpoint);
        align-self: start;
        @include respond-upto($takeover-gallery-breakpoint) {
            grid-column: 1 / -1;
        }
        @include respond-from($takeover-gallery-breakpoint) {
            grid-column: 1 / span 1;
        }
    }
    
    &__mobile-rte {
        grid-column: 1 / -1;
        @include respond-from($takeover-gallery-breakpoint) {
            display: none;
        }
        @include backend-specific {
            display: block!important;
        }
    }
    
    &__slider {
        --total-columns: 12;
        margin-left: calc(-0.5 * #{$column-gutter-default * 2});
        width: calc(100% + #{$column-gutter-default * 2});

        @include respond-upto($takeover-gallery-breakpoint) {
            grid-column: 1 / -1;
            margin-top: spacing(10);
        }
        @include respond-from($takeover-gallery-breakpoint) {
            grid-column: 2 / -1;
        }
        
        @include backend-specific {
            display: none;
        }
        
        &__slides {
            --gap: 0;
            --slider-padding: 0;
            --slider-breakpoint: (min-width: #{get-breakpoint($takeover-gallery-breakpoint, $breakpoints)});
            position: relative;

            @include respond-upto($takeover-gallery-breakpoint) {
                padding-bottom: spacing(5) + size(40px);
            }

            @include respond-from($takeover-gallery-breakpoint) {
                position: static;
            }
            
            > .splide {
                &__arrows {
                    align-items: center;
                    display: flex;
                    position: absolute;
                    
                    @include respond-upto($takeover-gallery-breakpoint) {
                        bottom: 0;
                        gap: size(10px);
                        justify-content: flex-end;
                        right: var(--container-gutter-width);
                    }
                    @include respond-from($takeover-gallery-breakpoint) {
                        left: calc(0px - var(--container-gutter-width));
                        height: 100%;
                        justify-content: space-between;
                        right: calc(0px - var(--container-gutter-width));
                    }
                    
                    > .splide__arrow {
                        align-items: center;
                        background-color: $colour-red;
                        display: flex;
                        opacity: 1;
                        justify-content: center;
                        transition: opacity 0.3s $easeInOutQuart;
                        z-index: 1;
                        
                        @include respond-upto($takeover-gallery-breakpoint) {
                            height: size(40px);
                            width: size(40px);
                        }
                        @include respond-from($takeover-gallery-breakpoint) {
                            height: size(75px);
                            width: size(75px);
                        }
                        
                        &[disabled] {
                            cursor: default;
                            opacity: 0;
                        }
                        
                        &--prev {
                            transform: rotate(90deg);
                        }
                        &--next {
                            transform: rotate(-90deg);
                        }
                        
                        svg {
                            stroke: $colour-white;

                            @include respond-upto($takeover-gallery-breakpoint) {
                                height: size(16px);
                                width: size(16px);
                            }
                            @include respond-from($takeover-gallery-breakpoint) {
                                height: size(22px);
                                width: size(22px);
                            }
                        }
                    }
                }
                &__track {
                    overflow: visible;

                    > .splide__list > .splide__slide:nth-child(2) { 
                        @include o-notched-corner($corner: 'top-left', $height: size(67px));
                    }

                    > .splide__list > .splide__slide:last-child { 
                        @include o-notched-corner($corner: 'bottom-right', $height: size(67px));
                    }
                }
            }

        }    
        &__slide {
            @include column-gutters;
            width: calc(var(--column-calc) + (#{$column-gutter-default * 2} * 0.25));

            &--rte {
                margin: auto 0;
                --column-calc: calc((var(--inner-container-width) * var(--column-count)/var(--total-columns)));

                @include respond-upto($media-query-mediumlarge) {
                    --column-count: 6;
                }
                @include respond-from($media-query-mediumlarge) {
                    --column-count: 5;
                }
            }
            
            &--image {
                --column-calc: calc((var(--inner-container-width) * var(--column-count)/var(--total-columns)));
                @include respond-upto($media-query-smallmedium) {
                    --column-count: 10;                    
                }
                @include respond-between($media-query-smallmedium, $takeover-gallery-breakpoint) {
                    --column-count: 7;                    
                }
                @include respond-between($takeover-gallery-breakpoint, $media-query-large) {
                    --column-count: 5;
                }
                @include respond-between($media-query-large, $media-query-xlarge) {
                    --column-count: 4;
                }
                @include respond-from($media-query-xlarge) {
                    --column-count: 3;
                }
            }
        }            
    }

    .rte {
        max-width: size(420px);
        &__text {
            a {
                color: $colour-red;
                text-decoration: none;

                &:focus,
                &:hover {
                    color: inherit;
                }
            }
        }
    }
}
