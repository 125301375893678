.map {
    $this: '.map';

    height: 100%;
    width: 100%;

    $padding: size(10px);
    --map-icon-width: #{size(36px)};
    --map-icon-height: #{size(54px)};
    --map-logo-size: #{size(16px)};
    
    &__marker {
        display: inline-flex;
        flex-direction: row-reverse;
        overflow: visible;
        position: relative;
        pointer-events: none;
        transform: translate(calc(-100% + (var(--map-icon-width) / 2)), -100%);

        &__button {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            bottom: 0;
            max-width: var(--map-icon-width);
            pointer-events: all;
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
        }
        
        &__text {
            align-items: flex-start;
            background-color: $colour-black;
            color: $colour-white;
            display: flex;
            flex: 0 1 0;
            flex-direction: column;
            min-width: 0;
            min-height: var(--map-icon-height);
            padding-top: $padding;
            padding-bottom: $padding;
            position: relative;
            overflow: hidden;
            justify-content: center;
            transition: flex 0.3s $easeInOutQuart;
            z-index: -1;
            
            > * {
                padding-left: $padding;
                padding-right: calc(#{$padding} + (var(--map-icon-width) / 2));
                white-space: nowrap;
            }
        }
        
        &__title {
            @include font-size($font-size-xxxsmall);
            @include bold-font;

            &:only-child {
                margin-bottom: auto;
            }
        }
        
        &__link-text {
            @include font-size($font-size-xxxsmall);
            @include default-font;

            @include is-touch {
                &::before {
                    content: 'Tap to';
                    text-transform: none;
                }
                text-transform: lowercase;
            }
            
            &__icon {
                display: inline-block;
                height: size(14px);
                stroke: currentColor;
                transform: rotate(-90deg);
                vertical-align: middle;
                pointer-events: none;
                width: size(14px);
            }
        }

        &__icon,
        &__logo,
        &__count {
            margin-right: calc(-1 * (var(--map-icon-width) / 2));
        }
        
        &__icon {
            fill: $colour-black;
            flex-grow: 0;
            flex-shrink: 0;
            height: var(--map-icon-height);
            transition: fill 0.3s $easeInOutQuart;
            position: relative;
            width: var(--map-icon-width);
        }
        
        &__logo,
        &__count {
            display: block;
            position: absolute;
            pointer-events: all;
            right: calc(var(--map-icon-width) / 2);
            top: 20.3%;
            transform: translateX(50%)
        }
        
        &__logo {
            opacity: 0;
            fill: $colour-white;
            height: var(--map-logo-size);
            width: var(--map-logo-size);
            transition: opacity 0.3s $easeInOutQuart;
        }

        &__count {
            @include light-font;
            @include font-size($font-size-xsmall);
            line-height: 1;
            color: $colour-white;
            opacity: 1;
        }

        &--active {
            z-index: 1;
        }
        &:hover {
            z-index: 2;
        }

        &:only-child &__button,
        &--active &__button,
        &__button.focus-visible,
        &__button:hover {
            max-width: none;
        }

        &:only-child,
        &--active,
        &__button.focus-visible ~,
        &__button:hover ~ {
            #{$this}__marker__text {
                flex: 1;
            }
        }

        &:only-child,
        &--active,
        &__button.focus-visible,
        &__button:hover {
            #{$this}__marker__icon {
                fill: $colour-red;
            }
        }

        &__button--zero,
        &:only-child,
        &--active {
            #{$this}__marker {
                &__logo {
                    opacity: 1;
                }
                &__count {
                    opacity: 0;
                }
            }
        }
    }

    &__clear {
        @include light-font;
        align-items: center;
        display: flex;
        border: 1px solid transparent;
        background-color: $colour-red;
        box-shadow: 0 1px 3px 0 rgba($colour-black, 0.1), 0 1px 2px 0 rgba($colour-black, 0.06);
        color: $colour-white;
        margin-top: spacing(2);
        padding: spacing(3) spacing(5);
        
        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-xxsmall);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xsmall);
        }

        &__icon {
            width: size(19px);
            height: size(19px);
            margin-left: spacing(2);
        }

        &:hover,
        &.focus-visible {
            background-color: $colour-white;
            border-color: $colour-red;
            color: $colour-red;
        }
    }
}