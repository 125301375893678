.available-properties-slider {
    &__properties {
        --gap: #{$column-gutter-default * 2};
        --slider-padding: 0;
        position: static;

        @include respond-upto($site__tablet-breakpoint) {
            --button-size: #{size(40px)};
            --buttons-margin: #{size(30px)};
        }

        @include respond-from($site__tablet-breakpoint) {
            --button-size: #{size(75px)};
            --buttons-margin: #{size(30px)};
        }

        > .splide {
            &__arrows:not(.is-arrows-disabled) + .splide__track {
                @include respond-upto($site__tablet-breakpoint) {
                    padding-bottom: calc(var(--button-size) + var(--buttons-margin));
                }
            }

            &__arrows {
                align-items: center;
                display: flex;
                gap: size(10px);
                justify-content: space-between;
                position: absolute;
                
                @include respond-upto($site__tablet-breakpoint) {
                    bottom: 0;
                    right: 0;
                }

                @include respond-from($site__tablet-breakpoint) {
                    height: 100%;
                    left: calc(0px - var(--container-gutter-width));
                    right: calc(0px - var(--container-gutter-width));
                }

                > .splide__arrow {
                    align-items: center;
                    background-color: $colour-black;
                    display: flex;
                    opacity: 1;
                    justify-content: center;
                    transition: opacity 0.3s $easeInOutQuart;
                    z-index: 1;
                    height: var(--button-size);
                    width: var(--button-size);

                    &[disabled] {
                        cursor: default;
                        opacity: 0;
                    }

                    &--prev {
                        transform: rotate(90deg);
                    }
                    &--next {
                        transform: rotate(-90deg);
                    }

                    svg {
                        stroke: $colour-white;

                        @include respond-upto($site__tablet-breakpoint) {
                            height: size(16px);
                            width: size(16px);
                        }

                        @include respond-from($site__tablet-breakpoint) {
                            height: size(22px);
                            width: size(22px);
                        }
                    }
                }
            }
            &__track {
                overflow: visible;
            }
        }

        &__property {
            display: flex;
            flex-direction: column;

            &:focus-within,
            &:hover {
                > * > *::after {
                    opacity: 1;
                }
            }

            > * {
                display: flex;
                flex-grow: 1;
                
                > * {
                    box-shadow: $box-shadow;

                    @include is-not-touch {
                        &::after {
                            box-shadow: 0 25px 50px -12px rgba($colour-black,0.25);
                            content: '';
                            opacity: 0;
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            transition: inherit;
                            transition-property: opacity;
                        }
                    }
                }
            }
        }
    }

}