.rte {
    $this: '.rte';

    --underline-colour: #{$colour-red};
    color: var(--text, #{$colour-grey-dark});
    @include light-font;

    @include backend-specific {
        color: $colour-black!important;
    }

    &__text {
        a {
            @include o-link--red;
        }

        #{$this}__underlined-title {
            @include alternate-medium-font;
            @include font-size($font-size-large);

            margin-bottom: spacing(4);

            @include respond-from($site__tablet-breakpoint) {
                @include font-size($font-size-xxlarge);
            }

            u {
                text-decoration-color: var(--underline-colour);
                text-decoration-thickness: 2px;
                text-underline-offset: 10%;
            }
        }
    
        h2 {
            @include alternate-default-font;
    
            @include respond-upto($site__tablet-breakpoint) {
                @include font-size($font-size-medium);
            }
            @include respond-from($site__tablet-breakpoint) {
                @include font-size($font-size-large);
            }
    
            &:not(:first-child) {
                margin-top: spacing(16);
            }
        }
    
        h3 {
            @include default-font;
            @include font-size($font-size-small);
    
            &#{$this}__heading-divider {
                border-top: 1px solid;
                padding-top: spacing(4);
    
                &:not(:first-child) {
                    margin-top: spacing(16);
                }
            }
        }
    
        ul,
        ol {
            @include frontend-specific {
                list-style-position: outside;
            }
            @include backend-specific {
                list-style-position: inside;
            }
            li {
                @include respond-upto($site__desktop-breakpoint) {
                    margin-left: 2.5ch;
                }
            }
        }
    
        ul {
            list-style-type: disc;
            list-style-type: "-" + css-character(space) + css-character(space) + css-character(space);
        }
        
        ol {
            list-style: decimal;
        }
    
        p,
        li,
        td {
            @include font-size($font-size-small);
            
            &#{$this}__text--lg {
                @include font-size($font-size-medium);
            }
            &#{$this}__text--xl {
                @include font-size($font-size-large);

                @include respond-upto($site__tablet-breakpoint) {
                    @include font-size($font-size-medium);
                }
            }

            &#{$this}__framed-text {
                $triangleHeight: size(50px);
                $triangleWidth: size(100px);
                @include font-size($font-size-large);
                padding: ($triangleHeight * 1) size(10px);
                position: relative;
                text-align: center;

                @include respond-from($site__desktop-breakpoint) {
                    @include font-size($font-size-xxlarge);
                    padding: (calc($triangleHeight / 2)) $triangleWidth ;
                }

                &::before,
                &::after {
                    background-color: $colour-grey-new-2;
                    content: '';
                    display: block;
                    height: size(50px);
                    position: absolute;
                    width: size(100px);
                }

                &::before {
                    @include o-triangle('top-left');
                    top: 0;
                    left: 0;
                }
                &::after {
                    @include o-triangle('bottom-right');
                    bottom: 0;
                    right: 0;                    
                }
            }
        }
    
        p {
            &:not(:last-child) {
                margin-bottom: var(--line-height-em);
            }
        }
        
        table {
            border-collapse: collapse;
            color: inherit;
            font: inherit;
            width: 100%;
    
            tr {
                border-bottom: 1px solid $colour-grey-xdark;
            }
    
            td {
                vertical-align: top;
                
                &:not(:first-child) {
                    padding-left: calc($column-gutter-default / 2);
                }
                &:not(:last-child) {
                    padding-right: calc($column-gutter-default / 2);
                }
    
                &:last-child {
                    text-align: right;
                }
            }
            
            &:not(:first-child) {
                margin-top: spacing(4);
            }
        }

        blockquote {
            &#{$this}__quote {
                @include alternate-medium-font;
                margin: 0 auto;
                padding-bottom: 0.8em;
                padding-right: 1.475em;
                position: relative;
                text-align: center;
                width: fit-content;

                @include respond-upto($site__tablet-breakpoint) {
                    @include font-size($font-size-large);
                }
                @include respond-from($site__tablet-breakpoint) {
                    @include font-size($font-size-xxlarge);
                }

                &::before,
                &::after {
                    $colour: str-replace("" + $colour-grey-mid, '#', '%23');
                    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 73 68' fill='#{$colour}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M27.1441 38.3928V68H0V44.6231C0 31.967 1.49991 22.8056 4.49974 17.139C8.43988 9.58856 14.6772 3.87557 23.2116 0L29.3978 9.91281C24.2413 12.0899 20.4443 15.3247 18.0066 19.6172C15.5689 23.9096 14.2096 30.1682 13.9285 38.3928H27.1441ZM70.754 38.3928V68H43.6022V44.6231C43.6022 31.9721 45.1021 22.8108 48.102 17.139C52.0421 9.58856 58.2794 3.87557 66.8138 0L73 9.91281C67.8385 12.0899 64.0414 15.3247 61.6088 19.6172C59.1763 23.9096 57.8169 30.1682 57.5307 38.3928H70.754Z'/%3E%3C/svg%3E%0A");
                    background-size: contain;
                    content: '';
                    display: inline-block;
                    height: 1.7465em;
                    width: 1.875em;
                    z-index: -1;
                }
                
                &::before {
                    margin-bottom: 0.2em;
                    margin-right: -0.2em;
                    position: relative;
                }
                &::after {
                    bottom: 0;
                    position: absolute;
                    right: 0;
                    transform: rotate(180deg);                    
                }
            }
        }
    }

    &__footer {
        $link-gap: 1ch;
        display: flex;
        flex-wrap: wrap;

        &:not(:first-child) {
            margin-top: spacing(16);
        }

        &--align {
            &-left {
                justify-content: flex-start;
                #{$this}__link {
                    margin-right: $link-gap;
                }
            }
            &-centre {
                justify-content: center;
                #{$this}__link {
                    margin-left: calc($link-gap / 2);
                    margin-right: calc($link-gap / 2);
                }
            }
            &-right {
                justify-content: flex-end;
                #{$this}__link {
                    margin-left: $link-gap;
                }
            }
        }
    }

    &__link {
        display: block;
        flex-shrink: 1;
        flex-grow: 0;
        margin-bottom: calc(var(--line-height) * 0.5em);
    }    
    
    &:not(&--v2) {
        #{$this} {
            &__link svg {
                display: none;
            }
            &__footer {        
                &:not(:first-child) {
                    margin-top: spacing(16);
                }
            }
        }
        &#{$this}--button {
            &-default,
            &-red {
                #{$this}__link {
                    @include o-button--ghost-default;                
                }
            }
            &-white {
                #{$this}__link {
                    @include o-button--ghost-white;                
                }
            }
            &-black {
                #{$this}__link {
                    @include o-button--ghost-black;                
                }
            }
        }
    }

    &--v2 {
        #{$this} {
            &__footer {        
                &:not(:first-child) {
                    margin-top: spacing(8);
                }
            }
        }
        @include o-button-theme($this: '&#{$this}', $buttonSelector: #{$this}__link);
        &#{$this}--button-default #{$this}__link {
            @include o-button-v2--black;
        }
    }

    &--black-underlines {
        --underline-colour: #{$colour-black};
    }
    &--white-underlines {
        --underline-colour: #{$colour-white};
    }
}