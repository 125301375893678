@mixin site-header__secondary-link {
    @include o-link--red;
    @include light-font;
    @include font-size($font-size-xsmall);
    text-decoration: none;
    
    @include respond-upto($site-header__desktop-breakpoint) {
        display: block;
        padding: spacing(2) 0;
        border-bottom: 1px solid var(--divider-colour);
        
        &:first-child {
            border-top: 1px solid var(--divider-colour);
        }
    }
}

$site-header__desktop-breakpoint: $site__desktop-breakpoint;

:root {
    @include respond-upto($site-header__desktop-breakpoint) {
        --site-header-logo-height: #{size(28px)};
        --site-header-padding-top: #{$column-gutter-default};
        --site-header-padding-bottom: 0;
        --site-header-height: #{size(28px) + ($column-gutter-default * 2)};
    }
    @include respond-from($site-header__desktop-breakpoint) {
        --site-header-logo-height: #{size(40px)};
        --site-header-padding-top: #{size(30px)};
        --site-header-padding-bottom: #{size(30px)};
        --site-header-height: #{size(40px) + (size(30px) + size(30px))};
    }
}

* {
    scroll-margin-top: var(--site-header-height);
}

body.is-site-nav-open {
    @include u-clip-to-viewport;
}

.site-header {
    $this: '.site-header';

    @include container;
    background-repeat: no-repeat;
    color: var(--text-colour);
    display: flex;
    height: var(--site-header-height);
    padding-top: var(--site-header-padding-top);
    padding-bottom: var(--site-header-padding-bottom);
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    transition: transform 0.3s linear;
    
    @include respond-upto($site-header__desktop-breakpoint) {
        align-items: flex-start;
        flex-wrap: wrap;
    }
    @include respond-from($site-header__desktop-breakpoint) {
        align-items: center;
    }
    
    &--sticky {
        position: fixed;
        .has-dialog & { 
            transform: translate3d(0, calc(-1 * var(--site-header-height)), 0);
        }
    }
    &:not(&--sticky) {
        position: absolute;
    }
    
    &--sticky:not(.is-open) {
        .site-header-hide & {
            transform: translate3d(0, calc(-1 * var(--site-header-height)), 0);
            &.is-property-listing {
                @include respond-from($site-header__desktop-breakpoint) {
                    transform: none;
                }
            }
            &.is-subnav-open {
                @include respond-upto($site-header__desktop-breakpoint) {
                    transform: none;
                }
            }
        }
    }

    &::before {
        background: var(--transparent-background-colour);
        bottom: 0;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        pointer-events: none;
        position: absolute;
        z-index: -1;
    }
    
    .is-initial-scroll-position & {
        &--dark::before {
            background: var(--gradient);        
            bottom: -#{spacing(5)};
            opacity: 1;
        }
    }

    &__logo {
        display: block;
        
        @include respond-from($site-header__desktop-breakpoint) {
            margin-right: auto;
        }
        
        &__image {
            display: block;
            height: var(--site-header-logo-height);
            width: auto;
        }
    }

    &__nav {
        $paddingBottomMobile: spacing(56);
        position: relative;
        
        @include respond-upto($site-header__desktop-breakpoint) {
            padding-top: spacing(10);
            min-height: calc(100% - var(--site-header-logo-height));
            width: 100%;
            padding-bottom: $paddingBottomMobile;
            
            &::after {
                background-repeat: no-repeat;
                content: '';
                position: absolute;
                bottom: 0;
                right: calc(-1 * var(--container-gutter-width));
                left: calc(-1 * var(--container-gutter-width));
                height: $paddingBottomMobile;
                background-image: var(--background-image);
                background-size: cover;
                background-position: center top;
            }
        }
        @include respond-from($site-header__desktop-breakpoint) {
            align-items: center;
            display: flex;
        }
    }

    &__toggle {
        display: flex;
        overflow: hidden;
        margin-left: spacing(10);
        
        @include respond-upto($site-header__desktop-breakpoint) {
            @include alternate-medium-font;
            @include font-size($font-size-xxxsmall);
            align-items: center;
        }
        @include respond-from($site-header__desktop-breakpoint) {
            @include alternate-default-font;
            @include font-size($font-size-xsmall);
            align-items: flex-start;
        }

        svg {
            height: size(22px);
            position: relative;            
            width: size(22px);
            transform-origin: center center;

            @include respond-upto($site-header__desktop-breakpoint) {
                margin-left: $column-gutter-default;
            }
            @include respond-from($site-header__desktop-breakpoint) {
                margin-right: $column-gutter-default;
            }
        }
        
        &[aria-expanded="true"] {
            span:nth-of-type(1) {
                display: none;
            }
            svg {
                transform: rotate(45deg);
            }
        }
        &[aria-expanded="false"] {
            span:nth-of-type(2) {
                display: none;
            }
        }

        @include is-not-touch {
            &:hover {
                svg {
                    fill: $colour-red;
                }
            }
        }

        &--mobile {
            margin-left: auto;
            @include respond-from($site-header__desktop-breakpoint) {
                display: none;
            }
        }
        &--desktop {
            @include respond-upto($site-header__desktop-breakpoint) {
                display: none;
            }
        }
    }

    &__primary-nav {
        @include respond-from($site-header__desktop-breakpoint) {
            align-items: center;
            display: flex;
        }
        
        &__item {
            @include respond-upto($site-header__desktop-breakpoint) {
                &:not(&--mobile-accordion) {
                    border-bottom: 1px solid var(--divider-colour);
                }
                
                &:first-child {
                    border-top: 1px solid var(--divider-colour);
                }

                &--mobile-accordion {                
                    #{$this}__primary-nav__link {
                        display: none;
                    }
    
                    &.is-open {
                        #{$this}__primary-nav__mobile-list {
                            display: block;
                        }
                    }
                }
            }

            @include respond-from($site-header__desktop-breakpoint) {
                &:not(:last-child) {
                    margin-right: spacing(12);
                }
            }
        }

        &__toggle,
        &__mobile-list__link,
        &__link {
            @include font-size($font-size-xsmall);
            @include respond-upto($site-header__desktop-breakpoint) {
                @include light-font;
                padding: spacing(2) 0;
            }
        }

        &__toggle {
            align-items: center;
            border-bottom: 1px solid var(--divider-colour);
            justify-content: space-between;
            text-align: left;
            width: 100%;

            @include respond-upto($site-header__desktop-breakpoint) {
                display: flex;
            }
            @include respond-from($site-header__desktop-breakpoint) {
                display: none;
            }

            &__icon {
                height: size(14px);
                stroke: currentColor;
                stroke-width: 1px;
                width: size(14px);
            }

            &[aria-expanded="true"] {
                @include default-font;
            }
            &[aria-expanded="true"] &__icon {
                transform: rotate(180deg);
            }
        }
        
        &__link {
            display: block;
            text-decoration: none;

            @include respond-upto($site-header__desktop-breakpoint) {
                @include o-link--red;
            }
            @include respond-from($site-header__desktop-breakpoint) {
                @include alternate-default-font;
                padding: spacing(2) 0;
                position: relative;
                overflow: hidden;

                &::before {
                    background-color: $colour-red;
                    content: '';
                    position: absolute;
                    bottom: 0;
                    height: 1px;
                    left: 0;
                    right: 0;
                    transform: translate3d(-101%,0,0);
                    transition: transform 0.3s $easeInQuad;
                }
                
                &--active,
                &:focus,
                &:hover {
                    &::before {
                        transform: translate3d(0,0,0);
                        transition: transform 0.4s $easeOutQuad;
                    }
                }
            }
        }

        &__mobile-list {            
            display: none;
            margin-bottom: spacing(8);

            &__item {
                
            }

            &__link {
                align-items: center;
                border-bottom: 1px solid var(--divider-colour);
                display: flex;
                text-decoration: none;
                padding: spacing(1) 0;
                justify-content: space-between;

                &__icon {
                    height: size(14px);
                    stroke: currentColor;
                    stroke-width: 1px;
                    transform: rotate(-90deg);
                    width: size(14px);
                }
            }
        }
    }

    &__primary-nav,
    &__secondary-links {
        @include respond-upto($site-header__desktop-breakpoint) {
            &:not(:last-child) {
                margin-bottom: spacing(12);
            }
        }
    }

    &__secondary-content {
        --header-content-top-padding: calc(var(--site-header-height) + #{spacing(20)});;

        @include respond-from($site-header__desktop-breakpoint) {
            @include container;
            background-color: var(--secondary-background-colour);
            background-image: var(--background-image);
            background-size: size(800px) auto;
            background-size: unquote('min(') 50%, #{size(1000px)} unquote(')') auto;
            background-position: right bottom;
            background-repeat: no-repeat;
            display: grid;
            column-gap: $column-gutter-default * 2;
            row-gap: $column-gutter-default * 2;
            grid-template-rows: repeat(2, min-content) 1fr;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            justify-content: end;
            padding-top: var(--header-content-top-padding);
            $padding-bottom: spacing(20);
            position: fixed;
            overflow: hidden;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            > * {
                align-items: flex-start;
                display: flex;
                flex-direction: column;
            }
        }
    }

    &__secondary-links {
        @include respond-from($site-header__desktop-breakpoint) {
            grid-row-start: 1;
        }
        
        &--first {
            @include respond-upto($site-header__desktop-breakpoint) {
                display: none;
            }
        }
        
        &__link {
            @include site-header__secondary-link;
        }
    }

    // Make room to the right of angled section
    &__secondary-links--first,
    &__tertiary-links--first {
        @include respond-from($site-header__desktop-breakpoint) {
            padding-left: $column-gutter-default * 2;
        }
    }

    &__tertiary-links,
    &__social-links {
        @include respond-upto($site-header__desktop-breakpoint) {
            // Counteracts unneccesary margin on last tertiary links
            &--first {
                margin-top: spacing(12);
            }
        }
    }
    
    &__tertiary-links {
        @include respond-from($site-header__desktop-breakpoint) {
            grid-row-start: 2;
        }

        &__link {
            @include site-header__secondary-link;
        }

        @include respond-upto($site-header__desktop-breakpoint) {
            // Counteracts double border on tertiary links
            &:not(&--first) &__link:first-child {
                border-top: none;
            }
        }
    }

    &__document-links {
        display: contents;

        &__link {
            @include site-header__secondary-link;
        }
    }

    &__social-links {
        &__link {
            @include site-header__secondary-link;
            align-items: center;
            display: flex;

            svg {
                height: size(16px);
                margin-right: size(18px);
                vertical-align: middle;
                width: size(16px);
            }
        }
    }

    &__address {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        position: relative;

        @include respond-upto($site-header__desktop-breakpoint) {
            margin-top: spacing(16);
        }
        @include respond-from($site-header__desktop-breakpoint) {
            grid-row: 1 / span 2;
            grid-column-start: 1;
            padding-right: 30%;
        }

        &__email {
            max-width: 100%;
            text-decoration: none;
            
            @include respond-upto($site-header__desktop-breakpoint) {
                @include light-font;
            }
            
            @include respond-from($site-header__desktop-breakpoint) {
                @include site-header__secondary-link;
                word-break: break-word;
            }
        }

        &__telephone {
            @include bold-font;
            text-decoration: none;
            
            @include respond-from($site-header__desktop-breakpoint) {
                @include site-header__secondary-link;
                @include bold-font;
            }
        }

        &__address-lines {
            &:not(:last-child) {
                @include respond-upto($site-header__desktop-breakpoint) {
                    margin-bottom: spacing(5);
                }
                @include respond-from($site-header__desktop-breakpoint) {
                    margin-bottom: $column-gutter-default * 2;
                }
            }

            &__line {
                @include light-font;
                display: block;
                
                @include respond-upto($site-header__desktop-breakpoint) {
                    @include font-size($font-size-xxxsmall);
                }
                
                @include respond-from($site-header__desktop-breakpoint) {
                    @include font-size($font-size-xsmall);
                }
            }
        }

        &::before,
        &::after {
            content: '';
            background-color: var(--secondary-angled-background-colour);
            min-width: calc(200% + var(--container-gutter-width) + 2.25rem);
            position: absolute;
            transform: skew(-26.883deg, 0deg);
            z-index: -1;
            
                
            @include respond-upto($site-header__desktop-breakpoint) {
                display: none;
            }
        }

        &::before {
            bottom: 100%;
            right: -$column-gutter-default * 2;
            transform-origin: bottom right;
            height: var(--header-content-top-padding);
        }        

        &::after {
            top: 0;
            right: -$column-gutter-default * 2;
            transform-origin: top right;
            height: calc(((var(--vh, 1vh) * 100)) - var(--header-content-top-padding));
        }
    }

    &__copyright-message {
        @include light-font;
        @include font-size($font-size-xsmall);
        color: var(--arla-colour);
        grid-row-start: 3;
        grid-column-start: 2;
        justify-content: flex-end;
        padding-bottom: spacing(20);

        @include respond-upto($site-footer__desktop-breakpoint) {
            display: none;
        }
    }

    &__arla {
        margin-bottom: spacing(6);
        
        svg {
            fill: var(--arla-colour);
            max-height: size(73px);
            max-width: size(200px);            
        }
    }

    &--dark {
        --background-colour: #{$colour-black};
        --transparent-background-colour: #{rgba($colour-black, 0.4)};
        --secondary-background-colour: #{$colour-grey-xxxdark};
        --secondary-angled-background-colour: var(--background-colour);
        --text-colour: #{$colour-white};
        --divider-colour: #{$colour-grey-xdark};
        --gradient: linear-gradient(#{scrim-gradient($colour-black)});
        --background-image: url(/_dev/_source/images/skyline-background-black.svg);
        --arla-colour: #{$colour-grey-xxdark};

        #{$this}__logo {
            filter: invert(1);
        }
    }

    &--light {
        --background-colour: #{$colour-white};
        --transparent-background-colour: #{$colour-white};
        --secondary-background-colour: #{$colour-white};
        --secondary-angled-background-colour: #{$colour-grey-light};
        --text-colour: #{$colour-black};
        --divider-colour: #{$colour-grey-dark};
        --gradient: linear-gradient(#{scrim-gradient($colour-white)});
        --background-image: url(/_dev/_source/images/skyline-background-white.svg);
        --arla-colour: #{$colour-grey-mid-dark};
    }

    &:not(.is-open) {
        @include respond-upto($site-header__desktop-breakpoint) {
            background-image: none!important;
        }
        #{$this} {
            &__nav {
                @include respond-upto($site-header__desktop-breakpoint) {
                    display: none;
                }
            }
            &__secondary-content {
                display: none;
            }
        }
    }

    &.is-open {
        @include respond-upto($site-header__desktop-breakpoint) {
            background-color: var(--secondary-background-colour);
            bottom: 0;
            overflow-y: auto;
            height: auto;

            &::before {
                display: none;
            }            
        }
    }

    &.is-subnav-open {
        @include respond-upto($site-header__desktop-breakpoint) {
            background-color: var(--background-colour);
        }
    }
}