// [Settings,][Tools,][Generic,][Base,][Objects,][Utilities,]
@import '_settings.media-queries';

////
/// @group -Project-
////

///
/// Neuzeit Grotesk font declaration. Do not use directly
///
/// @param {Number} $weight [400] - Font weight
/// @access private
///
@mixin Neuzeit-Grotesk ($weight: 400) {
  font-family: neuzeit-grotesk, sans-serif;
  font-weight: #{$weight};
  font-style: normal;
}
  
  ///
  /// Avant Garde font declaration. Do not use directly
  ///
  /// @param {Number} $weight [400] - Font weight
  /// @access private
  ///
  @mixin Avant-Garde ($weight: 400) {
    font-family: itc-avant-garde-gothic-pro, sans-serif;
    font-weight: #{$weight};
    font-style: normal;
    letter-spacing: xd-letter-spacing(20);
}

///
/// Light default font
///
@mixin light-font {
  @include Neuzeit-Grotesk(300);
}

///
/// Regular default font
///
@mixin default-font {
  @include Neuzeit-Grotesk;
}

///
/// Bold default font
///
@mixin bold-font {
  @include Neuzeit-Grotesk(700);
}

///
/// Alternate Font Regular
///
@mixin alternate-default-font {
  @include Avant-Garde(300);
}

///
/// Alternate Font Medium
///
@mixin alternate-medium-font {
  @include Avant-Garde(500);
}

///
/// Alternate Font Bold
///
@mixin alternate-bold-font {
  @include Avant-Garde(700);
}