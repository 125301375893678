.instagram-feed {
    @include backend-preview-padding;
    align-items: start;
    display: grid;
    grid-template-columns: 1fr max-content;

    &__logo {
        height: size(28px);
        width: size(28px);
        fill: $colour-red;
        margin-bottom: spacing(10);
    }

    &__profile-link {

        &__label {
            @include u-visually-hide;
        }
    }

    &__items {
        --one-item-breakpoint: 600;
        --two-item-breakpoint: 767;
        --three-item-breakpoint: 1024;
        --four-item-breakpoint: 1366;

        display: contents;

        .splide {
            &__track {
                grid-column: 1 / -1;

                @include backend-specific {
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                }
            }

            &__arrows {
                margin-left: auto;
                display: flex;
                justify-content: space-between;
                pointer-events: none;
                
                &.is-arrows-disabled {
                    display: none;
                }
            }

            &__arrow {            
                align-items: center;
                padding: spacing(2);
                position: relative;
                pointer-events: auto;
                
                &--prev {
                    --degrees: 90deg;
                }
                &--next {
                    --degrees: -90deg;
                }
        
                svg {
                    height: spacing(4);
                    stroke-width: 2px;
                    stroke: $colour-grey-xdark;
                    transition: all 0.3s ease-in-out;
                    transform: rotate(var(--degrees));
                    opacity: 1;
                    width: spacing(4);
                }
    
                &[disabled] {
                    cursor: default;
                    
                    svg {
                        opacity: 0.3;
                    }
                }

                @include is-not-touch {
                    &:not([disabled]):hover {
                        svg {
                            stroke: currentColor;
                        }
                    }
                }
            }
        }

        &__item {
            position: relative;            

            @include backend-specific {
                &:nth-child(n+6) {
                    display: none;
                }
            }
            
            &__image {
                position: relative;
                overflow: hidden;

                &::before {
                    @include u-square-pseudo;
                }
                
                @include respond-upto($media-query-smallmedium) {
                    width: calc(100vw - ((var(--container-gutter-width) * 2) + #{$column-gutter-default * 2} + #{size(60px)}))!important;
                }
                @include respond-from($media-query-smallmedium) {
                    width: 100%;
                }

                &__inner {
                    @include u-image-cover;
                }
            }

            &__link {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                &__label {
                    @include u-visually-hide;
                }
            }
        }
    }

    &--theme {
        &-white {
            color: $colour-black;
        }
        &-black {
            color: $colour-white;

            @include backend-specific {
                background-color: $colour-black!important;
            }
        }
    }
}