// [Settings,][Tools,][Generic,][Base,][Objects,][Utilities,]
@import '../website/_settings.buttons';
@import '_tools.buttons';


///*------------------------------------
//  Object: Buttons
//  ------------------------------------*/

//map-get($button-style, '')
@mixin o-button ($button-style: $button-style-positive, $media-query: $site__desktop-breakpoint, $defaultTypography: 'v1') {
  @if $media-query {
    @include respond-upto($media-query) {
      @include button(map-get($button-style, 'padding-mobile'),
                      map-get($button-style, 'border-style'),
                      map-get($button-style, 'border-width'),
                      map-get($button-style, 'hover-border-style'),
                      map-get($button-style, 'hover-border-width'));
    }
    @include respond-from($media-query) {
      @include button(map-get($button-style, 'padding'),
                      map-get($button-style, 'border-style'),
                      map-get($button-style, 'border-width'),
                      map-get($button-style, 'hover-border-style'),
                      map-get($button-style, 'hover-border-width'));
    }
  }
  @else {
    @include button(map-get($button-style, 'padding'),
                      map-get($button-style, 'border-style'),
                      map-get($button-style, 'border-width'),
                      map-get($button-style, 'hover-border-style'),
                      map-get($button-style, 'hover-border-width'));
  }
  @if $defaultTypography == 'v1' {
    @include button-typography;
  }
  @else if $defaultTypography == 'v2' {
    @include button-typography-v2;
  }

  background-color: map-get($button-style, 'bgnd-colour');
  @if map-get($button-style, 'border-width') > 0 { border-color: map-get($button-style, 'border-colour'); }
  @if map-get($button-style, 'border-radius') > 0 { border-radius: map-get($button-style, 'border-radius'); }
  color: map-get($button-style, 'label-colour');
  transition: border 0.4s $easeOutQuad, color 0.4s $easeOutQuad, background 0.4s $easeOutQuad;

  &.focus-visible:not(:disabled),
  &:hover:not(:disabled) {
    background-color: map-get($button-style, 'hover-bgnd-colour');
    @if map-get($button-style, 'hover-border-colour') != map-get($button-style, 'border-colour') { border-color: map-get($button-style, 'hover-border-colour'); }
    @if map-get($button-style, 'hover-label-colour') != map-get($button-style, 'label-colour') { color: map-get($button-style, 'hover-label-colour'); }
    transition-duration: 0.35s; 
    transition-timing-function: $easeInOutQuad;
  }
}


///
///
///
// 100% width
@mixin o-button--full {
  width:100%;
  text-align: $button-full-text-align;
}
