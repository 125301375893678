@import './_parRteLongformContent.scss';

.rte-longform {
    $this: '.rte-longform';

    @include rte-content-styles(#{$this}__text);

    &__text {
        @include rte-content-base;
        width: 100%;
    }
}