.development-hero {
    @include container;
    min-height: unquote('max((var(--vh, 1vh) * 100), #{size(500px)})');
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-top: calc(var(--site-header-height) + var(--mobile-listing-header-height));
    
    @include frontend-specific {
        z-index: 0;
    }
    @include backend-specific {
        z-index: 1;
    }
    
    @include reduced-motion {
        position: relative;
    }
    
    &__content {
        margin: auto 0;
        position: relative;
        width: 100%;

        @include respond-from($site__desktop-breakpoint) {
            column-gap: #{$column-gutter-default * 2};
            display: grid;
            grid-template-columns: repeat(12, 1fr);            
        }
    }
    
    &__image {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba($colour-black, 0.5);
        }

        &__inner {
            @include u-image-cover;
            z-index: -1;
        }
    }

    &__title,
    &__body-text,
    &__scroll-indicator {
        z-index: 0;
    }

    &__title,
    &__body-text {
        position: relative;
    }

    &__title {
        @include alternate-default-font;
        grid-column: 1 / span 5;
        margin: 0;

        @include respond-upto($site__tablet-breakpoint) {
            @include font-size($font-size-xlarge);
        }
        @include respond-from($site__tablet-breakpoint) {
            @include font-size($font-size-xxxxlarge);
        }
    }

    &__body-text {
        @include light-font;
        @include font-size($font-size-small);
        grid-column: 7 / span 4;        

        @include respond-upto($site__desktop-breakpoint) {            
            margin-top: var(--line-height-em);
        }

        p {
            max-width: 65ch;
            &:not(:last-child) {
                margin-bottom: var(--line-height-em);
            }
        }
    }

    &__scroll-indicator {
        @include container;
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-bottom: spacing(5);
        width: 100%;
        
        @include respond-upto($site__tablet-breakpoint) {
            margin-bottom: spacing(5);
        }
        @include respond-from($site__tablet-breakpoint) {
            margin-bottom: spacing(20);
        }
        
        &__icon {
            height: spacing(6);
            stroke-width: 3px;
            stroke: currentColor;
            transform: scaleY(-1);
        }
    }

    &--theme {
        &-black {
            background-color: $colour-black;            
            color: $colour-white;            
        }
        &-white {
            background-color: $colour-white;            
            color: $colour-black;            
        }
    }

    @media screen and (min-width: get-breakpoint($site__desktop-breakpoint, $breakpoints)), screen and (min-height: size(750px, $unit-em)) {
        position: sticky;
        top: 0;
    }
}