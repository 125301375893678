.property-listing-landing {
    $this: '.property-listing-landing';
    
    @include container($applyPadding: false);
    @include u-fill-viewport;
    display: flex;
    margin-top: calc(-1 * var(--site-header-height));
    min-height: size(500px);

    @include respond-upto($site__desktop-breakpoint) {
        flex-direction: column;
    }

    &__type {
        color: $colour-white;
        overflow: hidden;
        position: relative;
        z-index: 0;
        
        @include respond-upto($site__desktop-breakpoint) {
            flex: 1 1 50%;
        }
        @include respond-from($site__desktop-breakpoint) {
            width: 50vw;
        }
        
        &:before {
            background-color: rgba($colour-black, 0.5);
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transition: background-color 0.8s $easeInOutSine;
        }

        &:hover {
            &::before {
                background-color: rgba($colour-black, 0.65);
            }

            #{$this}__type__image img {
                transform: scale(1.01);
            }
        }

        &__image {
            img {
                @include u-image-cover;
                transform: scale(0);
                transition: transform 0.8s $easeInOutSine;
                z-index: -1;
            }
        }

        &__link {
            display: flex;
            align-items: center;
            position: absolute;
            transition: color 0.8s $easeInOutSine;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-decoration: none;

            @include respond-upto($site__desktop-breakpoint) {
                text-align: center;
            }

            &__content {
                display: flex;
                padding: 0 (calc(100% / 6));

                > * {
                    margin: 0 $column-gutter-default;
                    flex-grow: 0;
                }

                @include respond-upto($site__desktop-breakpoint) {
                    justify-content: center;
                    width: 100%;
                }
            }
        }

        &__text {
            @include header--medium;
            line-height: 1;
            order: var(--textColumn);
            width: min-content;
        }

        &__triangle,
        &__logo {
            grid-row: 1;

            @include respond-upto($media-query-smallmedium) {
                display: none;
            }
        }

        &__triangle {
            transform: scaleY(-1)scaleX(var(--scaleTriangleX, -1));
            height: spacing(4);
            order: var(--triangleColumn);
            width: spacing(8);
        }

        &__logo {
            height: spacing(20);
            order: var(--logoColumn);
            width: spacing(20);
        }

        &:nth-child(odd) {            
            #{$this}__type {
                &__link {
                    --triangleColumn: 1;
                    --textColumn: 2;
                    --logoColumn: 3;
                    
                    margin-left: var(--container-gutter-width);
                    
                    @include respond-upto($site__desktop-breakpoint) {
                        margin-right: var(--container-gutter-width);
                    }

                    &__content {
                        @include respond-from($site__desktop-breakpoint) {
                            padding-right: (calc(100% / 6));
                            position: absolute;
                            right: 0;
                            text-align: right;
                        }
                    }
                }

                &__logo,
                &__triangle {
                    justify-self: end;
                }
            }
        }

        &:nth-child(even)  {
            #{$this}__type {
                &__link {
                    --scaleTriangleX: 1;

                    --triangleColumn: 3;
                    --logoColumn: 1;
                    --textColumn: 2;
                    padding-right: var(--container-gutter-width);

                    @include respond-upto($site__desktop-breakpoint) {
                        padding-left: var(--container-gutter-width);
                    }

                    &__content {
                        @include respond-from($site__desktop-breakpoint) {
                            padding-left: (calc(100% / 6));
                        }
                    }
                }
            }
        }
    }
}