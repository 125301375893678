.intro-text {
    $this: '.intro-text';

    color: var(--text, #{$colour-grey-dark});
    @include alternate-default-font;
    
    @include respond-upto($site__tablet-breakpoint) {
        @include font-size($font-size-medium);
    }
    @include respond-from($site__tablet-breakpoint) {
        @include font-size($font-size-xxlarge);
    }

    @include backend-specific {
        color: $colour-black!important;
    }

    p {
        &:not(:last-child) {
            margin-bottom: 1.375em;
        }
    }
}