.development-detail {
    $this: '.development-detail';

    $distanceToViewportEdge: var(--page-nav-v2-height);

    @function column-calc($columnCount: 1) {
        $total-horizontal-margin: (($column-gutter-default * 2) * (12 - $columnCount));
        $total-space-available: '((100vw - (var(--container-gutter-width))) - #{$total-horizontal-margin})';
        @return calc(((#{$total-space-available} / 12)) * #{$columnCount});
    }
    padding: $distanceToViewportEdge 0;

    --media-query:  (min-width: #{get-breakpoint($site__tablet-breakpoint)})    and (min-height: #{size(730px, $unit-em)}),
                    (min-width: #{get-breakpoint($site__desktop-breakpoint)})   and (min-height: #{size(820px, $unit-em)});
    
    &__content {
        align-items: start;
        display: grid;
        position: relative;

        &__wrapper,
        &::before {
            grid-column: 1;
            grid-row: 1;
        }

        &__wrapper {
            
        }
        
        &__track {
            @include container;
        }        
    }
    
    &__support-title {
        @include container;
        @include header--support-title;
        @include o-support-title-animation;
        --translate-x-distance: 20%;
        margin-bottom: spacing(12);
        flex: 0 0 auto;
        text-align: center;
        width: 100%;
    }
    
    &__section-tag {
        align-self: flex-start;

        @include respond-from($site__tablet-breakpoint) {
            width: column-calc(1);
        }
        
        &__text {
            @include o-section-tag($breakpoint: 0em);            
        }
    }

    &__detail {
        > * {
            max-width: size(580px);
        }
    }

    &__title {
        @include alternate-medium-font;
        @include font-size($font-size-xlarge);
        margin-bottom: spacing(6);
    }

    &__body-text {
        @include font-size($font-size-xsmall);
    }
    
    &__body-text {
        p {
            @include default-font;

            &:not(:last-child) {
                margin-bottom: var(--line-height-em);
            }
        }
    }
    &__features {
        column-gap: $column-gutter-default * 2;
        display: grid;
        margin-top: spacing(12);
        row-gap: spacing(6);

        @include respond-upto($media-query-small) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include respond-from($media-query-small) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &__feature {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: spacing(8);

            &__icon {
                width: spacing(16);
            }
            &__text {
                @include default-font;
                @include font-size($font-size-small);
                text-align: center;
            }
        }
    }
    &__image-slide {
        @include header--support-title;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;

        &--first #{$this}__image img { 
            @include o-notched-corner($corner: 'top-left', $height: size(67px));
        }

        &--last #{$this}__image img { 
            @include o-notched-corner($corner: 'bottom-right', $height: size(67px));
        }
    }
    &__image {
        img {
        }
    }
    &__images-support-title {
        @include o-support-title-animation;
        text-align: center;
        white-space: nowrap;
        padding: 0 spacing(3);        
        z-index: 1;

        --percent: var(--scroll-percent);
        --translate-x-distance: 20%;
    }

    @include frontend-specific {
        &:not(.is-scrollable) {
            --scroll-percent: 100!important;
    
            #{$this} {
                &__images-support-title {
                    @include u-visually-hide;
                }
                &__content {
                    &__wrapper {
                        
                    }
                }
                &__detail,
                &__image-slide {
                    &:not(:last-child) {
                        margin-bottom: spacing(8);
                    }
                }
                &__section-tag {
                    &__text {
                        writing-mode: initial;
                        transform: none;
                    }
                }
            }
        }
    }
    
    &.is-scrollable {
        &.is-fixed #{$this}__content__wrapper {
            position: fixed;
            top: calc(#{$distanceToViewportEdge} + #{spacing(10)});
        }
    
        &:not(.is-fixed) {
            &.is-closer-to-top #{$this}__content__wrapper{
                position: absolute;
                top: #{spacing(10)};
            }
            &.is-closer-to-bottom #{$this}__content__wrapper{
                position: absolute;
                bottom: #{spacing(-10)};
            }
        }

        #{$this} {            
            &__content {
                &::before {
                    content: '';
                    display: block;
                    height: unquote('max((var(--track-width) * 1px), 100vh)');
                }
                &__track {
                    display: flex;
                    flex: 1 1 auto;
                    min-height: size(280px);
                    will-change: transform;
                    width: max-content;                                

                    &--support-title {
                        padding-bottom: size(36px);
                    }
                    
                    > * {                
                        flex-shrink: 0;
                    }

                    > div {                
                        margin-right: $column-gutter-default * 2;
                    }

                    // Don't fully scroll track (stop once the track's end gets to the right hand of the screen)
                    --final-position: calc((-100% + 100vw) * (var(--scroll-percent, 0) / 100));
    
                    // Don't allow the track to be positively transformed (maximum positive number = 0) 
                    --capped-percent: min(0%, var(--final-position));
                    
                    transform: translate3d(var(--capped-percent), 0, 0);
                }
                &__wrapper {
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    width: 100vw;
                    height: calc((var(--vh, 1vh) * 100) - (var(--page-nav-v2-height) + #{spacing(10)}));
                }
            }
            &__image {
                img {
                    @include u-image-cover;
                }
            }
            &__image-slide {
                @include respond-upto($media-query-mediumlarge) {
                    width: calc(90vw - #{$column-gutter-default * 2});
                }
                @include respond-from($media-query-mediumlarge) {
                    width: calc(50vw - #{$column-gutter-default * 2});
                }
            }
            &__images-support-title {
                margin-bottom: calc(-0.5 * var(--line-height-em));
            }
            &__detail {
                overflow-y: auto;

                @include respond-upto($site__tablet-breakpoint) {
                    width: calc(100vw - (var(--container-gutter-width) + #{($column-gutter-default * 2)} + #{spacing(6) + spacing(10)}));
                }
                @include respond-between($site__tablet-breakpoint, $site__desktop-breakpoint) {
                    width: column-calc(10);
                }
                @include respond-from($site__desktop-breakpoint) {
                    width: column-calc(6);
                }
            }
            &__support-title {
                --percent: var(--scroll-percent)!important;
            }
        }
    }

    @include backend-specific {
        overflow: hidden;
        
        #{$this} {
            &__content__track {
                display: flex;
                flex: 1 1 auto;
                will-change: transform;
                width: max-content;

                > * {                
                    flex-shrink: 0;
                }

                > div {                
                    margin-right: $column-gutter-default * 2;
                }
            }

            &__image-slide {
                width: calc(50vw - #{$column-gutter-default * 2});
            }
            &__detail {
                width: 35vw;
            }
        }
    }
}
