//*------------------------------------
//  Utility : Visually Hide
//  ------------------------------------*/

////
/// @group -Library-
////

///
/// Hide element visually, but accessible by screen-readers (avoids using visibility: hidden/ display: none etc.)
///
@mixin u-visually-hide {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.visually-hide {
	@include u-visually-hide;
}