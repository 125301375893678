@mixin o-section-tag($includeHyphen: true, $breakpoint: $media-query-mediumlarge) {
    @include default-font;
    @include font-size($font-size-small);

    @include respond-from($breakpoint) {
        writing-mode: vertical-rl;
        transform: rotate(180deg);
    }

    @if $includeHyphen {
        &::before {
            content: '- ';
        }
    }
}