.image-gallery {
    $this: '.image-gallery';

    &__support-title {
        @include header--support-title;
        @include o-support-title-animation;
        --translate-x-distance: 20%;
        margin-bottom: calc(var(--line-height-em) * -0.5);
        position: relative;
        z-index: 1;
    }
    &__images {
        position: relative;
        margin-left: calc(-1* var(--container-gutter-width));

        @include frontend-specific {
            width: 100vw;
        }
        @include backend-specific {
            width: 100%;
        }

        &__image {
            overflow: hidden;
            position: relative;

            &__caption {
                $horizontalPadding: spacing(8);

                @include u-ratio(440, 182, $property: 'padding-top');
                align-items: flex-end;
                color: $colour-white;
                display: flex;
                bottom: 0;
                left: 0;
                max-height: 100%;
                max-width: 100%;
                position: absolute;
                isolation: isolate;
                
                &__content {
                    width: max-content;
                    padding: spacing(10) $horizontalPadding;
                    max-width: 100%;
                    position: relative;

                    &::before {
                        @include u-ratio(440, 182, $property: 'padding-top');
                        @include o-triangle('bottom-left');
                        background-color: rgba($colour-black, 0.5);
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        min-width: calc(140% + #{$horizontalPadding * 2});
                        min-height: 100%;
                        z-index: -1;
                    }
                }                

                &__slide-count {
                    @include alternate-default-font;
                    @include font-size($font-size-xsmall);
                    display: block;
                }

                &__text {
                    @include alternate-medium-font;
                    @include font-size($font-size-large);
                    @include u-ellipsis;
                    display: block;
                    max-width: calc(100vw - #{$horizontalPadding * 2});
                }
            }
        }

        > .splide {
            &__arrows.is-arrows-disabled {
                display: none;
            }

            &__track {
                min-height: 1px;
            }

            &__arrows {
                @include container;
                align-items: center;
                display: flex;
                justify-content: space-between;
                position: absolute;
                gap: size(10px);
                z-index: 3;
                
                bottom: 0;
                right: 0;
                transform: translateY(50%);

                > .splide__arrow {
                    align-items: center;
                    background-color: $colour-red;
                    display: flex;
                    opacity: 1;
                    justify-content: center;
                    transition: opacity 0.3s $easeInOutQuart;
                    z-index: 1;

                    @include respond-upto($site__tablet-breakpoint) {
                        height: size(40px);
                        width: size(40px);
                    }

                    @include respond-from($site__tablet-breakpoint) {
                        height: size(75px);
                        width: size(75px);
                    }

                    &[disabled] {
                        cursor: default;
                        opacity: 0;
                    }

                    &--prev {
                        transform: rotate(90deg);
                    }
                    &--next {
                        transform: rotate(-90deg);
                    }

                    svg {
                        stroke: $colour-white;

                        @include respond-upto($site__tablet-breakpoint) {
                            height: size(16px);
                            width: size(16px);
                        }

                        @include respond-from($site__tablet-breakpoint) {
                            height: size(22px);
                            width: size(22px);
                        }
                    }
                }
            }
        }
    }

    &__cta-content {
        margin-top: spacing(10);

        @include respond-from($site__desktop-breakpoint) {
            display: grid;
            grid-template-columns: repeat(12, minmax(0, 1fr));
            column-gap: var(--column-gap);
        }
    }

    &__title {
        @include alternate-medium-font;
        @include font-size($font-size-xxlarge);
        grid-column: 2 / span 7;        
        margin-bottom: spacing(4);
        max-width: 15ch;
    }

    &__body-text {
        @include default-font;
        @include font-size($font-size-xsmall);
        grid-column: 2 / span 4;
        max-width: 45ch;
    }

    &__link {
        @include o-button-v2--black;
        align-self: end;
        grid-column: 6 / span 3;

        @include respond-upto($site__desktop-breakpoint) {
            margin-top: spacing(4);
        }

        span {
            margin: 0 auto;
        }
    }

    @include backend-specific {
        .splide__slide {
            &:not(:first-child) {
                display: none;
            }
        }
    }
}
