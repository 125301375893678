.stat-list {
    $this: '.stat-list';

    color: $colour-white;
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;

    @include backend-specific {
        color: $colour-black;
    }

    @include respond-upto($media-query-mediumlarge) {
        position: relative;
        max-width: max-content;
        margin: 0 auto;
    }

    @include respond-from($media-query-mediumlarge) {
        grid-template-columns: max-content 1fr max-content;
    }

    &::before,
    &::after {
        background-color: $colour-grey-new-3;
        content: '';
        display: block;

        height: size(30px);
        width: size(60px);

        @include respond-from($media-query-mediumlarge) {
            height: size(50px);
            width: size(100px);
        }
    }

    &::before {
        @include o-triangle('top-left');
        grid-column: 1;

        @include respond-from($media-query-mediumlarge) {
            position: relative;
            left: size(30px);
        }
    }
    &::after {
        @include o-triangle('bottom-right');     
        grid-column: 1;

        @include respond-upto($media-query-mediumlarge) {
            position: absolute;
            right: 0;
            bottom: 0;
        }

        @include respond-from($media-query-mediumlarge) {
            grid-column: 3;
            grid-row: 3;
        }
    }

    &__stats {
        display: flex;
        flex-direction: column;
        grid-row: 2;
        padding: spacing(5) 0;

        @include respond-from($media-query-mediumlarge) {
            grid-column: 2;
            flex-direction: row;
            padding: 0;
        }
    }
    &__stat {
        flex: 1 1 0%;

        margin-bottom: spacing(16);

        display: flex;
        flex-direction: column;

        @include respond-from($media-query-mediumlarge) {
            &:not(:last-child) {
                > *:not(#{$this}__stat__icon) {
                    border-right: 1px solid $colour-grey-new-3;
                }
            }
        }

        &__icon {
            height: size(50px);
            margin-bottom: spacing(5);

            @include respond-from($media-query-mediumlarge) {
                margin-bottom: spacing(12);
            }
        }
        &__value {
            @include font-size($font-size-xxxlarge);
            @include alternate-bold-font;
        }
        &__number {

        }
        &__unit-prefix {
        }
        &__unit-suffix {
        }
        &__text {
            @include default-font;
            @include font-size($font-size-small);
        }
    }

    &__svg {
        &.is-in-view:not(.is-tab-defocused) > .northern-logo-pattern {
            animation-play-state: running;
        }
    }
    
    .northern-logo-pattern {
        animation-play-state: paused;
        position: absolute;
        z-index: -1;

        @include respond-upto($site__desktop-breakpoint) {
            display: none;
        }
        @include respond-from($site__desktop-breakpoint) {
            max-height: 100%;
            width: 48%;
            max-width: size(920px);
            left: -3%;
            bottom: -25%;
        }
        
        path {
            --filled-colour: #{$colour-white};
            --empty-colour: #{rgba($colour-white, 0)};
            animation-play-state: inherit!important;
            fill: var(--empty-colour);
            stroke: $colour-white;
            stroke-width: 0.23;
            stroke-miterlimit: 10;
            
            &.is-animating {
                animation: northern-logo-background-fill 5s forwards 1;
            }
        }
    }
}
