.grid-builder {
    $this: '.grid-builder';

    $columnCount: 12;
    background-color: var(--background-fallback);
    overflow-x: hidden;
    overflow-x: clip;
    position: sticky;
    top: 0;
    z-index: 1;

    $baseValue: size($rem-base);
    $spacingProperties: (
        padding: (
            top,
            bottom
        ),
        margin: (
            top,
            bottom
        )
    );

    .page--dark & {
        --background-fallback: #{$colour-black};
    }
    .page--light & {
        --background-fallback: #{$colour-white};
    }

    &__row {
        background: var(--background, '');
        background-color: var(--background-colour, var(--background-fallback));
        column-gap: var(--column-gap);
        display: grid;
        grid-template-columns: repeat(#{$columnCount}, 1fr);
        position: relative;
        justify-content: start;
        row-gap: $column-gutter-default * 2;
        --content-shift: 0;

        z-index: 1;

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: var(--container-gutter-width, 0);
            right: var(--container-gutter-width, 0);
        }
        &::before {
            border-top: var(--border-top, 0) solid var(--border-top-colour, transparent);
            top: 0;
        }
        &::after {
            border-bottom: var(--border-bottom, 0) solid var(--border-bottom-colour, transparent);
            bottom: 0;
        }

        // Collapse all column gaps on mobile only
        @include respond-upto($media-query-smallmedium) {
            --column-gap: 0;
        }
        @include respond-from($media-query-smallmedium) {
            --column-gap: #{$column-gutter-default * 2};
        }

        // Row number controls column stacking
        @include respond-upto($media-query-small) {
            --row-number: var(--row-number--small);
            --row-animate-delay: var(--row-animate-delay--small, 0);
        }
        @include respond-between($media-query-small, $media-query-medium) {
            --row-number: var(--row-number--medium);
            --row-animate-delay: var(--row-animate-delay--medium, 0);
        }
        @include respond-between($media-query-medium, $media-query-large) {
            --row-number: var(--row-number--large);
            --row-animate-delay: var(--row-animate-delay--large, 0);
        }
        @include respond-from($media-query-large) {
            --row-number: var(--row-number--xlarge);
            --row-animate-delay: var(--row-animate-delay--xlarge, 0);
        }

        @each $propertyType, $propertyDirections in $spacingProperties {
            @each $direction in $propertyDirections {
                $propertyFull: #{$propertyType}-#{$direction};
                
                @include respond-upto($media-query-small) {
                    #{$propertyFull}: calc(var(--#{$propertyFull}--small, 0) * #{$baseValue});
                }
                @include respond-between($media-query-small, $media-query-medium) {
                    #{$propertyFull}: calc(var(--#{$propertyFull}--medium, 0) * #{$baseValue});
                }
                @include respond-between($media-query-medium, $media-query-large) {
                    #{$propertyFull}: calc(var(--#{$propertyFull}--large, 0) * #{$baseValue});
                }
                @include respond-from($media-query-large) {
                    #{$propertyFull}: calc(var(--#{$propertyFull}--xlarge, 0) * #{$baseValue});
                }
            }
        }        
                
        @include respond-upto($media-query-small) {
            --content-shift: var(--content-shift--small, 0);
        }
        @include respond-between($media-query-small, $media-query-medium) {
            --content-shift: var(--content-shift--medium, 0);
        }
        @include respond-between($media-query-medium, $media-query-large) {
            --content-shift: var(--content-shift--large, 0);
        }
        @include respond-from($media-query-large) {
            --content-shift: var(--content-shift--xlarge, 0);
        }
        
        &--container {
            @include container;

            #{$this}__col {
                // Looks complicated but it isn't too bad really.
                // Placed in quotes to avoid SCSS running it's own implementation of 'min' function
                // Uses min function to find the smallest (most negative) value (so only container-gutter-margin OR container-gutter-margin overrides are applied)
                
                margin-left: unquote('min(      (var(--container-gutter-margin) * (var(--override-gutter-margin-left) * -1)),   (var(--container-gutter-width) * (var(--override-gutter-width-left) * -1))  )');
                margin-right: unquote('min(     (var(--container-gutter-margin) * (var(--override-gutter-margin-right) * -1)),  (var(--container-gutter-width) * (var(--override-gutter-width-right) * -1)) )');

                @include respond-upto($media-query-small) {
                    --override-gutter-margin-left: var(--override-gutter-margin-left--small, 0);
                    --override-gutter-margin-right: var(--override-gutter-margin-right--small, 0);
                    
                    --override-gutter-width-left: var(--override-gutter-width-left--small, 0);
                    --override-gutter-width-right: var(--override-gutter-width-right--small, 0);
                }
                @include respond-between($media-query-small, $media-query-medium) {
                    --override-gutter-margin-left: var(--override-gutter-margin-left--medium, 0);
                    --override-gutter-margin-right: var(--override-gutter-margin-right--medium, 0);
                    
                    --override-gutter-width-left: var(--override-gutter-width-left--medium, 0);
                    --override-gutter-width-right: var(--override-gutter-width-right--medium, 0);
                }
                @include respond-between($media-query-medium, $media-query-large) {
                    --override-gutter-margin-left: var(--override-gutter-margin-left--large, 0);
                    --override-gutter-margin-right: var(--override-gutter-margin-right--large, 0);
                    
                    --override-gutter-width-left: var(--override-gutter-width-left--large, 0);
                    --override-gutter-width-right: var(--override-gutter-width-right--large, 0);
                }
                @include respond-from($media-query-large) {
                    --override-gutter-margin-left: var(--override-gutter-margin-left--xlarge, 0);
                    --override-gutter-margin-right: var(--override-gutter-margin-right--xlarge, 0);
                    
                    --override-gutter-width-left: var(--override-gutter-width-left--xlarge, 0);
                    --override-gutter-width-right: var(--override-gutter-width-right--xlarge, 0);
                }
            }
        }

        &--content-shift {
            #{$this}__col {
                transform: translateY(calc(var(--content-shift) * #{$baseValue}));
            }
        }
    }

    &__col {
        align-self: var(--align-vertical);
        grid-column: var(--column);
        // Controls stacking- uses var(--row-number) (i.e. 1 if not stacked), fallsback to using index if not set
        grid-row: var(--row-number, var(--index)) / span 1;
        justify-self: var(--align-horizontal);

        --animate-external-delay: calc((var(--row-animate-delay) * var(--column-animate-delay)) * 1s);

        @include respond-upto($media-query-small) {
            --column: var(--column--small);
        }
        @include respond-between($media-query-small, $media-query-medium) {
            --column: var(--column--medium);
        }
        @include respond-between($media-query-medium, $media-query-large) {
            --column: var(--column--large);
        }
        @include respond-from($media-query-large) {
            --column: var(--column--xlarge);
        }
    }

    &__angled {
        height: 108px;
        background: white;
    }

    &__angled--top {

        height: 110px;
        clip-path: polygon(0% 0%, 100% 10%, 100% 101%, 0 101%); 

        @include respond-from($site__desktop-breakpoint) {
            clip-path: polygon(0% 0%, 100% 47%, 100% 101%, 0 101%); 
        }
    }

    &__angled--bottom {

        height: size(110px);
        clip-path: polygon(0% -1%, 100% -1%, 100% 100%, 0 70%);

        @include respond-from($site__desktop-breakpoint) {
            clip-path: polygon(0% -1%, 100% -1%, 100% 100%, 0 47%);
        }
    }
}