.article-listing {
    $summaryMinWidth: size(340px);
    $summaryMaxWidth: size(548px);
    
    &__main {
        @include container;
        padding-top: spacing(24);
        padding-bottom: spacing(16);
        
        @include respond-from($site__desktop-breakpoint) {
            padding-top: spacing(32);
        }
    }
    
    &__articles {
        display: grid;
        column-gap: $column-gutter-default * 3;
        row-gap: spacing(16);
        justify-items: center;
        
        @include respond-from(size(($summaryMinWidth + ($column-gutter-default * 2)), $unit-em)) {
            grid-template-columns: repeat(auto-fit, minmax($summaryMinWidth, 1fr));
        }
        
        &__article {
            max-width: $summaryMaxWidth;
        }
    }

    &__pagination {
        margin: spacing(16) auto 0;
        width: fit-content;
    }
}
