.development-detail-hero {
    $this: '.development-detail-hero';
    
    --min-map-height-mobile: #{size(455px)};
    // $angle: 28.777deg;

    display: flex;
    margin-top: var(--site-header-height);
    min-height: calc(((var(--vh, 1vh) * 100)) - var(--site-header-height));
    position: relative;
    isolation: isolate;
    z-index: 2;
    
    @include respond-upto($site__desktop-breakpoint) {
        align-content: flex-end;
        align-items: flex-end;
        flex-wrap: wrap;
    }
    @include respond-from($site__desktop-breakpoint) {
        flex-direction: column;
    }

    &__background {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            
            @include respond-upto($site__desktop-breakpoint) {
                background: linear-gradient(0deg, rgba($colour-black, 0.8) 0%, rgba($colour-black, 0) 100%);
                top: 0;
            }
            @include respond-from($site__desktop-breakpoint) {
                background: linear-gradient(0deg, rgba($colour-black, 0.6) 0%, rgba($colour-black, 0) 100%);
                top: 40%;
            }
        }
    }

    &__background-image {
        display: block;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        
        img {
            @include u-image-cover;
            pointer-events: all;
        }
    }

    &__title {
        @include container;
        @include alternate-default-font;
        color: $colour-white;
        margin-top: auto;
        padding-bottom: spacing(10);
        padding-top: spacing(10);

        &__text {            
            display: block;
            
            @include respond-upto($site__tablet-breakpoint) {
                @include font-size($font-size-xxlarge);
            }
            @include respond-from($site__tablet-breakpoint) {
                @include font-size($font-size-xxxxlarge);
            }

            @include respond-upto($site__desktop-breakpoint) {
                max-width: 18ch;
            }
            @include respond-from($site__desktop-breakpoint) {
                max-width: unquote('min(18ch, 50%)');
            }
        }
        
    }

    &__map {
        transform: skew(var(--angle));
        transform-origin: left top;
        height: 100%;
        overflow: hidden;
        margin-left: auto;
        position: relative;
        z-index: 1;
        
        @include respond-upto($site__desktop-breakpoint) {
            display: none;
        }
        @include respond-from($site__desktop-breakpoint) {
            @include u-ratio(25, 32);
            --angle: 26.883deg;
            width: 50%;
        }

        > * {
            transform: skew(calc(-1 * var(--angle)));            
            transform-origin: inherit;
        }
    }

    &__summary {
        @include o-notched-corner($corner: 'top-left', $height: spacing(12), $ratio-height: 56, $ratio-width: 61);
        @include font-size($font-size-xsmall);
        background-color: rgba($colour-black, 0.85);
        color: $colour-white;
        max-width: size(245px);
        padding: spacing(12) spacing(8) spacing(5) spacing(16);
        
        @include respond-upto($site__desktop-breakpoint) {
            margin-left: auto;
        }
        @include respond-from($site__desktop-breakpoint) {
            position: absolute;
            bottom: 0;
            right: 0;
            max-height: 100%;
            overflow-y: auto;
        }

        &__title {
            @include default-font;
            text-transform: uppercase;
        }

        &__title,
        &__items__item {
            &:not(:last-child) {
                margin-bottom: var(--line-height-em);
            }
        }

        &__title {
        }
        &__items {
            &__item {
                @include light-font;
            }
        }
    }
}
