.modal {
    @include u-fill-viewport(min-height);
    align-items: stretch;
    background-color: $colour-white;
    display: flex;
    flex-direction: column;
    padding: spacing(4) spacing(8) spacing(8);
    justify-content: flex-start;
    width: 100%;
    
    @include respond-from($media-query-small) {
        width: 50%;
        min-width: calc(#{map-get($breakpoints, $media-query-small)} - (#{$column-gutter-default} * 2));  /* == breakpoint - left+right margin */
        min-height: auto;
        box-shadow: 0 19px 38px rgba($colour-black, 0.12), 0 15px 12px rgba($colour-black, 0.22);
    }
    
    &__close {
        @include dialog__close;
        margin-bottom: spacing(4);
    }

    &__form {
        &.is-loading {
            .form__submit {
                &::before {
                    @include o-loading-spinner($centreHorizontally: false, $centreVertically: false);
                    margin-right: 0.5em;
                    margin-right: 0.75ch;
                }
            }
        }
    }
}