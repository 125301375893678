blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

svg {
  fill: currentColor;
  pointer-events: none;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
}

img {
  display: block;
  height: auto;
  min-height: 1px;
  min-width: 1px;
  max-width: 100%;
  width: fit-content;
}

label,
input,
textarea,
select,
option {
  padding: 0;
}