@mixin triangle-cta-grid__triangle {
    background-color: $colour-grey-new-0;
    content: '';
    position: absolute;                    
    z-index: -1;
}
@mixin triangle-cta-grid__triangle--vertical {
    @include u-ratio(1,2);
    max-width: size(440px);
    width: 100%;
}
@mixin triangle-cta-grid__triangle--horizontal {
    @include u-ratio(2, 1);
    max-width: size(880px);
    width: 200%;    
}
.triangle-cta-grid {
    $this: '.triangle-cta-grid';

    column-gap: var(--column-gap);
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    isolation: isolate;
    
    @include respond-upto($media-query-smallmedium) {
        &::before,
        &::after {
            @include triangle-cta-grid__triangle;
            @include u-ratio(1,2);
            height: 45%;
        }
        &::before {
            @include o-triangle('bottom-left');
            left: 0;
            top: 0;
        }
        &::after {
            @include o-triangle('top-right');
            bottom: 0;
            right: 0;
        }
    }

    &__image,
    &__section-tag,
    &__title {
        isolation: isolate;
        z-index: 2;
    }

    &__section-tag {
        @include o-section-tag;
        grid-column: 1 / -1;
    }
    &__title {
        @include o-support-title-animation;
        @include header--support-title;        
        grid-column: 1 / -1;
        --translate-x-distance: 20%;

        @include respond-upto($media-query-smallmedium) {
            margin-bottom: spacing(8);
        }
        
        @include respond-between($media-query-smallmedium, $media-query-mediumlarge) {
            margin-bottom: spacing(12);
        }
        
        @include respond-from($media-query-mediumlarge) {
            margin-bottom: spacing(6);
            grid-column: 2 / -1;
        }
    }
    &__image--first,
    &__cta:nth-of-type(1) {
        @include respond-between($media-query-smallmedium, $media-query-mediumlarge) {
            margin-bottom: spacing(20);
        }
        @include respond-from($media-query-mediumlarge) {
            margin-bottom: spacing(12);
        }
    }
    &__image,
    &__cta {
        @include respond-upto($media-query-smallmedium) {
            grid-column: 1 / -1;

            &:not(:last-child) {
                margin-bottom: spacing(12);
            }
        }
        @include respond-between($media-query-smallmedium, $media-query-mediumlarge) {
            grid-column: span 6 / span 6;
        }
        @include respond-from($media-query-mediumlarge) {
            grid-column: span 5 / span 5;
        }
    }
    &__image,
    &__cta:nth-of-type(4) {
        @include respond-from($media-query-mediumlarge) {
            transform: translateX(calc(-1 * var(--column-gap)));
        }
    }

    &__cta {
        position: relative;
        
        &::before {
            @include triangle-cta-grid__triangle;
        }

        &:nth-of-type(2),
        &:nth-of-type(3) {
            @include respond-from($media-query-mediumlarge) {
                margin-bottom: spacing(20);
            }
        }
        &:nth-of-type(1),
        &:nth-of-type(4) {
            @include respond-from($media-query-mediumlarge) {                
                margin-top: spacing(6);
            }
        }

        &:nth-of-type(1) {
            @include respond-between($media-query-smallmedium, $media-query-mediumlarge) {
                &::before {
                    @include u-ratio(2, 1);
                    @include o-triangle('top-right');
                    right: calc(-1 * var(--container-gutter-width));
                    top: spacing(-20);
                    width: 100vw;
                }
            }
            @include respond-from($media-query-mediumlarge) {
                grid-column-start: 7;
                
                &::before {
                    @include triangle-cta-grid__triangle--horizontal;
                    @include o-triangle('top-left');
                    transform: translate(10%, calc(-10% - #{spacing(6)}));
                    right: 0;                
                }
            }
        }
        &:nth-of-type(2) {
            @include respond-between($media-query-smallmedium, $media-query-mediumlarge) {
                align-self: start;

                &::before {
                    @include u-ratio(2, 1);
                    @include o-triangle('bottom-left');
                    left: calc(-1 * var(--container-gutter-width));
                    bottom: 0;
                    width: 100vw;
                }
            }
            @include respond-from($media-query-mediumlarge) {
                align-self: stretch;
                grid-column-start: 2;
                
                &::before {
                    @include triangle-cta-grid__triangle--vertical;
                    @include o-triangle('bottom-left');
                    transform: translate(calc(-25% - var(--column-gap)), -50%);
                }
            }
        }
        &:nth-of-type(3) {
            @include respond-between($media-query-smallmedium, $media-query-mediumlarge) {
                margin-top: spacing(24);            
                margin-bottom: spacing(24); 
            }
            @include respond-from($media-query-mediumlarge) {
                grid-column-end: -1;
                padding-top: spacing(24);
                margin-left: calc(var(--column-gap) / 2);
                padding-right: calc(var(--column-gap) / 2);

                &::before {
                    @include triangle-cta-grid__triangle--vertical;
                    @include o-triangle('top-right');
                    top: 0;
                    right: 0;
                }
            }
        }
        &:nth-of-type(4) {
            @include respond-between($media-query-smallmedium, $media-query-mediumlarge) {     
                &::before {
                    @include u-ratio(2, 1);
                    @include o-triangle('top-left');
                    left: calc(-1 * var(--container-gutter-width));
                    top: spacing(5);
                    width: 100vw;
                }
            }
            @include respond-from($media-query-mediumlarge) {
                grid-column-start: 3;

                &::before {
                    @include triangle-cta-grid__triangle--horizontal;
                    @include o-triangle('bottom-right');
                    @include u-ratio(2, 1);
                    transform: translate(calc(-25% + (var(--column-gap)) * 1.5), 10%);
                    left: 0;
                    bottom: 0;
                }
            }
        }

        &__title {
            @include alternate-medium-font;
            
            @include respond-upto($media-query-smallmedium) {
                @include font-size($font-size-large);                
                margin-bottom: calc(var(--line-height-em) * 0.5);
            }
            @include respond-between($media-query-smallmedium, $site__desktop-breakpoint) {
                @include font-size($font-size-xlarge);
                margin-bottom: var(--line-height-em);
            }
            @include respond-from($site__desktop-breakpoint) {
                @include font-size($font-size-xxlarge);
                margin-bottom: var(--line-height-em);
            }
        }
        &__rte {
        }
    }
    &__image {
        &--first {            
            @include respond-from($media-query-mediumlarge) {
                grid-column-start: 2;
            }
            
            img {
                @include o-notched-corner($corner: 'top-left', $height: 13.5%);
            }
        }
        &--last {
            @include respond-between($media-query-smallmedium, $media-query-mediumlarge) {
                margin-top: spacing(10);
            }
            @include respond-from($media-query-mediumlarge) {
                grid-column-end: -1;
            }
            
            img {
                @include o-notched-corner($corner: 'bottom-right', $height: 13.5%);
            }
        }
    }
}
