////
/// @group -Project-
////

//*------------------------------------
//  @const font-sizes
//  ------------------------------------*/
$font-size-xxxsmall: 'xxxsmall';
$font-size-xxsmall: 'xxsmall';
$font-size-xsmall: 'xsmall';
$font-size-small: 'small';
$font-size-medium: 'medium';
$font-size-large: 'large';
$font-size-xlarge: 'xlarge';
$font-size-xxlarge: 'xxlarge';
$font-size-xxxlarge: 'xxxlarge';
$font-size-xxxxlarge: 'xxxxlarge';
$font-size-xxxxxlarge: 'xxxxxlarge';

@use "sass:math";

///
/// Typographic scale map for use in the "font-size" mixin.<br>
/// Default/breakpoint variable should be used at the top level as keys.<br>
/// Default= font styles which will not be wrapped in a media query. <br>
/// If breakpoint variables as a key it makes the font the styles responsive (applied when >= the breakpoint).<br>
///
/// @example Example typographic scale map
///   $typographic-scale: (
///       'default' : (
///           $font-size-xxxsmall: (
///               'font-size': size(11),
///               'line-height': (20/11)
///           ),
///           $font-size-xxsmall: (
///               'font-size': size(12),
///               'line-height': (20/12)
///           )
///       ),
///       $media-query-xsmall : (
///           $font-size-xxxsmall: (
///               'font-size': size(11),
///               'line-height': (20/11)
///           )
///       )
///   )
///

///
/// Typographic scale map for use in the "font-size" mixin.<br>
/// Default/breakpoint variable should be used at the top level as keys.<br>
/// Default= font styles which will not be wrapped in a media query. <br>
/// If breakpoint variables as a key it makes the font the styles responsive (applied when >= the breakpoint).<br>
///
/// @example Example typographic scale map
///   $typographic-scale: (
///       'default' : (
///           $font-size-xxxsmall: (
///               'font-size': size(11),
///               'line-height': (20/11)
///           ),
///           $font-size-xxsmall: (
///               'font-size': size(12),
///               'line-height': (20/12)
///           )
///       ),
///       $media-query-xsmall : (
///           $font-size-xxxsmall: (
///               'font-size': size(11),
///               'line-height': (20/11)
///           )
///       )
///   )
///
$typographic-scale: (
  'default' : (
    $font-size-xxxsmall: (
      'font-size': size(14),
      'line-height': math.div(18, 14),
      '--line-height': math.div(18, 14),
      '--line-height-em': calc(var(--line-height) * 1em)
    ),
    $font-size-xxsmall: (
      'font-size': size(16),
      'line-height': math.div(24, 16),
      '--line-height': math.div(24, 16),
      '--line-height-em': calc(var(--line-height) * 1em)
    ),
    $font-size-xsmall: (
      'font-size': size(18),
      'line-height': math.div(26, 18),
      '--line-height': math.div(26, 18),
      '--line-height-em': calc(var(--line-height) * 1em)
    ),
    $font-size-small: (
      'font-size': size(20),
      'line-height': math.div(28, 20),
      '--line-height': math.div(28, 20),
      '--line-height-em': calc(var(--line-height) * 1em)
    ),
    $font-size-medium: (
      'font-size': size(23),
      'line-height': math.div(35, 23),
      '--line-height': math.div(35, 23),
      '--line-height-em': calc(var(--line-height) * 1em)
    ),
    $font-size-large: (
      'font-size': size(28),
      'line-height': math.div(45, 28),
      '--line-height': math.div(45, 28),
      '--line-height-em': calc(var(--line-height) * 1em)
    ),
    $font-size-xlarge: (
      'font-size': size(35),
      'line-height': math.div(45, 35),
      '--line-height': math.div(45, 35),
      '--line-height-em': calc(var(--line-height) * 1em)
    ),
    $font-size-xxlarge: (
      'font-size': size(40),
      'line-height': math.div(55, 40),
      '--line-height': math.div(55, 40),
      '--line-height-em': calc(var(--line-height) * 1em)
    ),
    $font-size-xxxlarge: (
      'font-size': size(60),
      'line-height': math.div(72, 60),
      '--line-height': math.div(72, 60),
      '--line-height-em': calc(var(--line-height) * 1em)
    ),
    $font-size-xxxxlarge: (
      'font-size': size(70),
      'line-height': math.div(85, 70),
      '--line-height': math.div(85, 70),
      '--line-height-em': calc(var(--line-height) * 1em)
    ),
    $font-size-xxxxxlarge: (
      'font-size': size(100),
      'line-height': math.div(85, 100),
      '--line-height': math.div(85, 100),
      '--line-height-em': calc(var(--line-height) * 1em)
    )
  )
) !default;

@mixin header--large {
  @include alternate-default-font;
  @include font-size($font-size-xxxxlarge);
}
@mixin header--medium {
  @include alternate-default-font;
  @include font-size($font-size-xxlarge);
}
@mixin header--small {
  @include alternate-default-font;
  @include font-size($font-size-large);
}
@mixin header--xsmall {
  @include alternate-default-font;
  @include font-size($font-size-small);
}
@mixin header--support-title {
  @include alternate-medium-font;
  color: $colour-grey-new-4;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px currentColor;
  width: fit-content;
  
  @include respond-upto($media-query-small) {
    @include font-size($font-size-xlarge);
  }
  @include respond-between($media-query-small, $media-query-smallmedium) {
    @include font-size($font-size-xxlarge);
  }

  @include respond-between($media-query-smallmedium, $media-query-mediumlarge) {
    @include font-size($font-size-xxxlarge);
  }

  @include respond-between($media-query-mediumlarge, $site__desktop-breakpoint) {
    @include font-size($font-size-xxxxlarge);
  }

  @include respond-from($site__desktop-breakpoint) {
    @include font-size($font-size-xxxxxlarge);
    -webkit-text-stroke: 2px currentColor;
  }
}