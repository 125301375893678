.property-detail-enquire {
    .dialog-backdrop {
        align-items: flex-start;
        background-color: $colour-grey-light;
        display: none;
        justify-content: center;
    
        &.active {
            display: flex;
        }
    }
    
    &__dialog {
        padding: spacing(6) spacing(10);
        width: 100%;
    }
    
    &__close {
        @include dialog__close;
    }

    &__form {
        margin: 0 auto;
        max-width: size(550px);

        &.is-loading {
            .form__submit {
                &::before {
                    @include o-loading-spinner($centreHorizontally: false, $centreVertically: false);
                    margin-right: 0.5em;
                    margin-right: 0.75ch;
                }
            }
        }
    }
}