.form {
    $this: '.form';

    &__title {
        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-large);
            margin-bottom: spacing(6);
            margin-top: spacing(6);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xxlarge);
            margin-bottom: spacing(12);
        }
    }

    &__errors {
        border: 1px solid $colour-red;
        color: $colour-red;
        margin-bottom: spacing(5);
        padding: spacing(3);

        &__title {
            @include alternate-default-font;
            @include font-size($font-size-medium);
        }
        &__content {
            @include light-font;
            @include font-size($font-size-small);
        }
    }    

    &__fieldset { 
        
        &__legend {
            @include light-font;
            margin-bottom: spacing(5);

            @include respond-upto($site__desktop-breakpoint) {
                @include font-size($font-size-xsmall);
            }
            @include respond-from($site__desktop-breakpoint) {
                @include font-size($font-size-medium);
            }
        }

        &__field {
            &:not(:last-child) {
                margin-bottom: spacing(10);            
            }
        }
        
        &__grid {
            display: grid;
            row-gap: spacing(10);
        }
        
        &__row {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(size(200px), 1fr));
            column-gap: $column-gutter-default * 2;
            row-gap: inherit;
        }

        &__column {
        }
    }

    &__actions {
        display: flex;
        margin-top: spacing(8);
    }
    
    &__submit {
        align-items: center;
        margin-left: auto;
    }
    
    &--default {
        #{$this} {
            &__title {
                @include alternate-default-font;
                color: $colour-red;
                text-align: center;
            }
            &__fieldset { 
                &__grid {
                    row-gap: spacing(10);
                }
            }
            &__submit {
                @include o-button--ghost-default;
                svg {
                    display: none;
                }                
            }
            &__success {
                @include alternate-default-font;
                @include font-size($font-size-xlarge);
                color: $colour-grey-xdark;
                text-align: center;
            }
        }
    }
    &--dark {
        #{$this} {
            &__title {
                @include alternate-medium-font;
                color: $colour-white;
            }
            &__fieldset { 
                &__grid {
                    row-gap: spacing(5);
                }
            }
            &__submit {
                @include o-button-v2--red;
            }
        }
    }
}