@mixin o-animated-underline($underlineHeight: 1px, $underlinePadding: 1px, $beforeColour: transparent, $afterColour: currentColor, $direction: 'right', $activeTriggers: ':hover', $display: 'inline') {
    // $totalHeight: $underlineHeight + ($underlinePadding * 2);
    $totalHeight: $underlineHeight + $underlinePadding;
    $startPos: if($direction == 'right', 0%, 100%);


    $triggers: '';
    @for $i from 1 through length($activeTriggers) {
        $trigger: nth($activeTriggers, $i);
        $triggers: $triggers + '&' + $trigger;
        @if $i != length($activeTriggers) {
            $triggers: $triggers + ', ';
        }
    }

    $gradient: linear-gradient(
        transparent $underlinePadding,
        $afterColour $underlinePadding,
        $afterColour $underlinePadding + $underlineHeight,
        transparent $underlinePadding + $underlineHeight
    );
    $gradientFallback: linear-gradient($afterColour, $afterColour);

    @if $beforeColour != transparent {
        $gradient: $gradient + ', ' +
        linear-gradient(
              transparent $underlinePadding,
              $beforeColour $underlinePadding,
              $beforeColour $underlinePadding + $underlineHeight,
              transparent $underlinePadding + $underlineHeight
        );

        $gradientFallback: $gradientFallback + ', ' + linear-gradient($beforeColour, $beforeColour)
    };
    
    text-decoration: none;
    background-image: $gradient;
      
    background-size: 0% $totalHeight, 100% $totalHeight;
    background-position: $startPos 100%, 0 100%;
    background-repeat: no-repeat;
    display: #{$display};
    padding-bottom: $underlineHeight + ($underlinePadding + 2px);
    // border-bottom: $totalHeight solid transparent;
    transition: background-size 0.3s $easeInQuad;

    
    #{$triggers} {
        background-size: 100% $totalHeight;
        transition: background-size 0.4s $easeOutQuad;
    }

    @supports (-ms-ime-align:auto) {
        background-image: $gradientFallback;
        background-size: 0% $underlineHeight, 100% $underlineHeight;
        padding-bottom: $underlineHeight;

        #{$triggers} {
            background-size: 100% $underlineHeight;
        }
    }
}