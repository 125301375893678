$development-listing-results__tablet-breakpoint: $site__tablet-breakpoint;
$development-listing-results__small-desktop-breakpoint: $media-query-mediumlarge;

@keyframes fillChange {
    from {
        fill: var(--initialColour);
    }
    to {
        fill: var(--endColour);
    }
}

body {
    &.is-filters-open,
    &.is-map-open {
        @include respond-upto($site__desktop-breakpoint) {
            @include u-clip-to-viewport;

            .site-header {
                background-color: var(--background-colour);
            }

            .development-listing-results__header { 
                background-color: $colour-white;
            }
        }
    }
}

:root {    
    @include respond-upto($site__desktop-breakpoint) {
        --mobile-listing-header-height: #{(spacing(2) * 2) + size(28px)};
    }
}

.development-listing-results {
    $this: &;
    $development-listing-results-header-height: size(142px);

    background-color: $colour-white;
    position: relative;
    z-index: 1;

    // For JS
    --mobile-width: #{map-get($breakpoints, $site__desktop-breakpoint)};

    @include respond-between($development-listing-results__tablet-breakpoint, $development-listing-results__small-desktop-breakpoint) {
        --column-count: 2;
    }
    @include respond-between($development-listing-results__small-desktop-breakpoint, $site__desktop-breakpoint) {
        --column-count: 3;
    }
    @include respond-from($site__desktop-breakpoint) {
        --column-count: 4;
    }
    
    &__content {
        @include container;        
        padding-bottom: spacing(40);
    }

    &__header {
        @include container;
        background-color: $colour-white;
        top: var(--site-header-height);
        transition: transform 0.3s linear;
        transform: none;
        z-index: 2;
        
        @include respond-upto($site__desktop-breakpoint) {
            position: fixed;
            left: 0;
            right: 0;
        }
            
        @include respond-from($site__desktop-breakpoint) {
            margin-left: calc(-1 * var(--container-gutter-width));
            margin-right: calc(-1 * var(--container-gutter-width));
            position: sticky;
            grid-template-columns: repeat(var(--column-count), minmax(0, 1fr));
            margin-top: $breadcrumb__margin-top;
            padding-bottom: spacing(3);
            align-items: flex-start;
            display: flex;
            justify-items: space-between;

            &::before {
                border-top: 1px solid $colour-grey-xdark;
                content: '';
                position: absolute;
                left: var(--container-gutter-width, 0);
                right: var(--container-gutter-width, 0);
                top: 0;
            }
        }

        &__top {            
            @include respond-upto($site__desktop-breakpoint) {
                align-items: center;
                border-top: 1px solid $colour-grey-xdark;
                display: flex;
                height: var(--mobile-listing-header-height);
                flex-wrap: wrap;
                padding-top: spacing(2);
                padding-bottom: spacing(2);
            }
        }
    }

    &__actions {
        @include respond-upto($site__desktop-breakpoint) {
            margin-left: auto;
        }
        @include respond-from($site__desktop-breakpoint) {
            display: flex;            
            padding-top: spacing(2);
            justify-content: flex-end;

            > * {
                width: max-content;

                &:not(:last-child) {
                    margin-right: size(46px);
                }
            }
        }

        &__reset-button {
            @include light-font;
            @include font-size($font-size-xsmall);
            align-items: center;

            @include respond-upto($site__desktop-breakpoint) {
                display: none;
            }
            @include respond-from($site__desktop-breakpoint) {
                display: flex;
            }

            &__icon {
                width: size(19px);
                height: size(19px);
                margin-left: spacing(2);
            }
        }
    }

    &__toggle {
        @include light-font;
        @include font-size($font-size-xxxsmall);
        align-items: center;
        display: flex;
        
        @include respond-from($site__desktop-breakpoint) {
            display: none;
        }

        &__icon {
            stroke: currentColor;
            margin-left: spacing(1);
            height: size(14px);
            width: size(14px);
        }

        &.is-active {
            color: $colour-red;
        }
    }

    &__search-fields,
    &__property-list-filter__elements {
        @include respond-upto($site__desktop-breakpoint) {
            @include container;
            background-color: $colour-white;
            padding-top: spacing(6);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }

    &__search-fields {        
        z-index: 2;

        @include respond-upto($site__desktop-breakpoint) {
            display: none;
            flex-direction: column;
            top: var(--mobile-listing-header-height);
        }
        
        @include respond-from($site__desktop-breakpoint) {
            display: flex;
            flex-grow: 1;
            padding-top: spacing(3);
            position: relative;
            order: -1;

            &:not(:last-child) {
                margin-bottom: spacing(3);
            }
        }

        &__actions {
            @include respond-from($site__desktop-breakpoint) {
                display: none;
            }
        }

        &__button {
            
        }
    }

    &__property-list-filter,
    &__search-fields {
        &__actions {
            @include respond-upto($site__desktop-breakpoint) {
                padding: spacing(3) 0 spacing(8);
                margin-top: auto;
            }
        }

        &__button {
            @include respond-upto($site__desktop-breakpoint) {
                &:nth-child(odd) {
                    @include o-button--ghost-default;
                }
                &:nth-child(even) {
                    @include o-button--default;
                }
                &:not(:last-child) {
                    margin-right: spacing(2);
                }
            }
            @include respond-from($site__desktop-breakpoint) {
                @include default-font;
                @include font-size($font-size-small);
                color: $colour-red;
            }
        }
    }

    &__let-agreed-mobile__checkbox-radio,
    &__search-field {
        --border-bottom: 1px solid #{$colour-grey-xdark};
        --border-bottom-active: 1px solid #{$colour-red};

        @include respond-upto($site__desktop-breakpoint) {
            padding-top: spacing(2);
            padding-bottom: spacing(3);
        }
    }

    &__search-field {
        @include respond-upto($site__desktop-breakpoint) {
            border-bottom: var(--border-bottom);

            &--filtered {
                border-bottom: var(--border-bottom-active);
            }
        }
        @include respond-from($site__desktop-breakpoint) {
            flex: 1 0 0%;
            max-width: size(355px);
                
            &:nth-last-child(2) {
                border-right: 1px solid $colour-grey-xdark;
            }
        }
    }

    &__main {
        margin-bottom: spacing(12);
    }

    &__properties {
        @include respond-upto($site__desktop-breakpoint) {
            padding-top: var(--mobile-listing-header-height);
        }
        @include respond-from($development-listing-results__tablet-breakpoint) {
            display: grid;
            gap: $column-gutter-default * 2;
            grid-template-columns: repeat(var(--override, var(--column-count)), minmax(0, 1fr));
        }
    }

    &__property {
        @include respond-upto($development-listing-results__tablet-breakpoint) {       
            &:not(:last-child) {
                margin-bottom: spacing(8);
            }
            > * {
                max-width: size(420px);
                margin: 0 auto;
            }
        }
        @include respond-from($development-listing-results__tablet-breakpoint) {
            display: flex;
            flex-direction: column;

            > * {
                display: flex;
                flex-grow: 1;
            }
        }

        > svg {
            $speed: 1.2s;
            $delay: -($speed * 1.2);
            $delayIncrement: 0.06s;

            display: block;

            .image,
            .text,
            .triangle {
                animation: fillChange #{$speed} $easeInOutCirc alternate infinite;
                animation-delay: calc(#{$delay} + (var(--index) * #{$delayIncrement}));
            }

            .background {
                fill: $colour-grey-light;
            }
            .image {
                --initialColour: #{$colour-grey-mid};
                --endColour: #{darken($colour-grey-mid, 2)};

                fill: var(--initialColour);
            }
            .text,
            .triangle {
                --initialColour: #{$colour-grey-mid-light};
                --endColour: #{$colour-grey-mid-dark};

                fill: var(--initialColour);
            }
            .text {
            }
        }           
        
    }

    &__map {
        @include respond-upto($site__desktop-breakpoint) {
            @include container;
            background-color: $colour-white;
            bottom: 0;
            left: 0;
            overflow: hidden;
            padding-bottom: var(--container-gutter-width);
            position: fixed;
            right: 0;
            top: calc(var(--site-header-height) + var(--mobile-listing-header-height));
            z-index: 2;
        }        

        @include respond-from($site__desktop-breakpoint) {
            &:not(:empty) {
                background-color: $colour-grey-light;
            }
            margin-bottom: spacing(5);
            min-height: size(400px);
            max-height: size(720px);
            height: calc(100vh - (var(--site-header-height) + #{$development-listing-results-header-height}));
        }
    }

    &__footer {
        @include default-font;
        @include font-size($font-size-small);
        align-items: baseline;
        display: flex;
        flex-wrap: wrap;
        grid-column: 1 / -1;
        justify-content: space-between;
        padding-top: spacing(12);

        &__results {
            margin-bottom: spacing(2);
            margin-right: spacing(6);
        }
    }

    &__property-list-filter {
        @include respond-from($site__desktop-breakpoint) {
            border-left: 1px solid $colour-grey-xdark;
            position: relative;
            padding-left: spacing(2);
            padding-right: spacing(4);
        }

        &__legend,
        &__selected {
            @include light-font;
            @include respond-upto($site__desktop-breakpoint) {
                @include font-size($font-size-xxxsmall);
            }
            @include respond-from($site__desktop-breakpoint) {
                @include font-size($font-size-xsmall);
                cursor: pointer;
            }
        }

        &__legend {
            align-items: center;
            color: $colour-black;
            display: flex;
            float: left;
            width: 100%;

            // Align with top of divider lines
            @include respond-from($site__desktop-breakpoint) {
                margin-top: -0.33em;
            }

            svg {
                margin-left: auto;
                float: right;
                stroke: $colour-black;
                
                @include respond-upto($site__desktop-breakpoint) {
                    height: size(14px);
                    width: size(14px);
                    transform: rotate(-90deg);
                }
                @include respond-from($site__desktop-breakpoint) {
                    height: size(16px);
                    width: size(16px);
                }
            }

            ~ * {
                clear: left;
            }
        }

        &__selected {
            color: $colour-grey-xdark;
            display: block;
            transition: opacity 0.15s linear 0.35s;

            span:not(:last-child) {
                &::after {
                    content: ', ';
                }
            }
        }

        &__elements {
            background-color: $colour-white;
            z-index: 1;

            @include respond-upto($site__desktop-breakpoint) {
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                top: 0;
                transform: translate3d(100vw, 0, 0);
                transition: transform 0.3s $easeInOutQuart;
            }
            
            @include respond-from($site__desktop-breakpoint) {
                border-bottom: 1px solid transparent;
                border-left: inherit;
                visibility: hidden;
                max-height: 0;
                overflow: hidden;
                padding-left: inherit;
                padding-right: inherit;
                position: absolute;
                transition: max-height 0.3s $easeInOutQuart, visibility 0s linear 0.3s;
                top: 100%;
                left: -1px;
                right: 0;
            }

            &__legend {
                @include respond-upto($site__desktop-breakpoint) {
                    @include default-font;
                    @include font-size($font-size-xxxsmall);
                    display: block;
                    margin-bottom: spacing(3);
                }
                @include respond-from($site__desktop-breakpoint) {
                    display: none;
                }
            }

            &__actions {
                @include respond-from($site__desktop-breakpoint) {
                    display: flex;
                    justify-content: space-between;
                }
            }
        }

        &.is-open,
        &.is-active {
            border-color: $colour-red;

            #{$this}__property-list-filter {
                &__selected {
                    color: $colour-red;
                }
            }

        }

        @include respond-upto($site__desktop-breakpoint) {
            &.is-open,
            &.is-active {
                #{$this}__property-list-filter {
                    &__legend {
                        @include default-font;
                        
                        svg {
                            stroke: $colour-red;
                        }
                    }
                }
            }
            &.is-open {
                #{$this}__property-list-filter {
                    &__elements {
                        transform: translate3d(0, 0, 0);
                        transition-timing-function: $easeInOutQuad;
                    }
                }
            }
        }
        @include respond-from($site__desktop-breakpoint) {
            &.is-open {

                #{$this}__property-list-filter {
                    &__selected {
                        opacity: 0;
                        transition-delay: 0s;
                    }
                    &__elements {                        
                        border-bottom-color: $colour-grey-xdark;
                        max-height: unquote('min(var(--height), 60vh)');
                        visibility: visible;
                        transition: max-height 0.3s $easeInOutQuart, border-bottom 0.3s $easeInOutQuart;
                        
                        &:not(.is-transitioning) {
                            overflow-y: auto;
                        }
                    }
                    &__legend {
                        color: $colour-red;

                        svg {
                            stroke: $colour-red;
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }

        &__actions {
            @include respond-from($site__desktop-breakpoint) {
                display: flex;
                justify-content: space-between;
                margin-top: spacing(6);
                padding-bottom: spacing(4);
            }
        }

        &__button {
            @include respond-from($site__desktop-breakpoint) {
                @include o-animated-underline($underlineHeight: 1px);
                @include light-font;
                @include font-size($font-size-xsmall);
                color: $colour-black;
            }
        }
    }

    &__checkbox-radio {
        @include light-font;
        flex-shrink: 0;
        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-xxxsmall);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xsmall);
        }
    }

    &__checkbox-radio--radio {
        #{$this}__checkbox-radio {
            &__control {
                @include u-visually-hide;
            }
    
            &__faux {
                display: none;
            }

            &__label {
                border-bottom: 1px solid $colour-grey-xdark;
                display: block;
                padding: spacing(3) size(2px);
            }
    
            &__control:checked ~ #{$this}__checkbox-radio__label {
                background-color: $colour-grey-light;
            }
        }
    }

    &__checkbox-radio--checkbox {
        @include property-listing-checkbox($this: #{$this}__checkbox-radio, $activeLabelColour: $colour-red);
        align-items: center;
        border-bottom: 1px solid $colour-grey-mid-dark;
        display: flex;
        padding-bottom: spacing(2);

        #{$this}__checkbox-radio {
            &__label {            
                margin-right: spacing(3);
            }            
        }

        &:not(:last-child) {
            margin-bottom: spacing(2);
        }

        &:hover {
            #{$this}__checkbox-radio {
                &__label {
                    color: $colour-black;
                }

                &__faux::before {
                    border-color: $colour-black;
                }
            }
        }
    }

    &__sort {
        @include light-font;
    
        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-xxxsmall);
            margin-left: auto;
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xsmall);
        }

        &__results {
            color: $colour-black;
        }

        &__select {
            @include respond-upto($site__desktop-breakpoint){
                --iconSize: #{size(14px)};
            }
            @include respond-from($site__desktop-breakpoint) {
                --iconSize: #{size(16px)};
            }
    
            --verticalPadding: #{size(6px)};
            --horizontalPadding: #{size(8px)};
    
            align-items: baseline;
            display: inline-flex;
            width: auto;
    
            &__label {
                text-transform: lowercase;
                @include respond-upto($site__desktop-breakpoint){
                    @include u-visually-hide;
                }
            }
                  
            &__input {            
                position: relative;
            }
            
            &__control {
                background-color: $colour-white;
                color: $colour-grey-xxdark;
                font: inherit;

                @include respond-upto($site__desktop-breakpoint){
                    padding: var(--verticalPadding) calc(var(--horizontalPadding) + var(--iconSize)) var(--verticalPadding) 0;
                }
                @include respond-from($site__desktop-breakpoint){
                    padding: var(--verticalPadding) calc((var(--horizontalPadding) * 3) + var(--iconSize)) var(--verticalPadding) var(--horizontalPadding);
                }
            }
    
            &__option {
                font: inherit;
                color: $colour-black;
            }
    
            &__faux {
                bottom: 0;
                pointer-events: none;
                position: absolute;
                top: 0;
                width: var(--iconSize);
                
                @include respond-upto($site__desktop-breakpoint){
                    right: 0;
                }
                @include respond-from($site__desktop-breakpoint){
                    right: var(--horizontalPadding);
                }
                
    
                svg {
                    height: 100%;
                    stroke: $colour-black;
                    stroke-width: 1px;
                    width: 100%;
                }
            }        
        }
    }

    
    &__button {
        @include o-button--default;
        @include light-font;
        @include font-size($font-size-medium);
    }

    &__let-agreed__checkbox-radio,
    &__map__checkbox-radio {
        @include property-listing-checkbox($inactiveColour: $colour-black, $borderRadius: 50%, $activeLabelColour: $colour-black);
        @include light-font;
        align-items: center;
        display: flex;

        @include respond-upto($site__desktop-breakpoint){
            @include font-size($font-size-xxxsmall);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xsmall);
        }

        &__faux {
            @include respond-upto($site__desktop-breakpoint){
                display: none;
            }
        }

        :checked ~ &__label {
            span:not(:only-child):first-child {
                display: none;
            }
        }
        :not(:checked) ~ &__label {
            span:not(:only-child):last-child {
                display: none;
            }
        }

        &__label {
            @include respond-from($site__desktop-breakpoint) {
                margin-right: spacing(3);
            }
        }
    }

    &__let-agreed__checkbox-radio {
        @include respond-upto($site__desktop-breakpoint) {
            display: none;
        }
    }
    &__let-agreed-mobile__checkbox-radio {
        @include property-listing-checkbox($inactiveColour: $colour-black, $activeLabelColour: $colour-black);
        @include font-size($font-size-xxxsmall);
        align-items: flex-end;
        display: flex;
        position: relative;
        
        @include respond-from($site__desktop-breakpoint) {
            display: none;
        }
        
        &__label {
            @include default-font;            
            margin-right: spacing(3);

            &::before {
                border-bottom: var(--border-bottom);
                bottom: 0;
                content: '';
                left: 0;
                position: absolute;
                right: 0
            }

            &::after {
                @include light-font;
                content: 'Off';
                color: $colour-grey-xdark;
                display: block;
            }
        }

        &__control:checked ~ &__label {
            &::before {
                border-bottom: var(--border-bottom-active);
            }
            &::after {
                content: 'On';
                color: $colour-red;
            }
        }
    }

    &.is-open {
        @include respond-upto($site__desktop-breakpoint) {
            #{$this} {
                &__header {
                    // Make mobile menu take up full viewport
                    bottom: 0;
                }
                &__toggle__icon {
                    transform: rotate(180deg);
                }
                &__search-fields {
                    display: flex;
                }
                &__map__checkbox-radio,
                &__sort {
                    display: none;
                }
            }
        }
    }

    &.is-secondary-menu-open {
        @include respond-upto($site__desktop-breakpoint) {
            #{$this}__search-fields {
                overflow: hidden;
            }
        }
    }

    &:not(.is-map-open) &__map { 
        display: none;
    }
    &.is-map-open {
        @include respond-upto($site__desktop-breakpoint) {
            #{$this} {
                &__toggle,
                &__sort {
                    display: none;
                }
            }
        }
    }

    // When page scrolled down + filters + map are closed, move filter bar to top of page
    .site-header-hide & {
        &:not(.is-open),
        &:not(.is-map-open) {
            #{$this}__header {
                @include respond-upto($site__desktop-breakpoint) {
                    transform: translate3d(0, calc(-1 * var(--site-header-height)), 0);
                }
            }
        }
    }
    // If filters/map are open, PREVENT filter bar from moving
    &.is-open,
    &.is-map-open {
        #{$this}__header {
            @include respond-upto($site__desktop-breakpoint) {
                transform: translate3d(0, 0, 0)!important;
            }
        }
    }
}