// [Settings,][Tools,][Generic,][Base,][Objects,][Utilities,]
@import '../library/_tools.sizing';


////
/// @group -Project-
////

//*----------------------------------------
// 
//  Loading spinner
// 
// ----------------------------------------*/

///
@mixin o-loading-spinner(
    $speed: 1s,
    $spinnerStyle: 2px solid,
    $size: size(1em),
    $centreVertically: true,
    $centreHorizontally: true
) {
    animation: rotate $speed infinite linear;		
    background: transparent;
    backface-visibility: hidden;
    border: $spinnerStyle;
    border-left-color: transparent;
    border-radius: 50%;
    content: '';
    height: $size;
    width: $size;
    
    @if $centreHorizontally or $centreVertically {
        position: absolute;
    }
    @if $centreHorizontally {
        left: calc(50% - #{$size / 2});
    }
    @if $centreVertically {
        top: calc(50% - #{$size / 2});
    }
}

///
@mixin o-loading-spinner__backdrop($colour: rgba($white, 0.85), $fill: true) {
    background-color: $colour;
    content: '';

    @if $fill {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }
}

@keyframes rotate {	
	0% {
	    transform: rotate(0deg)translate3d(0,0,0);
	}
	100% {
	    transform: rotate(360deg)translate3d(0,0,0);
	}
}