.property-detail-header {
    display: grid;
    column-gap: $column-gutter-default;
    @include respond-upto($site__desktop-breakpoint) {
        grid-template-columns: 1fr max-content;
        padding-top: spacing(6);
    }
    @include respond-from($site__tablet-breakpoint) {
        align-items: baseline;
    }
    @include respond-from($site__desktop-breakpoint) {
        grid-template-columns: 1fr max-content 50%;
    }

    &__headings {        
        @include respond-upto($site__desktop-breakpoint) {
            grid-column: 1 / span 2;
        }
    }

    &__title {
        @include alternate-default-font;
        color: $colour-red;
        
        @include respond-upto($site__tablet-breakpoint) {
            @include font-size($font-size-medium);
        }
        @include respond-from($site__tablet-breakpoint) {
            @include font-size($font-size-xlarge);
        }
    }
    
    &__location {
        @include light-font;
        
        @include respond-upto($site__tablet-breakpoint) {
            @include font-size($font-size-xxxsmall);
        }
        @include respond-from($site__tablet-breakpoint) {
            @include font-size($font-size-small);
        }

        &--mobile {
            @include respond-from($site__desktop-breakpoint) {
                display: none;
            }
        }
        &--desktop {
            @include respond-upto($site__desktop-breakpoint) {
                display: none;
            }
        }
    }

    &__price,
    &__view-map {
        @include respond-upto($site__desktop-breakpoint) {
            margin-bottom: spacing(10);
        }
    }
    
    &__price {
        @include light-font;

        @include respond-upto($site__tablet-breakpoint) {
            @include font-size($font-size-medium);
        }
        @include respond-between($site__tablet-breakpoint, $site__desktop-breakpoint) {
            @include font-size($font-size-xxlarge);
        }
        @include respond-from($site__desktop-breakpoint) {
            text-align: right;
        }
        
        &__value {
            @include respond-from($site__desktop-breakpoint) {
                @include font-size($font-size-xxlarge);
                color: $colour-red;
            }
        }

        &__unit {
            @include respond-from($site__desktop-breakpoint) {
                @include font-size($font-size-small);
                display: block;
            }
        }
    }

    &__contact {
        display: flex;

        @include respond-upto($site__tablet-breakpoint) {
            align-items: flex-end;
            flex-direction: column-reverse;
        }
        @include respond-from($site__tablet-breakpoint) {
            align-items: baseline;
            justify-content: flex-end;
        }
    }

    &__enquiry-link {
        @include o-button--default;
        @include light-font;
        @include font-size($font-size-medium);
        
        &:not(:only-child) {
            @include respond-upto($site__tablet-breakpoint) {
                margin-bottom: spacing(2);
            }
        }
        @include respond-between($site__tablet-breakpoint, $site__desktop-breakpoint) {
            margin-left: spacing(8);
        }
        @include respond-from($site__desktop-breakpoint) {
            margin-left: spacing(16);
        }
    }

    &__telephone {
        @include alternate-default-font;
        text-decoration: none;

        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-xxsmall);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-large);
        }
    }

    &__view-map {
        @include font-size($font-size-xxsmall);
        @include default-font;
        justify-self: end;
        text-decoration: underline;
        text-underline-position: under;

        @include respond-from($site__desktop-breakpoint) {
            display: none;
        }
    }
}