.image-cta {
    $this: '.image-cta';

    $image-notch-height: size(67px);

    column-gap: var(--column-gap);
    display: grid;
    position: relative;
    grid-template-columns: repeat(12, minmax(0, 1fr));

    &__content {
        align-items: end;
        grid-column: 1 / span 12;
        padding-right: $column-gutter-default;
        
        column-gap: var(--column-gap);
        display: grid;

        z-index: 2;

        @include respond-from($media-query-smallmedium) {  
            grid-template-columns: repeat(10, minmax(0, 1fr)); 
        }

        @include respond-from($media-query-mediumlarge) {  
            grid-column: 2 / span 10;
        }

        @include respond-from($site__desktop-breakpoint) { 
            margin-left: $column-gutter-default * 2;
            margin-right: $column-gutter-default;
            transform: translateX($column-gutter-default);
        }

    }

    &__rte,
    &__image {
        grid-row: 1;
    }
    
    &__rte {
        grid-column: 1 / -1;
        column-gap: var(--column-gap);
        grid-template-columns: repeat(10, minmax(0, 1fr));
        display: grid;
        position: relative;
        z-index: 0;

        @include respond-from($media-query-smallmedium) {
            margin-top: $image-notch-height + spacing(2);
        }
        
        &::before {
            box-shadow: $box-shadow;
            content: '';
            grid-column: 1 / -1;
            margin-right: -$column-gutter-default;
            z-index: -1;
        }

        &::before,
        .rte {
            grid-row: 1;
        }
        
        .rte {
            padding: spacing(4);
            padding-right: 0;
            
            @include respond-upto($media-query-smallmedium) {  
                grid-column: 1 / -1;
            }
            @include respond-from($media-query-smallmedium) {  
                grid-column: 1 / span 4;
            }
    
            @include respond-from($media-query-mediumlarge) { 
                padding: spacing(10);
                grid-column: 1 / span 5;
            }
    
            @include backend-specific {
                color: $colour-white !important;
            }
        }

        > .rte--button-black {
            .rte__link {
                border: 1px solid $colour-black!important;
            }
        }

    }
    &__image {       
        @include o-notched-corner($corner: 'top-left', $height: $image-notch-height); 
        align-self: start;
        display: none;
        grid-column-start: span 6;
        grid-column-end: -1;
        justify-self: end;
        position: relative;
        margin-bottom: $column-gutter-default;
        z-index: 1;

        @include respond-from($media-query-smallmedium) {  
            display: block;
        }

        @include respond-from($media-query-mediumlarge) {  
            grid-column-start: span 5;
        }
    }

    &__svg {
        grid-column: 1 / span 12;
        &.is-in-view:not(.is-tab-defocused) > .northern-logo-pattern {
            animation-play-state: running;
        }
    }

    .northern-logo-pattern {
        animation-play-state: paused;
        bottom: -$column-gutter-default;
        height: 100%;
        position: absolute;
        margin-left: -$column-gutter-default;
        z-index: 1;

        path {
            --filled-colour: #{$colour-black};
            --empty-colour: #{rgba($colour-black, 0)};
            animation-play-state: inherit!important;
            fill: var(--empty-colour);
            stroke: $colour-black;
            stroke-width: 0.23;
            stroke-miterlimit: 10;
            
            &.is-animating {
                animation: northern-logo-background-fill 8s forwards 1;
            }
        }

        @include respond-upto($media-query-mediumlarge) {
            max-width: calc(100% + #{$column-gutter-default * 2});
        }
        @include respond-from($media-query-mediumlarge) { 
            bottom: size(-100px); 
            height: calc(100% + #{size(100px)});
            margin-left: $column-gutter-default * 3;
            max-width: calc(100% + #{$column-gutter-default * 3});
        }
    }

    &--subtle {
        #{$this} {
            &__rte::before {
                background-color: $colour-grey-new-0;
            }
            &__image {
                max-width: size(380px);
                @include respond-upto($media-query-medium) {
                    grid-column-start: span 6;
                }
                @include respond-between($media-query-medium, $media-query-large) {
                    grid-column-start: span 5;
                }
                @include respond-from($media-query-large) {  
                    grid-column-start: span 4;
                }
            }
        }
    }

    &--theme {
        &-red {
            #{$this}__rte::before {
                background-color: $colour-red;
            }
        }
        &-black {
            #{$this}__rte::before {
                background-color: $colour-black;
            }
        }
    }
}

@keyframes northern-logo-background-fill {
    0% { 
        animation-timing-function: $easeOutQuad;
        fill: var(--empty-colour);
    }
    50%,
    70% {
        animation-timing-function: $easeInOutQuad;
        fill: var(--filled-colour);
    }
    100% {
        fill: var(--empty-colour);
    }
}