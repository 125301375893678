.banner-cta {
    $this: '.banner-cta';

    color: $colour-white;

    &__background {
        @include respond-upto($site__tablet-breakpoint) { 
            display: none;
        }

        &::after {
            content: '';
            background-image: linear-gradient(90deg, rgba($colour-black, 1) 30%, 85%, rgba($colour-black, 0) 100%);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &__image {
            @include u-image-cover;
            z-index: -1;
        }
    }

    
    &__inner {
        column-gap: var(--column-gap);
        display: grid;
        position: relative;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        
        @include respond-upto($site__tablet-breakpoint) { 
            padding-top: spacing(8);
            padding-bottom: spacing(32);
        }
        @include respond-from($site__tablet-breakpoint) {
            padding-top: spacing(64);
            padding-bottom: spacing(64); 
        }
    }

    &__content {
        grid-column: 1 / span 12;
        z-index: 3;

        @include respond-from($site__tablet-breakpoint) { 
            grid-column: 2 / span 7;
        }
    }

    &__title {
        @include alternate-medium-font;
        @include font-size($font-size-xlarge);

        margin-bottom: spacing(12);

        u {
            text-decoration-color: $colour-red;
            text-decoration-thickness: 3px;
            text-underline-offset: 40%;
        }
    }
    &__body-text {
        @include default-font;
        @include font-size($font-size-small);

        margin-bottom: spacing(12);
        max-width: 50ch;

        @include respond-from($site__tablet-breakpoint) { 
            @include font-size($font-size-large);
        }

    }
    &__link {
        @include o-button-v2--white;  
    }

    .dialog-backdrop {
        @include dialog__backdrop(false);
    }

    &__video-button {
        align-self: center;
        justify-self: end;
        grid-column: 1 / span 12;
        width: fit-content;

        span {
            @include u-visually-hide;
        }

        @include respond-upto($site__tablet-breakpoint) {
            display: none;
        }
        @include respond-from($site__tablet-breakpoint) { 
            grid-column: 9 / -2;
        }

        &__icon {
            max-height: spacing(32);
            width: auto;
        }
    }
    &__video {        
        display: none;

        @include u-fill-viewport(max-height);
        background-color: $colour-black;
        flex-direction: column;
        position: relative;
        max-height: calc(100% - #{spacing(10)});
        width: calc(100% - #{spacing(10)});
        
        @include respond-from($media-query-small) {
            min-width: calc(#{map-get($breakpoints, $media-query-small)} - (#{$column-gutter-default} * 2));  /* == breakpoint - left+right margin */
            max-height: calc(100% - #{spacing(10)});
            box-shadow: 0 19px 38px rgba($colour-black, 0.12), 0 15px 12px rgba($colour-black, 0.22);
            width: calc(75% - #{spacing(10)});
        }
        
        &::before {
            @include u-ratio(16, 9);
            content: '';
            display: block;
        }

        &__player {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        
        &__close {
            align-items: center;
            background-color: $colour-black;
            border-radius: 50%;
            color: $colour-white;
            display: flex;
            padding: spacing(3);
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -50%);
            z-index: 1;

            span {
                @include u-visually-hide;
            }

            &__svg {
                transform: rotate(45deg);
                height: spacing(4);
                width: spacing(4)
            }
        }     

        .active & {
            display: block;
        }
    }

    &--branding{
        &-colony {
            #{$this} {
                &__title {
                    u {
                        text-decoration-color: $colour-orange;
                    }
                }

                &__link {
                    @include o-button-v2--orange;  
                }

                &__colony-logo {
                    position: relative;
                }

                &__colony-logo img {
                    display: inline;
                    height: 35px;
                    position: relative;
                    top: 4px;
                }

                &__colony-logo::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    left: 0;
                    bottom: -10px;
                    background: $colour-orange;
                }
            
                &__colony-partial-logo {
                    bottom: spacing(4);
                    position: absolute;
                    right: 0;
                    max-width: size(133px);
                    z-index: 2;
                    
                    @include respond-from($site__tablet-breakpoint) { 
                        bottom: spacing(6);
                        right: spacing(16);
                        width: size(228px);
                    }
                }
                &__animated-svg {
                    bottom: 0;
                    left: calc(-1 * var(--container-gutter-margin, 0));
                    position: absolute;
                    pointer-events: none;
                    right: calc(-1 * var(--container-gutter-margin, 0));
                    top: 0;
                    z-index: 2;
                    
                    @include respond-upto($site__tablet-breakpoint) {
                        overflow: hidden;
                    }

                    &.is-in-view:not(.is-tab-defocused) > .colony-ripple {
                        animation-play-state: running;
                    }
                    
                    .colony-ripple {        
                        animation-play-state: paused;
                        left: calc(#{size(-222px)} + var(--container-gutter-margin, 0));
                        position: absolute;
                        top: size(78px);
                        width: size(564px);
            
                        path {
                            animation-play-state: inherit!important;
                            backface-visibility: hidden;
                            fill: none; 
                            stroke: rgba($colour-white, 0.28); 
                            stroke-width: 1; 
                            stroke-miterlimit: 10;
                            transform-origin: center center;
                            transform-box: fill-box;                
                        }
                    }

                    &.animation-grow {
                        .colony-ripple path {
                            animation: colony-ripple__grow 0.5s $easeInOutCirc both;
                            animation-delay: calc(0.3s * var(--index)); 
                        }
                    }
                    &.animation-shrink {
                        .colony-ripple path {
                            animation: colony-ripple__shrink 0.2s $easeInOutCirc both;
                            animation-delay: calc(0.1s * var(--index)); 
                        }
                    }
                }
            }
        }
        &-northern {
            #{$this} {
                &__northern-logo {
                    @include u-ratio(1,1);
                    opacity: 0.1;
                    bottom: 0;
                    position: absolute;
                    pointer-events: none;
                    right: 71.25%;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 46%;
                    z-index: 2;

                    svg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@keyframes colony-ripple__grow {
    from {
        transform: scale(1)rotate(-30deg)translateZ(0);
    }
    to {
        transform: scale(1.1)rotate(60deg)translateZ(0);
    }
}
@keyframes colony-ripple__shrink {
    from {
        transform: scale(1.1)rotate(60deg)translateZ(0);
    }
    to {
        transform: scale(1)rotate(-30deg)translateZ(0);
    }
}