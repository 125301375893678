.hero-article {
  @include container;
  background-color: $colour-grey-xxxdark;
  position: relative;
  isolation: isolate;
  color: $colour-white;
  background-size: cover;
  background-position: center;
  text-align: center;

  @include respond-upto($media-query-small) {
    padding-top: spacing(20);
    padding-bottom: spacing(20);
  }
  @include respond-between($media-query-small, $media-query-smallmedium) {
    padding-top:spacing(24);
    padding-bottom:spacing(24);
  }
  @include respond-between($media-query-smallmedium, $media-query-medium) {
    padding-top: spacing(32);
    padding-bottom: spacing(32);
  }
  @include respond-from($media-query-medium) {
    padding-top: spacing(40);
    padding-bottom: spacing(40);
  }

  &__image {
    &__inner {
        @include u-image-cover;
        z-index: -1;
    }
  }

  &__title,
  &__subtitle {
    margin: 0 auto;
  }

  &__title {
    @include alternate-medium-font;
    max-width: 25ch;

    @include respond-upto($site__tablet-breakpoint) {
      @include font-size($font-size-xlarge);
    }
    @include respond-from($site__tablet-breakpoint) {
      @include font-size($font-size-xxxlarge);
    }
  }

  &__subtitle {
    @include default-font;
    @include font-size($font-size-small);
    max-width: 35ch;
  }

  &--has-image {
    &::after {
      background: rgba($colour-grey-xxdark, 0.75);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
}
