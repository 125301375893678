.contact-map {
    --min-map-height-mobile: #{size(455px)};

    @include container;
    align-items: stretch;
    color: $colour-white;
    column-gap: var(--column-gap);
    display: grid;
    overflow: hidden;

    @include frontend-specific {
        z-index: 0;
    }

    @include backend-specific {
        z-index: 1;
    }

    @include reduced-motion {
        position: relative;
    }

    @include respond-upto($site__desktop-breakpoint) {
        grid-template-columns: repeat(1, 1fr);
    }

    @include respond-from($site__desktop-breakpoint) {
        grid-template-columns: repeat(12, 1fr);
        position: sticky;
        top: 0;
    }

    &__main,
    &__map {
        @include respond-from($site__desktop-breakpoint) {
            grid-row: 1 / span 1;
        }
    }

    &__main {
        position: relative;
        z-index: 1;

        @include respond-upto($site__desktop-breakpoint) {
            grid-row: 2 / span 1;
        }

        @include respond-from($site__desktop-breakpoint) {
            @include u-fill-viewport;
            align-content: end;
            align-items: end;
            grid-column: 1 / span 6;
            grid-auto-rows: min-content;
            padding-bottom: size(114px);
            column-gap: var(--column-gap);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        &::before,
        &::after {
            content: '';
            background-color: $colour-black;
            height: calc((var(--vh, 1vh) * 100));
            position: absolute;
            z-index: -1;

            @include respond-upto($site__desktop-breakpoint) {
                transform: skew(63.117deg, 0deg);
            }

            @include respond-from($site__desktop-breakpoint) {
                transform: skew(26.883deg, 0deg);
            }
        }

        &:before {
            right: 0;
            bottom: 100%;
            transform-origin: bottom left;
            width: 100vw;
        }

        &:after {
            top: 0;
            right: 0;
            transform-origin: top left;
            width: 120vw;
        }
    }

    &__map {
        margin-right: calc(var(--container-gutter-width) * -1);
        position: relative;
        overflow: hidden;

        .gmap-logo-wrapper {
            right: 0 !important;
            left: auto !important;
            bottom: size(35px) !important;
        }

        @include respond-upto($site__desktop-breakpoint) {
            &::before {
                @include u-ratio(375, 455, $includeAspectRatio: false);
                content: '';
                display: block;
            }

            margin-left: calc(var(--container-gutter-width) * -1);
            min-height: var(--min-map-height-mobile);
            max-height: unquote('max(75vh, var(--min-map-height-mobile))');
            margin-bottom: calc(var(--container-gutter-width) * -1);
            grid-row: 1 / span 1;
            position: relative;

            >.map {
                position: absolute !important;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;

                --map-icon-width: #{size(47px)};
                --map-icon-height: #{size(70px)};
                --map-logo-size: #{size(19px)};

            }
        }

        @include respond-from($site__desktop-breakpoint) {
            grid-column: 7 / span 9;

            >.map {
                position: relative !important;

                --map-icon-width: #{size(63px)};
                --map-icon-height: #{size(94px)};
                --map-logo-size: #{size(25px)};
            }
        }
    }

    &__title {
        @include alternate-default-font;

        @include respond-upto($media-query-small) {
            margin-top: -15%;
        }

        @include respond-between($media-query-small, $site__desktop-breakpoint) {
            margin-top: -25%;
        }

        @include respond-upto($site__tablet-breakpoint) {
            @include font-size($font-size-xlarge);
            margin-bottom: spacing(16);
        }

        @include respond-between($site__tablet-breakpoint, $site__desktop-breakpoint) {
            @include font-size($font-size-xxlarge);
            margin-bottom: spacing(16);
        }

        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xxxxlarge);
            margin-bottom: calc((var(--vh, 1vh) * 100) * 0.16);
            grid-column: 1 / -1;
        }
    }

    &__address {
        @include light-font;
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        @include respond-upto($site__desktop-breakpoint) {
            &:not(:last-child) {
                margin-bottom: spacing(20);
            }
        }

        @include respond-upto($site__tablet-breakpoint) {
            @include font-size($font-size-xxsmall);

        }

        @include respond-from($site__tablet-breakpoint) {
            @include font-size($font-size-small);
        }

        &__email,
        &__telephone {
            @include o-link--red;
            text-underline-position: under;

            @include respond-from($site__desktop-breakpoint) {
                @include default-font;
            }
        }

        &__address-lines {
            &:not(:last-child) {
                @include respond-upto($site__desktop-breakpoint) {
                    margin-bottom: 1.35em;
                }

                @include respond-from($site__desktop-breakpoint) {
                    margin-bottom: 1.35em;
                }
            }

            &__line {
                @include light-font;
                display: block;
            }
        }
    }

    &__opening-hours {
        &__title {
            @include alternate-default-font;

            @include respond-upto($site__tablet-breakpoint) {
                @include font-size($font-size-medium);
            }

            @include respond-from($site__tablet-breakpoint) {
                @include font-size($font-size-large);
            }
        }

        &__sections {
            @include light-font;
            border-top: 1px solid $colour-grey-xdark;
            border-bottom: 1px solid $colour-grey-xdark;
            padding-bottom: spacing(4);
            padding-top: spacing(4);
            margin-top: spacing(4);

            &__section {

                &__title,
                &__text {
                    @include respond-upto($site__tablet-breakpoint) {
                        @include font-size($font-size-xxsmall);
                    }

                    @include respond-from($site__tablet-breakpoint) {
                        @include font-size($font-size-small);
                    }
                }

                &__title {
                    @include default-font;
                }

                &__text {
                    @include light-font;
                }
            }
        }
    }

}