.google-reviews-v2 {
    $this: '.google-reviews-v2';
    display: grid;    

    @include respond-upto($site__tablet-breakpoint) {
        --button-size: #{size(40px)};
        --buttons-margin: #{size(10px)};
    }

    @include respond-from($site__tablet-breakpoint) {
        --button-size: #{size(75px)};
        --buttons-margin: #{size(30px)};
    }

    &::before,
    &__content {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
    }
    &::before {
        background: $colour-grey-light;
        content: '';
        margin-top: spacing(6);
        margin-left: calc(-1 * var(--container-gutter-width));
        width: calc(100% + var(--container-gutter-width));
        @include u-ratio(2,1);
        @include o-triangle('top-left');

        @include respond-from($site__tablet-breakpoint) { 
            margin-top: spacing(32);
            width: calc(90% + var(--container-gutter-width));
        }
    }

    &__content {
        align-content: start;
        column-gap: var(--column-gap);
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        isolation: isolate;
        row-gap: spacing(4);
        z-index: 1;

        grid-template-areas:
        'title  title   title   title   title   title   title   title   title   title   title   title'
        'reviews    reviews    reviews    reviews   reviews   reviews   reviews   reviews   reviews  reviews  reviews  reviews'
        'badge    badge    badge    badge   badge   badge   badge   .  .  .  .  .';

        @include respond-from($site__tablet-breakpoint) {
            grid-template-areas:
            'title  title   title   title   title   title   title   title   title   badge   badge   badge'
            'reviews    reviews    reviews    reviews   reviews   reviews   reviews   reviews   reviews  reviews  reviews  reviews';

            row-gap: spacing(24);
        }
    }

    &__support-title {
        @include header--support-title;
        @include o-support-title-animation($isMultiLine: true);
        
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        grid-area: title;
        
        span {
            &:nth-of-type(odd) {
                --translate-x-distance: 20%;
            }
            &:nth-of-type(even) {
                --translate-x-distance: 10%;
            }
        }
    }
    &__badge {
        align-self: start;
        grid-area: badge;
        position: relative;
        max-width: size(165px);

        @include respond-upto($site__tablet-breakpoint) {
            margin-top: calc(-1 * (var(--button-size) + var(--buttons-margin)));
        }

        @include respond-from($site__tablet-breakpoint) {
            justify-self: end;
            max-width: spacing(40);
        }

        &__logo {
            height: 30px;
            width: 100%;
        }
        &__stars {
            display: grid;
            gap: spacing(1);
            grid-template-columns: repeat(5, minmax(0, 1fr));

            &__star {
                position: relative;
                &::before {
                    @include u-square-pseudo;
                }

                svg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }
        &__link {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            &__label {
                @include u-visually-hide;
            }
        }
        &__footer {
            display: flex;
            gap: 1ch;

            > *:not(:first-child)::before {
                content: ' | ';
            }
        }
        &__average-rating {
            @include bold-font;
            @include font-size($font-size-small);

            @include respond-from($site__tablet-breakpoint) {
                @include font-size($font-size-xsmall);
            }
        }
        &__review-count {
            @include light-font;
            @include font-size($font-size-small);

            @include respond-from($site__tablet-breakpoint) {
                @include font-size($font-size-xsmall);
            }
        }
    }
    &__slider {
        grid-area: reviews;
        position: relative;

        @include respond-from(size(1600px, $unit-em)) {
            grid-column: 2 / -2;
        }

        &__slides {
            --gap: #{$column-gutter-default * 2};
            --slider-padding: 0;
            position: static;

            --one-item-breakpoint: 600;
            --two-item-breakpoint: 900;
            --three-item-breakpoint: 1200;

            > .splide {
                &__track {
                    @include backend-specific {
                        display: grid;
                        grid-template-columns: repeat(3, 1fr);
                    }
                }

                &__arrows.is-arrows-disabled {
                    display: none;
                }
                &__arrows:not(.is-arrows-disabled) + .splide__track {
                    padding-bottom: calc(var(--button-size) + var(--buttons-margin));
                }

                &__arrows {
                    align-items: center;
                    bottom: 0;
                    display: flex;
                    justify-content: space-between;
                    position: absolute;
                    gap: size(10px);
                    z-index: 3;

                    @include respond-upto($site__tablet-breakpoint) {
                        right: 0;
                    }

                    @include respond-from($site__tablet-breakpoint) {
                        right: 10px;
                    }

                    > .splide__arrow {
                        align-items: center;
                        background-color: $colour-red;
                        display: flex;
                        opacity: 1;
                        justify-content: center;
                        transition: opacity 0.3s $easeInOutQuart;
                        z-index: 1;
                        height: var(--button-size);
                        width: var(--button-size);

                        &[disabled] {
                            cursor: default;
                            opacity: 0;
                        }
    
                        &--prev {
                            transform: rotate(90deg);
                        }
                        &--next {
                            transform: rotate(-90deg);
                        }
    
                        svg {
                            stroke: $colour-white;

                            @include respond-upto($site__tablet-breakpoint) {
                                height: size(16px);
                                width: size(16px);
                            }
    
                            @include respond-from($site__tablet-breakpoint) {
                                height: size(22px);
                                width: size(22px);
                            }
                        }
                    }
                }
                &__track {
                    overflow: visible;
                }
            }

             // Match slide content heights
             .splide__slide {
                display: flex;
                align-items: stretch;
            }  
        }

    }
    &__review {
        $iconOffset: size(10px);

        @include default-font;
        box-shadow: $box-shadow;
        background-color: $colour-black;
        color: $colour-white;
        display: flex;
        flex-direction: column;

        @include respond-from($site__tablet-breakpoint) {
            margin: $iconOffset $iconOffset 0;
        }

        @include respond-from($site__desktop-breakpoint) {
            --animate-delay: calc(0.05s * var(--index));
        }
        
        &__icon {
            @include o-triangle('top-left');
            background-color: $colour-red;
            height: size(50px);
            margin-bottom: -$iconOffset;
            padding-left: spacing(2);
            padding-top: spacing(1);
            width: size(100px);
            transform: translate(-$iconOffset, -$iconOffset);
            
            svg {
                height: size(25px);
                width: size(25px);
            }
        }
        &__content {
            flex-grow: 1;
            display: flex;
            gap: spacing(6);
            flex-direction: column;
            padding: 0 spacing(12) spacing(6);
        }
        &__text {
            @include font-size($font-size-xsmall);
        }
        &__footer {
            border-top: 1px solid;
            padding-top: spacing(3);
            margin-top: auto;
        }
        &__author {
            @include font-size($font-size-small);
            display: block;
        }
        &__reviewer-type {
            @include font-size($font-size-xxxsmall);
        }
        &__rating {
        }
    }
}
