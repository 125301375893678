.simple-cta {
    $this: '.simple-cta';
    
    @include o-button-theme($buttonSelector: #{$this}__link);
    column-gap: var(--column-gap);
    display: grid;
    position: relative;
    grid-template-columns: repeat(12, minmax(0, 1fr));

    grid-template-areas: 
        'title title title title title title title title title . . .'
        'body body body body body body body body body body body body';

    @include respond-from($media-query-small) {
        grid-template-areas: 
        'title title title title title title title . . . . .'
        'body body body body body body body . . . . .';
    }

    @include respond-from($site__tablet-breakpoint) {
        grid-template-areas: 
            'title title title title title title . body body body body .';
    }

    &__support-title {
        @include header--support-title;        
        @include o-support-title-animation($isMultiLine: true, $lineCharLength: 15);

        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        grid-area: title;
        
        span {            
            &:nth-of-type(odd) {
                --translate-x-distance: -20%;
            }
            &:nth-of-type(even) {
                --translate-x-distance: -10%;
            }
        }

        @include respond-upto($site__tablet-breakpoint) {
            margin-bottom: size(28px);
        }
    }
    
    &__body {
        grid-area: body;        
    }
    &__title {
        @include default-font;
        @include font-size($font-size-small);
        max-width: 25ch;

        @include respond-from($site__tablet-breakpoint) {
            @include font-size($font-size-large);
        }

        span {
            color: $colour-red;
        }
    }
    &__link {
        &:not(:first-child) {
            margin-top: spacing(10);
        }

        @include respond-upto($media-query-small) {
            width: 100%;
            justify-content: center;
        }
    }
}
