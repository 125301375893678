.history-summary {
    $this: '.history-summary';

    @include o-button-theme($buttonSelector: '&__link');
    padding-bottom: spacing(20);
    position: relative;

    @include respond-from($site__tablet-breakpoint) {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        column-gap: var(--column-gap);
    }

    &::before {               
        @include o-triangle('bottom-left');        
        background-color: $colour-grey-new-0;
        content: '';
        grid-column: 1 / span 11;
        position: absolute;                    
        bottom: 0;
        left: 0;
        margin-left: calc(var(--container-gutter-margin) * -1);
        width: 100%;
        z-index: -1;
        
        @include respond-upto($site__tablet-breakpoint) {
            @include u-ratio(4, 5);
            max-height: 100%;
        }
        @include respond-from($site__tablet-breakpoint) {
            @include u-ratio(5, 2);
        }
    }

    &__section-tag {
        @include o-section-tag($breakpoint: $site__tablet-breakpoint);
        align-self: start;
        grid-column: 1 / span 1;
    }

    &__main {
        grid-column: 2 / -1;
        @include respond-upto($site__desktop-breakpoint) {            
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            column-gap: var(--column-gap);

            --left-column:  1 / span 1;
            --right-column:  span 1 / -1;
        }
        @include respond-from($site__desktop-breakpoint) {
            display: grid;
            grid-template-columns: repeat(11, minmax(0, 1fr));
            column-gap: var(--column-gap);

            --left-column:  1 / span 5;
            --right-column:  span 5 / -1;
        }

        &__left,
        &__right {
            @include respond-from($site__desktop-breakpoint) {
                grid-row-end: span 3;
            }
        }

        &__left {
            grid-column: var(--left-column);
        }
        &__right {            
            grid-column: var(--right-column);
        }
    }

    &__title {
        @include header--support-title;
        @include o-support-title-animation($isMultiLine: true, $isMultiLineAutoAlignment: false);

        @include respond-upto($site__tablet-breakpoint) {
            column-gap: 1ch;
            display: flex;
            flex-wrap: wrap;
            grid-column: 1 / -1;
        }
        @include respond-from($site__tablet-breakpoint) {
            display: contents;
        }

        &__left,
        &__right {
            margin-bottom: calc(-0.35 * var(--line-height-em));
            isolation: isolate;
            z-index: 1;

            @include respond-upto($site__tablet-breakpoint) {
                flex: 1 1 0%;
            }
        }

        &__left {
            grid-column: var(--left-column);
            --translate-x-distance: -20%;
            text-align: right;
        }
        &__right {
            grid-column: var(--right-column);
            --translate-x-distance: 20%;
            text-align: left;
            
            @include respond-from($site__desktop-breakpoint) {
                grid-row-start: 2;                
                margin-top: spacing(10);
            }
        }
    }

    &__image {
        display: block;

        &--left {
            img { 
                @include o-notched-corner($corner: 'top-left', $height: spacing(10));
            }
        }
        &--right {            
            img { 
                @include o-notched-corner($corner: 'bottom-right', $height: spacing(10));
            }
        }
    }

    &__body-text {
        @include default-font;
        @include font-size($font-size-xsmall);
        margin-top: spacing(12);
        
        @include respond-upto($site__desktop-breakpoint) {
            grid-column: 1 / -1;
        }
        @include respond-from($site__desktop-breakpoint) {
            grid-column: var(--left-column)
        }

        &__content {
            p {
                break-inside: avoid;
                width: unquote('min(100%, 60ch)');

                &:not(:last-child) {
                    margin-bottom: var(--line-height-em);
                }
            }
        }
    }

    &__link {
        margin-right: calc(-1 * var(--column-gap));
        margin-top: spacing(4);
    }
}
