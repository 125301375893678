$property-summary__content-shift: spacing(5);

.property-summary {
    $this: '.property-summary';

    color: $colour-black;
    position: relative;
    padding-top: $property-summary__content-shift;

    &__wrapper {
        background-color: $colour-white;
        display: flex;
        flex-direction: column;
        transition: transform 0.4s $easeOutQuad;
        padding-left: 1px;
        position: relative;
        width: 100%;

        &::before {
            background: linear-gradient(180deg, $colour-grey-xdark 0%, $colour-grey-xdark 50%, $colour-red 50%, $colour-red 100%);
            background-size: 100% 200%;
            background-position: center 0%;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            transition: background-position 0.8s $easeInOutQuad;
            width: 1px;
        }
    }

    &__link {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
    
    &__images {         
        &__image {
            img {
                transform: translateZ(0); // Enable hardware acceleration (transform on wrapper element was causing image to jitter for some reason)
                width: 100%;
            }
            span {
                display: none;
            }
        }
    }

    .splide {
        &__arrows {
            display: flex;
            justify-content: space-between;
            left: 0;
            height: 100%;
            overflow: hidden;
            position: absolute;
            pointer-events: none; 
            right: 0;
            transform: translateY(-100%);
            z-index: 1;
        }

        &__slide {
            
        }
        
        &__arrow {            
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
            padding: spacing(2);
            position: relative;
            pointer-events: auto;
            
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                background-size: 100% 100%;
                pointer-events: none;
                transition: transform 0.3s $easeInOutQuart;
                user-select: none;
            }
            
            &--prev {
                --degrees: 90deg;

                &::before {
                    background: linear-gradient(scrim-gradient(rgba($colour-black, 0.6), 'to right'));   
                    left: -25%;
                    right: -75%;
                    transform: translateX(-100%);
                }
            }
            &--next {
                --degrees: -90deg;

                &::before {
                    background: linear-gradient(scrim-gradient(rgba($colour-black, 0.6), 'to left'));
                    left: -75%;
                    right: -25%;
                    transform: translateX(100%);
                }
            }
    
            svg {
                height: spacing(4);
                stroke-width: 2px;
                stroke: $colour-white;
                transition: opacity 0.3s ease-in-out;
                transform: rotate(var(--degrees));
                width: spacing(4);
            }

            &[disabled] {
                cursor: default;
                
                svg {
                    opacity: 0;
                }
            }

            &:not([disabled]):hover{
                &::before {
                    opacity: 1;
                    transform: none;
                }
            }
        }

    }

    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: spacing(6) calc($column-gutter-default/2) spacing(5);
    }

    &__header {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
    }

    &__title,
    &__price {
        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-medium);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-large);
        }
    }

    &__promotion-message,
    &__meta-lines {
        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-xxxsmall);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xxsmall);
        }
    }
    
    &__title {
        @include alternate-default-font;
        line-height: 1.25!important;
    }

    &__promotion-message {
        @include default-font;
        background-color: $colour-red;
        color: $colour-white;
        left: -$property-summary__promotion-overhang;
        padding: spacing(3);
        pointer-events: none;
        position: absolute;
        top: spacing(2);
        z-index: 1;
    }

    &__price {
        @include default-font;
        margin-bottom: spacing(8);
    }

    &__meta-lines {
        @include default-font;
        margin-top: auto;
        
        &__meta-line {
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
        }
    }

    &__triangle {
        fill: $colour-grey-xdark;
        flex-shrink: 0;
        height: size(13px);
        margin-bottom: 0.3em;
        margin-left: $column-gutter-default;
        width: size(25px);
    }
            
    &__triangle,
    &__title {
        transition: fill 0.3s ease-in-out, color 0.3s ease-in-out;
    }

    &--promotion {
        &-black {
            color: $colour-grey-xxdark;

            #{$this} {
                &__triangle {
                    fill: $colour-grey-mid-light;
                }
                &__images {
                    &__image {
                        img {
                            filter: grayscale(1);
                        }
                    }
                }
                &__promotion-message {
                    background-color: $colour-black;
                    color: $colour-white;
                }
            }
        }
        &-red { 
            #{$this} {
                &__promotion-message {
                    background-color: $colour-red;
                    color: $colour-white;
                }
            }
        }
    }

    &:not(&--promotion-black) {
        #{$this} {
            &__title {                
                transition-delay: 0.4s;
            }
            &__triangle {                
                transition-delay: 0.5s;
            }
        }
    }
    
    &:hover {
        @include is-not-touch {
            #{$this} {
                &__wrapper {
                    transform: translate3d(0, -#{$property-summary__content-shift}, 0);
                    transition-timing-function: $easeInOutQuad;
                }
            }
        }
    }

    &:focus-within,
    &:hover {    
        &:not(#{$this}--promotion-black) {
            #{$this} {
                &__wrapper::before {
                    background-position: center 100%;
                    transition-delay: 0.1s;
                }
                &__title {
                    color: $colour-red;
                    transition-delay: 0.3s;
                }
                &__triangle {
                    fill: $colour-red;
                    transition-delay: 0s;
                }
            }
        }

        &#{$this}--promotion-black {
            color: $colour-black;

            #{$this} {
                &__triangle {
                    fill: currentColor;
                }
            }
        }
    }
}