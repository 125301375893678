.angled-section {
    $columnCount: 12;
    --content-col-count: (#{$columnCount} - (var(--offset-left, 0) + var(--offset-right, 0)));

    $this: '.angled-section';
    $spacingVariables: (
        text: (
            top,
            bottom
        ),
        offset: (
            top,
            bottom,
            left,
            right
        )
    );
    
    position: relative;
    overflow: hidden;
    
    @each $variableType, $variableDirections in $spacingVariables {
        @each $direction in $variableDirections {
            $variableFull: --#{$variableType}-#{$direction};
            
            @include respond-upto($media-query-small) {
                #{$variableFull}: var(#{$variableFull}--small, 0);
            }
            @include respond-between($media-query-small, $media-query-medium) {
                #{$variableFull}: var(#{$variableFull}--medium, 0);
            }
            @include respond-between($media-query-medium, $media-query-large) {
                #{$variableFull}: var(#{$variableFull}--large, 0);
            }
            @include respond-from($media-query-large) {
                #{$variableFull}: var(#{$variableFull}--xlarge, 0);
            }
        }
    }
    
    &__background {
        &__image {
            @include u-image-cover;
        }
    }
    
    &__wrapper {
        @include container;
        margin-top: var-rem-calc(--offset-top);
        overflow: hidden;
        padding-bottom: var-rem-calc(--offset-bottom);

        &::before {
            background-color: var(--background-colour);
            bottom: 0;
            content: '';
            top: var-rem-calc(--offset-top);
            position: absolute;
            width: var(--container-gutter-width);
        }
    }

    &__content {
        background-color: var(--background-colour);
        position: relative;
        padding-top: var-rem-calc(--text-top);
        padding-bottom: var-rem-calc(--text-bottom);
        z-index: 1;
    }

    &__columns {
        display: flex;

        &--single {
            // @include column-gutters;
        }
    }

    &__column {
        align-items: flex-start;
        display: flex;
        flex: 1 1 0%;
        flex-direction: column;

        &:not(:first-child) {
            margin-left: calc((1 / var(--content-col-count)) * 100%);
        }
    }
    
    &__triangle {
        position: absolute;
        pointer-events: none;
        user-select: none;
        width: unquote('max(') 200vh, 100vw unquote(')');
        z-index: -1;

        &::before {
            content: '';
            background-color: var(--background-colour);
            transform: skew(29.092deg, 0deg);
            min-height: 100%;
            min-width: 100%;
            position: absolute;
        }
        
        &:nth-of-type(1) {
            top: 0;
            bottom: 0;

            &::before {
                transform-origin: bottom left;
            }

            &::before {
                bottom: 0;
                left: 100%;
            }
        }
        &:nth-of-type(2) {
            top: 100%;
            bottom: calc(-100% - #{var-rem-calc(--offset-bottom)});

            &::before {
                transform-origin: top left;
            }
            
            &::before {
                top: 0;
                left: 0;
            }
        }
    }        
    
    &--left {
        #{$this} {
            &__wrapper {
                &::before {
                    left: 0;
                }
            }
            &__content {
                padding-left: calc(((var(--offset-left) / #{$columnCount}) * 100%));
                margin-right: calc(((var(--offset-right) / #{$columnCount}) * 100%));
                width: calc(100% - (((var(--offset-right) / #{$columnCount}) * 100%)));
            }
            &__triangle {
                transform: scaleX(-1);
                
                &:nth-of-type(1) {
                    left: 100%;
                }
                &:nth-of-type(2) {
                    right: 0;
                }
            }   
        }
    }
    
    &--right {
        #{$this} {
            &__wrapper {
                &::before {
                    right: 0;
                }
            }
            &__content {
                padding-right: calc(((var(--offset-right) / #{$columnCount}) * 100%));
                margin-left: calc(((var(--offset-left) / #{$columnCount}) * 100%));
                width: calc(100% - (((var(--offset-left) / #{$columnCount}) * 100%)));
            }
            &__triangle {
                &:nth-of-type(1) {
                    right: 100%;
                }
                &:nth-of-type(2) {
                    left: 0;
                }
            }        
        }
    }        

    &__title {
        @include header--small;
    }

    &__text {
        @include light-font;
        @include font-size($font-size-small);
        overflow: hidden;

        &:not(:last-child) {
            margin-bottom: auto;
        }
        
        p {
            margin-bottom: spacing(2);
        }
    }

    &__link {
        @include o-button--ghost-white;
        margin-top: spacing(24);
    }

    // Fallback background
    &--image {
        background-color: $colour-grey-xxxdark;
    }

    &--theme {
        &-white {
            --background-colour: #{$colour-white};
        }
        &-black {
            --background-colour: #{$colour-black};

            @include backend-specific {
                .rte {
                    color: $colour-white!important;
                }
            }
        }
    }
}