﻿@keyframes wobble {
    16.65% {
        transform: skew(-0.8deg);
        
    }
    33.3% {
        transform: skew(0.75deg);
        
    }
    49.95% {
        transform: skew(-0.6deg);
        
    }
    66.6% {
        transform: skew(0.45deg);
        
    }
    83.25% {
        transform: skew(-0.25deg);        
    }
    100% {
        transform: skew(0deg);
    }    
}