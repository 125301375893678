//*------------------------------------
//  Utility : Visually Hide
//  ------------------------------------*/

////
/// @group -Library-
////

///
/// Force element to be square using a ::before/::after
///
@mixin u-square-pseudo {
	@include u-ratio(1, 1, 'padding-bottom');
	content: '';
	display: block;
}
