.social-wrapper {
    column-gap: var(--column-gap);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    
    @include respond-upto($media-query-mediumlarge) {
        row-gap: spacing(24);
    }
    @include respond-from($media-query-mediumlarge) {
        row-gap: spacing(20);
    }

    &__google,
    &__twitter {
        @include respond-upto($media-query-mediumlarge) {
            grid-column-end: span 12;
        }
    }

    &__google {
        @include respond-from($media-query-mediumlarge) {
            grid-column-end: span 5;
        }
    }

    &__twitter {
        @include respond-from($media-query-mediumlarge) {
            grid-column: 7 / -1;
        }
    }

    &__instagram {
        grid-column: 1 / -1;
    }
}