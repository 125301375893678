.map-overlay-text {
    $this: '.map-overlay-text';
    
    column-gap: var(--column-gap);
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));

    &__map {
        @include u-ratio(2, 1);
        position: relative;
        margin-left: calc(-1 * var(--container-gutter-width));
        margin-right: calc(-1 * var(--container-gutter-width));
        grid-column: 1 / -1;
        max-height: size(860px);
        min-height: size(400px);
        min-width: calc(100% + (var(--container-gutter-width) * 2));

        > *:first-child {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    &__support-title {
        @include header--support-title;
        position: absolute;
        pointer-events: none;
        top: 9%;
        left: 13%;
    }
    &__text {
        justify-self: end;
        color: $colour-white;
        filter: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
        grid-column: 1 / -1;
        isolation: isolate;
        margin-top: spacing(-24);
        margin-left: calc(-1 * var(--container-gutter-margin));
        margin-right: calc(-1 * var(--container-gutter-margin));
        max-width: size(720px);
        padding: spacing(16) calc(var(--container-gutter-margin) + #{spacing(8)}) spacing(24) spacing(8);
        position: relative;
        
        &::before {
            @include o-notched-corner('top-right', $height: spacing(16));
            background-color: $colour-red;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            content: '';
            z-index: -1;
        }
    }
    &__title {
        @include alternate-medium-font;
        margin-bottom: spacing(5);

        @include respond-upto($site__tablet-breakpoint) {
            @include font-size($font-size-xlarge);
        }
        @include respond-from($site__tablet-breakpoint) {
            @include font-size($font-size-xxlarge);
        }
    }
    &__body-text {
        @include font-size($font-size-xsmall);
        @include default-font;

        p {
            max-width: 54ch;
        }
    }
}