.team-member {
    $this: '.team-member';

    

    @include font-size($font-size-xsmall);
    @include light-font;

    &__image {
        display: block;
        margin-bottom: spacing(8);
    }

    &__name {
        @include default-font;
    }

    &__meta {
        &__metum {            
            &:not(:last-child) {
                &::after {
                    content: ' . ';
                }
            }
        }
    }
    &__contact-links {
        border-top: 1px solid $colour-black;
        margin-top: spacing(8);
        padding-top: spacing(4);
        display: flex;
        gap: 1em;

        &__contact-link {
            svg {
                height: spacing(6);
                width: spacing(6);
            }
            span {
                @include u-visually-hide;
            }
        }
    }
    &__body-text {
        @include default-font;
        margin-top: spacing(12);
        max-width: 50ch;
    }
    &:not(&--listing) {
        #{$this}__body-text {
            display: none;
        }
    }
}
