.hero {
    $this: '.hero';

    color: $colour-white;
    position: sticky;
    top: 0;
    
    @include frontend-specific {
        z-index: 0;
    }
    @include backend-specific {
        z-index: 1;
    }
    
    @include reduced-motion {
        position: relative;
    }
    
    &__slides {
        height: 100%;        
    }

    .splide {
        @include backend-specific {
            visibility: visible;

            &__list {
                width: 100%;
            }
            &__slide {
                &:not(:first-child) {
                    display: none;
                }
            }
        }
        
        &__track {

        }
        &__list {

        }
        &__slide {
            
        }

        &__pagination {
            @include container;
            position: absolute;
            bottom: spacing(20);
            right: 0;
            width: auto;
            z-index: 1;

            @include respond-upto($media-query-smallmedium) {
                display: none;
            }

            li {
                &:not(:last-child) {
                    margin-right: spacing(2);
                }
            }

            &__page {
                --fill: none;
                
                background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 16' xml:space='preserve'%3E%3Cpath style='fill:none;stroke:%23FFFFFF;' d='M0.5,15.8h30.1L0.5,0.8V15.8z'/%3E%3C/svg%3E%0A");
                height: size(16px);
                width: size(30px);

                &.is-active {
                    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 16' xml:space='preserve'%3E%3Cpath style='fill:%23FFFFFF;stroke:%23FFFFFF;' d='M0.5,15.8h30.1L0.5,0.8V15.8z'/%3E%3C/svg%3E%0A");
                }
            }
        }
    }
    
    &__slide {
        @include u-fill-viewport;
        @include container;
        align-items: center;
        display: flex;
        flex-direction: column;
        min-height: size(500px);
        overflow: hidden;
        position: relative;
        justify-content: flex-end;

        &:only-child {
            width: 100%;
        }

        &.is-active,
        &.is-active ~ & {
            transform: translateZ(100px);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba($colour-black, 0.5);
        }
    }
    

    &__title,
    &__subtitle,
    &__scroll-indicator {
        z-index: 0;
    }


    &__image {
        &__inner {
            @include u-image-cover;
            transition: transform linear;
            transition-duration: 0s;
            transition-delay: calc((var(--interval)) * 1ms);
            z-index: -1;
        }
    }

    &__text {
        margin: auto auto auto 0;
        position: relative;
    }

    &__title {
        @include alternate-default-font;

        @include respond-upto($site__tablet-breakpoint) {
            @include font-size($font-size-xlarge);
        }
        @include respond-from($site__tablet-breakpoint) {
            @include font-size($font-size-xxxxlarge);
        }
    }

    &__subtitle {
        @include alternate-default-font;
        padding: size(3px);

        @include respond-upto($site__tablet-breakpoint) {
            @include font-size($font-size-medium);
        }
        @include respond-from($site__tablet-breakpoint) {
            @include font-size($font-size-xlarge);
        }
    }

    &__scroll-indicator {
        @include container;
        align-items: center;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        position: absolute;
        
        @include respond-upto($site__tablet-breakpoint) {
            bottom: spacing(5);
        }
        @include respond-from($site__tablet-breakpoint) {
            bottom: spacing(20);
        }
        
        &__text {
            @include alternate-default-font;
            @include font-size($font-size-small);
            margin-bottom: spacing(6);
        }

        &__icon {
            height: spacing(6);
            stroke-width: 3px;
            stroke: currentColor;
            transform: scaleY(-1);
        }
    }

    &:not(&--single) &__slide.is-active{
        #{$this}__image__inner {
            transition-duration: calc((var(--interval) + var(--speed)) * 1ms);
            transform: scale(1.1);
            transition-delay: 0s;
        }
    }

    &--single {
        .splide {
            visibility: visible;

            &__list {
                width: 100%;
            }
        }
    }
}