.property-detail-nearby {
    background-color: $colour-white;
    @include o-scroll-margin-top(calc(var(--site-header-height) + var(--property-detail-nav-height)));

    // For JS
    --mobile-width: #{map-get($breakpoints, $site__desktop-breakpoint)};

    @include respond-upto($site__desktop-breakpoint) {
        display: grid;
    }

    &__tabs {
        @include respond-upto($site__desktop-breakpoint) {
            display: contents;
        }
        @include respond-from($site__desktop-breakpoint) {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(size(80px), max-content));
            margin-bottom: spacing(16);
        }

        &__button {
            @include default-font;
            @include font-size($font-size-small);
            text-align: left;

            @include u-ellipsis;

            @include respond-upto($site__desktop-breakpoint) {
                padding: spacing(1) 0;
                order: var(--index);
            }

            @include respond-upto($site__desktop-breakpoint) {
                border-bottom: 1px solid $colour-grey-xdark;
            }
            @include respond-from($site__desktop-breakpoint) {
                &:not(:first-child) {
                    padding-left: spacing(10);
                }
                &:not(:last-child) {
                    padding-right: spacing(10);
                    border-right: 1px solid $colour-grey-xdark;
                }
            }

            @include respond-upto($site__desktop-breakpoint) {
                &.is-active {
                    border-bottom-color: $colour-red;
                }
            }
            @include respond-from($site__desktop-breakpoint) {
                &[aria-selected="true"] {
                    text-decoration: underline;
                    text-underline-position: under;
                    text-decoration-color: $colour-red;                
                }
            }
        }
    }

    &__tab {        

        &__content {
            @include light-font;

            &:not(.is-active) {
                @include respond-upto($site__desktop-breakpoint) {
                    display: none;
                }
            }
            
            @include respond-upto($site__desktop-breakpoint) {
                @include font-size($font-size-xsmall);
                order: var(--index);
                padding-top: spacing(3);
                
                &:not(:last-child) {
                    border-bottom: 1px solid $colour-grey-xdark;
                    padding-bottom: spacing(10);
                }
            }
            @include respond-from($site__desktop-breakpoint) {
                @include font-size($font-size-small);
            }
        }
        &__section {
            &:not(:last-child)::after {
                content: css-character(newLine) + '-' + css-character(newLine);
                white-space: pre;
            }
        }
        &__title {
            @include default-font;
        }
        &__description {
    
        }
        &__vicinity {
    
        }
    }
}