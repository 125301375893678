.strapline-text {
    $this: '.strapline-text';
    padding: spacing(12) 0;
    position: relative;
    text-align: center;
    
    @include respond-from($media-query-smallmedium) {
        padding: spacing(24) 0;
    }

    @include respond-from($site__desktop-breakpoint) {
        padding-left: size(110px);
        text-align: left;
    }

    .northern-logo-pattern {
        left: 0;
        max-height: 100%;
        max-width: 100%;
        overflow: visible;
        position: absolute;
        top: 0;
        z-index: -1;
        
        path {
            fill: $colour-grey-new-0;
            transform-box: fill-box;
            transform-origin: left center;
            --scale-modifier: var(--column-index) * 0.075;
        }
    }
    
    &__text {
        @include font-size($font-size-xxlarge);
        @include alternate-medium-font;
        max-width: 20ch;
        
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xxxxlarge);
        }
        
        u {
            text-decoration-color: $colour-red;
            text-decoration-thickness: 2px;
            text-underline-offset: 6%;
        }
    }
    
    &.is-animating .northern-logo-pattern {
        path {
            animation: strapline-text-pulse 2s both;
            animation-delay: calc((var(--row-index) + var(--column-index)) * 0.08s);
        }
    }
}

@keyframes strapline-text-pulse {
    0%, 
    100%{
        transform: scale(1);
        animation-timing-function: $easeOutQuad;
        fill: $colour-grey-new-0;
    }
    45%,
    55% {
        animation-timing-function: $easeInOutQuad;
        transform: scale(calc(1.2 + var(--scale-modifier)));
        fill: $colour-grey-new-2;
    }
}