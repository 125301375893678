.triangle-gallery {
    $this: '.triangle-gallery';
    display: grid;

    &::before,
    &__content {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
    }
    &::before {
        background-color: $colour-grey-new-0;
        content: '';
        margin-left: calc(-1 * var(--container-gutter-width));
        width: calc(100% + var(--container-gutter-width));
        @include u-ratio(2,1);
        @include o-triangle('top-left');

        @include respond-from($media-query-mediumlarge) {
            width: calc(90% + var(--container-gutter-width));
        }
    }

    &__content {
        align-content: start;
        align-items: center;
        column-gap: var(--column-gap);
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        row-gap: spacing(10);
        isolation: isolate;
        z-index: 1;

        @include respond-from($media-query-mediumlarge) {
            row-gap: spacing(24);
        }
    }

    &__support-title {
        @include header--support-title;
        @include o-support-title-animation;
        --translate-x-distance: 20%;
        
        margin-top: calc(-0.5 * var(--line-height-em));
        grid-column: 1 / -1;
    }
    &__gallery {

        grid-column: 1 / span 12;

        @include respond-from($media-query-smallmedium) {
            grid-column: 2 / span 10;
        }

        @include respond-from($media-query-mediumlarge) {
            grid-column: 1 / span 6;
            margin-right: -($column-gutter-default * 2);
            position: relative;
        }
        
        &__image {
            width: 100%;
        }
        &__caption {
            @include o-section-tag($includeHyphen: false);
            transition-timing-function: $easeOutQuad;
            transition-property: opacity;
        }

        .splide {

            &__arrows {
                bottom: spacing(6);
                display: flex;
                gap: spacing(4);
                position: absolute;
                right: $column-gutter-default * 0.5;
                transform: translateY(50%);
                z-index: 1;

                @include respond-from($media-query-small) {
                    right: $column-gutter-default * 1;
                }

                @include respond-from($media-query-mediumlarge) {
                    bottom: 0;
                    right: $column-gutter-default * 2;
                }
            }

            &__slide {
                align-items: flex-start;
                display: flex;
                gap: spacing(3);
                flex-direction: column-reverse;

                @include respond-from($media-query-mediumlarge) {
                    flex-direction: row;
                }

                &:not(.is-active) {
                    #{$this}__gallery__caption {
                        opacity: 0;
                        transition-duration: 0.1s;
                    }
                }
                &.is-active {
                    #{$this}__gallery__caption {
                        opacity: 1;
                        transition-duration: 0.7s;
                        transition-delay: 0.2s;
                    }
                }
            }

            &__arrow {
                align-items: center;
                background-color: $colour-grey-new-2;
                display: flex;
                height: spacing(12);
                justify-content: center;
                width: spacing(12);

                @include respond-from($media-query-small) {
                    height: spacing(16);
                    width: spacing(16);
                }

                svg {
                    height: size(18px);
                    width: size(18px);
                    stroke: $colour-black;
                }

                &--prev {
                    transform: rotate(90deg);
                }
                &--next {
                    transform: rotate(-90deg);
                }

                &[disabled] {
                    cursor: default;
                    opacity: 0;
                }
            }
        }
    }
    &__text {
        grid-column: 1 / span 12;

        @include respond-from($media-query-smallmedium) {
            grid-column: 2 / span 8;
        }

        @include respond-from($media-query-mediumlarge) {
            grid-column: 8 / span 4;
        }

        .splide {
            &__slide {
                &:not(.is-active) {
                    transition: opacity 0.8s ease!important;
                }
                &.is-active {
                    transition: opacity 1.6s $easeOutQuad 0.8s!important;                    
                }
            }
            
            &:not(.is-active) &__slide {
                opacity: 0!important;
                transition: none!important;
            }

            // Prevent height jumps on desktop
            @include respond-from($media-query-mediumlarge) {
                &__list {
                    display: grid!important;
                    grid-template-columns: 1fr;
                    grid-template-rows: 1fr;
                }

                &__slide {
                    align-items: center;
                    display: flex;
                    position: relative!important;
                    grid-column: 1 / -1;
                    grid-row: 1 / span 1;
                }
            }
        }
    }

    .splide {

        @include backend-specific {
            &__list {

                li:not(:first-child) {
                    display: none;
                }
            }
        }
    }
}
