///*------------------------------------
//  Utilities: Ratio
//  ------------------------------------*/

////
/// @group -Library-
////

///
/// Calculates a percentage for use as a ratio
///

@use "sass:math";

@mixin u-ratio(
    $width: 16,
    $height: 9,
    $property: 'padding-bottom',
    $includeAspectRatio: true
) {
    #{$property}: (calc(100 / $width) * $height) * 1%;

    @if $includeAspectRatio {
        @supports(aspect-ratio: 16 / 9) {
            #{$property}: 0;
            aspect-ratio: #{$width} / #{$height};
        }
    }
}
