.form-dark__text-input { 
    $this: '.form-dark__text-input';
    $inputHeight: size(74px);
    $textYShift: size(7px);
    $paddingTop: size(10px);
    $paddingBottom: size(19px);
    $iconSize: size(20px);
    $paddingX: spacing(4);
    $borderWidth: 2px;

    display: flex;
    flex-direction: column;
    position: relative;

    &__control,
    &__label {
        cursor: text;
        padding: 0 $paddingX;
    }

    &__control {
        @include form__typography-control;
        background-color: $colour-white;
        border: $borderWidth solid $colour-white;
        color: $form__active-colour;
        padding-bottom: $form__padding-bottom;
        font-style: inherit;

        line-height: $inputHeight - ($paddingTop + $paddingBottom);
        min-height: $inputHeight;
        padding-top: $paddingTop;
        padding-bottom: $paddingBottom;
        transition: padding 0.2s $easeInQuad, border-color 0.15s $easeInQuad;
        will-change: padding;

        &::placeholder {
            color: $form__active-colour;
        }

        &:focus {
            border-color: $colour-grey-mid-dark;
            outline: none;
        }

        &:placeholder-shown ~ #{$this}__label {
            opacity: 0;
            transition: opacity 0.15s ease;
        }

        &:not(:placeholder-shown) {
            transition: all 0.4s $easeOutQuad;
            padding-top: $paddingTop + $textYShift;
            padding-bottom: $paddingBottom - $textYShift;

            ~ #{$this}__label {
                opacity: 1;
                transition: opacity 0.3s ease;
            }
        }
    }
    
    &__label {
        @include form-v2__typography-label;
        color: $colour-grey-xdark;
        order: -1;
        position: absolute;
        top: size(12px);
        left: 0;
        user-select: none;
    }
    
    &:hover &__label,
    &:focus-within &__label {        
        color: $form__inactive-colour;
    }
    
    &:focus-within &__control {        
        border-color: $colour-black;
    }
    
    &__control#{$form__error-class} {
        border-color: $form__error-colour;
    }
    &__control#{$form__error-class} ~ &__label {
        color: $form__error-colour;
    }

    #{$form__error-message-class} {
        @include form-v2__error-label;
        padding: 0 size($borderWidth) + $paddingX;
    }
}