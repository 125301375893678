.home-showcase {
    align-items: end;
    column-gap: var(--column-gap);
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    // Collapse all column gaps on mobile only
    @include respond-upto($media-query-smallmedium) {
        --column-gap: 0;
    }
    @include respond-from($media-query-smallmedium) {
        --column-gap: #{$column-gutter-default * 2};
    }

    &__rte {
        padding-bottom: spacing(10);
        
        @include respond-upto($site__tablet-breakpoint) {
            grid-column: 1 / -1;
        }
        @include respond-between($site__tablet-breakpoint, $site__desktop-breakpoint) {
            grid-column: 1 / span 6;
        }
        @include respond-from($site__desktop-breakpoint) {
            grid-column: 2 / span 3;
            margin-bottom: auto;
        }
    }
    
    &__item {
        position: relative;

        &::before {
            content: '';
            background-color: rgba($colour-black, 0.3);
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
        }

        
        &__image {
            &__inner {

            }
        }
        
        &__label {
            @include alternate-default-font;
            @include column-gutters;
            color: $colour-white;
            position: absolute;
            bottom: spacing(1);
            left: 0;
            right: 0;
            z-index: 1;
            
            @include respond-upto($media-query-small) {
                @include font-size($font-size-xxxsmall);
            }
            @include respond-between($media-query-small, $media-query-smallmedium) {
                @include font-size($font-size-xxsmall);
            }
            @include respond-between($media-query-smallmedium, $site__desktop-breakpoint) {
                @include font-size($font-size-medium);
            }
            @include respond-from($site__desktop-breakpoint) {
                @include font-size($font-size-large);                
            }
        }

        &--small {
            grid-row-end: span 1;            
            
            @include respond-upto($site__desktop-breakpoint) {
                grid-column-end: span 6;

                &:nth-child(even) {
                    margin-right: calc(-0.5 * var(--column-gap));
                }
                &:nth-child(odd) {
                    margin-left: calc(-0.5 * var(--column-gap));
                }
            }
            @include respond-from($site__desktop-breakpoint) {
                grid-column-end: span 3;
                
                &:nth-child(even) {
                    margin-left: calc(-0.5 * var(--column-gap));
                    margin-right: calc(-0.25 * var(--column-gap));
                }
                &:nth-child(odd) {
                    margin-left: calc(-0.75 * var(--column-gap));
                }
            }
        }

        &--large {
            @include respond-upto($site__desktop-breakpoint) {
                grid-column: 1 / -1;
            }

            @include respond-from($site__desktop-breakpoint) {
                &:nth-child(2) {
                    margin-left: calc(-0.5 * var(--column-gap));
                    grid-column-end: -1;
                    grid-column-start: span 6;
                }
                &:nth-child(3) {
                    margin-right: calc(-0.5 * var(--column-gap));
                    grid-row: 2 / span 2;
                    grid-column-end: span 6;
                }
            }
        }

        &--link {
            &::after {
                content: '';
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 418 247.8' style='enable-background:new 0 0 418 247.8;' xml:space='preserve'%3E%3Cpath style='fill: rgba(0,0,0,0.7);' d='M418,208.4L0,0v247.8h418V208.4z'/%3E%3C/svg%3E");
                background-size: cover;
                background-position: left top;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                transition: opacity 0.4s $easeInOutSine;
            }
    
            &:hover::after {
                opacity: 1;
            }
        }
    }
}
