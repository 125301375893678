.development-intro {
    $this: '.development-intro';

    @include o-button-theme($buttonSelector: #{$this}__cta);
    
    @include respond-from($media-query-mediumlarge) {
        column-gap: var(--column-gap);
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }

    &__intro {
        @include light-font;
        @include font-size($font-size-large);
        grid-column: 1 / span 8;
        max-width: 55ch;

        &:not(:last-child) {
            margin-bottom: spacing(10);
        }
    }

    &__contact {
        @include respond-from($media-query-mediumlarge) {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            grid-column: span 4 / -1;
        }
    }

    &__contact-details {
        @include font-size($font-size-xsmall);
        display: grid;
        grid-template-columns: repeat(2, minmax(0, max-content));
        column-gap: spacing(6);

        &__type,
        &__value {
            max-width: 50ch;
        }

        &__type {
            @include default-font;
            grid-column: 1;
            &::after {
                content: ':';
            }
        }
        &__value {
            @include bold-font;
            grid-column: 2;
        }
    }

    &__cta {
        @include o-button-v2--red;
        margin-left: auto;

        &:not(:first-child) {
            margin-top: spacing(8);
        }
    }
}