// [Settings,][Tools,][Generic,][Base,][Objects,][Utilities,]
@import '../library/_tools.sizing';
@import './settings.media-queries';

////
/// @group -Project-
////

//*------------------------------------
//  @const Page Constants
//  ------------------------------------*/

/// Standard column padding <br>
/// Total Column Gutter = (2 * $column-gutter-default)
$column-gutter-default: size(18px) !default;

$site__tablet-breakpoint: $media-query-medium;
$site__desktop-breakpoint: $media-query-large;

$property-summary__promotion-overhang: spacing(2);
$container-width: size(1800px, $unit-em);

@mixin container($mobileGutter: $column-gutter-default, $desktopGutter: $column-gutter-default * 4, $applyPadding: true) {
    
    @if $mobileGutter {
        @include respond-upto($site__desktop-breakpoint) {
            @if $applyPadding {
                @include column-gutter('padding', 'left', $mobileGutter);
                @include column-gutter('padding', 'right', $mobileGutter);
            }
            --container-gutter-margin: #{$mobileGutter};
            --container-gutter-width: #{$mobileGutter};
        }
    }
    @include respond-between($site__desktop-breakpoint, $container-width) {
        @if $applyPadding {
            @include column-gutter('padding', 'left', $desktopGutter);
            @include column-gutter('padding', 'right', $desktopGutter);
        }
        --container-gutter-margin: #{$desktopGutter};
        --container-gutter-width: #{$desktopGutter};
    }
  
    @include respond-from($container-width) {
        $padding-calc: calc(
            ((100vw - #{$container-width}) / 2) + #{$desktopGutter}
        );

        --container-gutter-margin: #{$desktopGutter};
        --container-gutter-width: #{$padding-calc};

        @if $applyPadding {
            padding-left: $padding-calc;
            padding-right: $padding-calc;
        }
    }
}