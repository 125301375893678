.hidden {
    display: none!important;
}

/* this is added to the body when a dialog is open */
.has-dialog {
    @include u-clip-to-viewport;
}

@mixin dialog__backdrop($responsiveBackground: true) {
    display: none;
    position: fixed;
    overflow-y: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    place-items: center;

    &.active {
        display: grid;
    }

    @if $responsiveBackground {
        @include respond-from($media-query-small) {
            background: rgba($colour-black, 0.3);
        }
    }
    @else {
        background: rgba($colour-black, 0.3);
    }
}

@mixin dialog__close {
    @include light-font;
    align-items: center;
    display: flex;
    margin-left: auto;

    @include respond-upto($site__desktop-breakpoint) {
        @include font-size($font-size-xxsmall);
        &__svg {
            transform: rotate(45deg);
            height: size(18px);
            margin-right: spacing(2);
            width: size(18px);
        }
    }
    @include respond-from($site__desktop-breakpoint) {
        @include font-size($font-size-xsmall);
        &__svg {
            transform: rotate(45deg);
            height: size(22px);
            margin-right: spacing(4);
            width: size(22px);
        }
    }
}