:root {
    --page-nav-v2-height: #{spacing(16)};
}
.page-nav-v2 {
    $this: '.page-nav-v2';
    
    background-color: $colour-black;
    color: $colour-white;
    position: sticky;
    top: 0;
    overflow: hidden;
    transform: none;
    transition: transform 0.2s $easeOutQuad;
    z-index: 2;

    // For JS
    --mobile-width: #{map-get($breakpoints, $site__desktop-breakpoint)};

    @include respond-from($site__desktop-breakpoint) {
        height: var(--page-nav-v2-height);
    }
    
    &__mobile-toggle {
        @include container;
        @include default-font;
        @include font-size($font-size-xsmall);
        letter-spacing: 0.07em;
        text-decoration: none;
        text-transform: uppercase;
        color: $colour-white;
        height: var(--page-nav-v2-height);

        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-xsmall);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-medium);
        }

        @include respond-from($site__desktop-breakpoint) {
            display: none;
        }

        svg {
            height: spacing(3);
            margin-left: spacing(2);
            stroke: currentColor;
            stroke-width: 2px;
            width: spacing(3);
        }
    }
    
    &__content {
        @include container;
        align-items: baseline;
        display: flex;
        padding-top: spacing(5);
        padding-bottom: spacing(5);
        
        @include respond-upto($site__desktop-breakpoint) {
            background-color: $colour-grey-xxxdark;
            gap: $column-gutter-default * 2;
            flex-direction: column;
            overflow-y: auto;
        }
        @include respond-from($site__desktop-breakpoint) {
            gap: 5%;
        }

        @include is-not-touch {
            pointer-events: none;
    
            > * {
                pointer-events: auto;
            }
    
            &:hover #{$this}__internal-link:not(:hover):not(.is-active) {
                color: $colour-grey-dark;
            }
        }
    }
    
    &__internal-link {
        @include default-font;
        @include font-size($font-size-xsmall);
        letter-spacing: 0.07em;
        text-decoration: none;
        text-transform: uppercase;

        @include is-not-touch {
            transition: color 0.3s $easeInOutQuart;

            &:hover {
                transition-timing-function: $easeOutQuart;
            }
        }

        @include respond-from($site__desktop-breakpoint) {
            @include u-ellipsis;
        }

        &.is-active {
            color: $colour-red;
        }
    }

    &.is-open {
        @include respond-upto($site__desktop-breakpoint) {            
            #{$this} {
                &__mobile-toggle svg {
                    transform: rotate(180deg);
                }
                &__content {
                    height: calc((var(--vh, 1vh) * 100) - var(--page-nav-v2-height));
                }
            }
        }
    }

    &:not(.is-open) {
        #{$this} {
            &__content {
                @include respond-upto($site__desktop-breakpoint) {
                    display: none;
                }
            }
        }
    }

    &.is-sticky {
        .has-dialog & { 
            transform: translate3d(0, calc(-1 * var(--page-nav-v2-height)), 0);
        }
    }
}
