$animation-package-prefix: 'animate';

@keyframes #{$animation-package-prefix}-fade-in {
    from {
        opacity: 0;
    }
    to { 
        opacity: 1;
    }
}
@keyframes #{$animation-package-prefix}-fade-in-up {
    from {
        opacity: 0;
        transform: translateY(var(--#{$animation-package-prefix}-translate-y, 1.875rem));
    }
    to {
        opacity: 1;
    }
}

@mixin animate-build-animation(
    $name, 
    $duration: 0.5s, 
    $timing-function: ease-out,
    $delay: 0s,
    $iteration-count: 1,
    $direction: normal,
    $fill-mode: forwards,
    $play-state: paused,
) {
    animation: #{$animation-package-prefix}-#{$name} var(--#{$animation-package-prefix}-duration, #{$duration}) var(--#{$animation-package-prefix}-timing-function, #{$timing-function}) calc(var(--#{$animation-package-prefix}-external-delay, 0s) + var(--#{$animation-package-prefix}-delay, #{$delay})) var(--#{$animation-package-prefix}-iteration-count, #{$iteration-count}) var(--#{$animation-package-prefix}-direction, #{$direction}) var(--#{$animation-package-prefix}-fill-mode, #{$fill-mode}) var(--#{$animation-package-prefix}-play-state, #{$play-state});
}

.#{$animation-package-prefix} {
    &-fade-in {
        @include animate-build-animation('fade-in');
    }
    &-fade-in-up {
        @include animate-build-animation('fade-in-up');
    }
}

.#{$animation-package-prefix} {
    &[data-#{$animation-package-prefix}^="fade-"],
    [data-#{$animation-package-prefix}^="fade-"] {
        opacity: 0;
    }
}