@function get-notch($corner: 'top-left') {
    $path: '';
    @if ($corner == 'top-left') {
        $path: polygon(var(--notch-width) 0, 100% 0, 100% 100%, 0 100%, 0 var(--notch-height));
    }
    @else if ($corner == 'top-right') {
        $path: polygon(0 0, calc(100% - var(--notch-width)) 0, 100% var(--notch-height), 100% 100%, 0 100%);
    }
    @else if ($corner == 'bottom-right') {
        $path: polygon(0 0, 100% 0, 100% calc(100% - var(--notch-height)), calc(100% - var(--notch-width)) 100%, 0 100%);
    }
    @else if ($corner == 'top-right-bottom-left') {
        $path: polygon(0 0, calc(100% - var(--notch-width)) 0, 100% var(--notch-height), 100% 100%, var(--notch-width) 100%, 0 calc(100% - var(--notch-height)));
    }
    @return $path;
}
@mixin o-notched-corner($corner: 'top-left', $width: false, $height: false, $ratio-width: 145, $ratio-height: 70) {

    @if ($width and $height) {
        --notch-height: #{$height};
        --notch-width: #{$width};
    }
    @else if $width {
        --notch-height: calc(#{($ratio-height / $ratio-width)} * #{$width});
        --notch-width: #{$width};
    }
    @else if $height {
        --notch-width: calc(#{calc($ratio-width / $ratio-height)} * #{$height});
        --notch-height: #{$height};
    }
    @else {
        --notch-height: #{size($ratio-height)};
        --notch-width: #{size($ratio-width)};
    }

    clip-path: get-notch($corner: $corner);   
}