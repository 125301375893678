.modal-list {
    &__modals {
        z-index: 11;
    }

    .dialog-backdrop {
        @include dialog__backdrop;
    }
    
    &__modal {
        display: none;
        width: 100%;

        .active & {
            display: flex;
            justify-content: center;
        }
    }
}