// [Settings,][Tools,][Generic,][Base,][Objects,][Utilities,]
@import '../projectConfig/_settings.colours';
@import '../projectConfig/_settings.typography';
@import '../projectConfig/_tools.spacing';
@import '../projectConfig/_settings.spacing-scale';
@import '../library/_tools.typography';



/// Default button border radius.
/// To override within a project simply redefine the variable
///
/// @access private
/// @name Button Border Radius
$button-border-radius: 0 !default;


/// Default full width button text align.
/// To override within a project simply redefine the variable
///
/// @access private
/// @name Button Full Text Align
$button-full-text-align: center !default;



///*------------------------------------
// Button style properties
// ------------------------------------*/
// Button defaults
$button-text-align: center !default;
$button-text-decoration: none !default;

$button-mobile-padding: spacing(1) spacing(2);
$button-default-padding: spacing(2) spacing(5);

$button-v2-mobile-padding: spacing(3) spacing(2) spacing(3) spacing(3);
$button-v2-default-padding: spacing(4) spacing(3) spacing(4) spacing(4);

@mixin button-typography {
  @include light-font;
  @include respond-upto($site__desktop-breakpoint) {
    @include font-size($font-size-xsmall);
  }
  @include respond-from($site__desktop-breakpoint) {
    @include font-size($font-size-medium);
  }
}
@mixin button-typography-v2 {
  @include default-font;
  @include respond-upto($site__desktop-breakpoint) {
    @include font-size($font-size-xsmall);
  }
  @include respond-from($site__desktop-breakpoint) {
    @include font-size($font-size-small);
  }
}

@mixin button-v2-svg {
  svg {
    align-self: baseline;
    height: var(--line-height-em);
    flex-shrink: 0;
    margin-left: spacing(3);
    width: var(--line-height-em);
  }
}

@mixin button-v2-hover-effect {
  @include is-not-touch {
    position: relative;
    overflow: hidden;
    --translate-x: 0;

    > * {
      isolation: isolate;
      z-index: 1;
    }
    &::before {
      background-color: $colour-red;
      content: '';
      position: absolute;
      left:100%;
      top: 0;
      bottom: 0;
      transform: skew(315deg) translateX(var(--translate-x));
      width: 200%;
      transform-origin: left bottom;
      transition: transform 0.3s ease;
    }
    &:hover:not(:disabled) {
      --translate-x: -100%;
      &::before {

      }
    }
  }
}

///
///
///
$button-style-default: (
  'bgnd-colour': $colour-red,
  'border-colour': $colour-red,
  'border-radius': $button-border-radius,
  'border-style': solid,
  'border-width': 1px,
  'label-colour': $colour-white,
  'padding': $button-default-padding,
  'padding-mobile': $button-mobile-padding,

  'hover-bgnd-colour': $colour-white,
  'hover-border-colour': $colour-red,
  'hover-border-style': solid,
  'hover-border-width': 1px,
  'hover-label-colour': $colour-red,
) !default;

///
///
///
$button-style-black: (
  'bgnd-colour': $colour-black,
  'border-colour': $colour-black,
  'border-radius': $button-border-radius,
  'border-style': solid,
  'border-width': 1px,
  'label-colour': $colour-white,
  'padding': $button-default-padding,
  'padding-mobile': $button-mobile-padding,

  'hover-bgnd-colour': $colour-white,
  'hover-border-colour': $colour-black,
  'hover-border-style': solid,
  'hover-border-width': 1px,
  'hover-label-colour': $colour-black,
) !default;

///
///
///
$button-v2-style-black: (
  'bgnd-colour': $colour-black,
  'border-colour': $colour-black,
  'border-radius': $button-border-radius,
  'border-style': solid,
  'border-width': 0,
  'label-colour': $colour-white,
  'padding': $button-v2-default-padding,
  'padding-mobile': $button-v2-mobile-padding,

  // 'hover-bgnd-colour': $colour-black,
  // 'hover-border-colour': $colour-black,
  // 'hover-border-style': solid,
  'hover-border-width': 0,
  // 'hover-label-colour': $colour-white,

  // 'hover-bgnd-colour': $colour-white,
  // 'hover-border-colour': $colour-black,
  // 'hover-border-style': solid,
  // 'hover-border-width': 0,
  // 'hover-label-colour': $colour-black,
) !default;

///
///
///
$button-v2-style-red: (
  'bgnd-colour': $colour-red,
  'border-colour': $colour-red,
  'border-radius': $button-border-radius,
  'border-style': solid,
  'border-width': 0,
  'label-colour': $colour-white,
  'padding': $button-v2-default-padding,
  'padding-mobile': $button-v2-mobile-padding,

  // 'hover-bgnd-colour': $colour-red,
  // 'hover-border-colour': $colour-red,
  // 'hover-border-style': solid,
  'hover-border-width': 0,
  // 'hover-label-colour': $colour-white,

  // 'hover-bgnd-colour': $colour-white,
  // 'hover-border-colour': $colour-red,
  // 'hover-border-style': solid,
  // 'hover-border-width': 0,
  // 'hover-label-colour': $colour-red,
) !default;

///
///
///
$button-v2-style-orange: (
  'bgnd-colour': $colour-orange,
  'border-colour': $colour-orange,
  'border-radius': $button-border-radius,
  'border-style': solid,
  'border-width': 0,
  'label-colour': $colour-black,
  'padding': $button-v2-default-padding,
  'padding-mobile': $button-v2-mobile-padding,

  // 'hover-bgnd-colour': $colour-red,
  // 'hover-border-colour': $colour-red,
  // 'hover-border-style': solid,
  'hover-border-width': 0,
  // 'hover-label-colour': $colour-white,

  // 'hover-bgnd-colour': $colour-white,
  // 'hover-border-colour': $colour-red,
  // 'hover-border-style': solid,
  // 'hover-border-width': 0,
  // 'hover-label-colour': $colour-red,
) !default;

///
///
///
$button-v2-style-white: (
  'bgnd-colour': $colour-white,
  'border-colour': $colour-white,
  'border-radius': $button-border-radius,
  'border-style': solid,
  'border-width': 0,
  'label-colour': $colour-black,
  'padding': $button-v2-default-padding,
  'padding-mobile': $button-v2-mobile-padding,

  // 'hover-bgnd-colour': $colour-white,
  // 'hover-border-colour': $colour-white,
  // 'hover-border-style': solid,
  'hover-border-width': 0,
  // 'hover-label-colour': $colour-black,

  // 'hover-bgnd-colour': $colour-black,
  // 'hover-border-colour': $colour-black,
  // 'hover-border-style': solid,
  // 'hover-border-width': 0,
  // 'hover-label-colour': $colour-white,
) !default;

///
///
///
$button-style-naked: (
  'bgnd-colour': $colour-transparent,
  'border-colour': $colour-transparent,
  'border-radius': 0,
  'border-style': none,
  'border-width': 0,
  'label-colour': $colour-red,
  'padding': $button-default-padding,
  'padding-mobile': $button-mobile-padding,

  'hover-bgnd-colour': $colour-transparent,
  'hover-border-colour': $colour-transparent,
  'hover-border-style': none,
  'hover-border-width': 0,
  'hover-label-colour': $colour-red,
) !default;

///
///
///
$button-style-ghost-default: (
  'bgnd-colour': $colour-transparent,
  'border-colour': $colour-red,
  'border-radius': $button-border-radius,
  'border-style': solid,
  'border-width': 1px,
  'label-colour': $colour-red,
  'padding': $button-default-padding,
  'padding-mobile': $button-mobile-padding,

  'hover-bgnd-colour': $colour-red,
  'hover-border-colour': $colour-red,
  'hover-border-style': solid,
  'hover-border-width': 1px,
  'hover-label-colour': $colour-white,
) !default;

///
///
///
$button-style-ghost-black: (
  'bgnd-colour': $colour-transparent,
  'border-colour': $colour-black,
  'border-radius': $button-border-radius,
  'border-style': solid,
  'border-width': 1px,
  'label-colour': $colour-black,
  'padding': $button-default-padding,
  'padding-mobile': $button-mobile-padding,

  'hover-bgnd-colour': $colour-black,
  'hover-border-colour': $colour-black,
  'hover-border-style': solid,
  'hover-border-width': 1px,
  'hover-label-colour': $colour-white,
) !default;


///
///
///
$button-style-ghost-white: (
  'bgnd-colour': $colour-transparent,
  'border-colour': $colour-white,
  'border-radius': $button-border-radius,
  'border-style': solid,
  'border-width': 1px,
  'label-colour': $colour-white,
  'padding': $button-default-padding,
  'padding-mobile': $button-mobile-padding,

  'hover-bgnd-colour': $colour-white,
  'hover-border-colour': $colour-white,
  'hover-border-style': solid,
  'hover-border-width': 1px,
  'hover-label-colour': $colour-grey-xxxdark,
) !default;
