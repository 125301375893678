@mixin rte-content-base {
    --underline-colour: #{$colour-red};
    --text-black: #{$colour-grey-xxdark};
    --text-white: #{$colour-grey-light};

    @include default-font;
    font-size: 1.125rem;
    line-height: 1.7777778;
    color: var(--text-colour, --text-black);
    max-width: 72ch;
}

@mixin rte-content-styles($this: '') {
    a {
        color: $colour-grey-xxxdark;
        text-decoration: underline;
        @include bold-font;
    }

    strong {
        color: $colour-grey-xxxdark;
        @include bold-font;
    }

    ol[type="A"] {
        --list-counter-style: upper-alpha;
    }

    ol[type="a"] {
        --list-counter-style: lower-alpha;
    }

    ol[type="I"] {
        --list-counter-style: upper-roman;
    }

    ol[type="i"] {
        --list-counter-style: lower-roman;
    }

    ol[type="1"] {
        --list-counter-style: decimal;
    }

    ol>li {
        position: relative;
        padding-left: 1.75em;
    }

    ol>li::before {
        content: counter(list-item, var(--list-counter-style, decimal)) ".";
        position: absolute;
        font-weight: 400;
        color: $colour-grey-xxdark;
        left: 0;
    }

    ul>li {
        position: relative;
        padding-left: 1.75em;
    }

    ul>li::before {
        content: "-";
        position: absolute;
        color: $colour-grey-xxdark;
        top: 0;
        left: 0.25em;
    }

    hr {
        border-color: $colour-grey-mid-light;
        border-top-width: 1px;
        margin-top: 3em;
        margin-bottom: 3em;
    }

    /**umb_name:Blockquote*/
    blockquote {
        @include bold-font;
        font-style: italic;
        color: $colour-grey-xxxdark;
        border-left-width: 0.25rem;
        border-left-color: $colour-grey-mid-light;
        quotes: "\201C""\201D""\2018""\2019";
        margin-top: 1.6em;
        margin-bottom: 1.6em;
        padding-left: 1em;
    }

    blockquote p:first-of-type::before {
        content: open-quote;
    }

    blockquote p:last-of-type::after {
        content: close-quote;
    }

    /**umb_name:Heading 1*/
    h1 {
        color: $colour-grey-xxxdark;
        @include alternate-medium-font;
        font-size: 2.25em;
        margin-top: 0;
        margin-bottom: 0.8888889em;
        line-height: 1.1111111;
    }

    /**umb_name:Heading 2*/
    h2 {
        color: $colour-grey-xxxdark;
        @include alternate-medium-font;
        font-size: 1.5em;
        margin-top: 2em;
        margin-bottom: 1em;
        line-height: 1.3333333;
    }

    /**umb_name:Heading 3*/
    h3 {
        color: $colour-grey-xxxdark;
        @include alternate-default-font;
        font-size: 1.25em;
        margin-top: 1.6em;
        margin-bottom: 0.6em;
        line-height: 1.6;
    }

    h4 {
        color: $colour-grey-xxxdark;
        @include alternate-default-font;
        margin-top: 1.5em;
        margin-bottom: 0.5em;
        line-height: 1.5;
    }

    figure figcaption {
        color: $colour-grey-xdark;
        font-size: 0.875em;
        line-height: 1.4285714;
        margin-top: 0.8571429em;
    }

    /**umb_name:Code*/
    code {
        color: $colour-grey-xxxdark;
        @include bold-font;
        font-size: 0.875em;
    }

    code::before {
        content: "`";
    }

    code::after {
        content: "`";
    }

    a code {
        color: $colour-grey-xxxdark;
    }

    /**umb_name:Preformatted*/
    pre {
        color: $colour-grey-light;
        background-color: $colour-grey-xxxdark;
        overflow-x: auto;
        font-size: 0.875em;
        line-height: 1.7142857;
        margin-top: 1.7142857em;
        margin-bottom: 1.7142857em;
        border-radius: 0.375rem;
        padding-top: 0.8571429em;
        padding-right: 1.1428571em;
        padding-bottom: 0.8571429em;
        padding-left: 1.1428571em;
    }

    pre code {
        background-color: transparent;
        border-width: 0;
        border-radius: 0;
        padding: 0;
        font-weight: 400;
        color: inherit;
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
    }

    pre code::before {
        content: none;
    }

    pre code::after {
        content: none;
    }

    table {
        border-collapse: collapse;
        width: 100%;
        table-layout: auto;
        text-align: left;
        margin-top: 2em;
        margin-bottom: 2em;
        font-size: 0.875em;
        line-height: 1.7142857;
    }

    thead {
        color: $colour-grey-xxxdark;
        @include bold-font;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: $colour-grey-mid;
    }

    thead th {
        vertical-align: bottom;
        padding-right: 0.5714286em;
        padding-bottom: 0.5714286em;
        padding-left: 0.5714286em;
    }

    tbody tr {
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: $colour-grey-mid-light;
    }

    tbody tr:last-child {
        border-bottom-style: solid;
        border-bottom-width: 0;
    }

    tbody td {
        vertical-align: top;
        padding-top: 0.5714286em;
        padding-right: 0.5714286em;
        padding-bottom: 0.5714286em;
        padding-left: 0.5714286em;
    }

    p {
        margin-top: 1.25em;
        margin-bottom: 1.25em;
    }

    img {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    video {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    /**umb_name:Figure*/
    figure {
        margin-top: 2em;
        margin-bottom: 2em;
    }

    figure>* {
        margin-top: 0;
        margin-bottom: 0;
    }

    h2 code {
        font-size: 0.875em;
    }

    h3 code {
        font-size: 0.9em;
    }

    ol {
        margin-top: 1.25em;
        margin-bottom: 1.25em;
    }

    ul {
        margin-top: 1.25em;
        margin-bottom: 1.25em;
    }

    li {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    #{$this}>ul>li p {
        margin-top: 0.75em;
        margin-bottom: 0.75em;
    }

    #{$this}>ul>li>*:first-child {
        margin-top: 1.25em;
    }

    #{$this}>ul>li>*:last-child {
        margin-bottom: 1.25em;
    }

    #{$this}>ol>li>*:first-child {
        margin-top: 1.25em;
    }

    #{$this}>ol>li>*:last-child {
        margin-bottom: 1.25em;
    }

    ul ul,
    ul ol,
    ol ul,
    ol ol {
        margin-top: 0.75em;
        margin-bottom: 0.75em;
    }

    hr+* {
        margin-top: 0;
    }

    h2+* {
        margin-top: 0;
    }

    h3+* {
        margin-top: 0;
    }

    h4+* {
        margin-top: 0;
    }

    thead th:first-child {
        padding-left: 0;
    }

    thead th:last-child {
        padding-right: 0;
    }

    tbody td:first-child {
        padding-left: 0;
    }

    tbody td:last-child {
        padding-right: 0;
    }

    #{$this}> :first-child {
        margin-top: 0;
    }

    #{$this}> :last-child {
        margin-bottom: 0;
    }
}