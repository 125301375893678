.pagination {
  $this: '.pagination';
  isolation: isolate;
  display: inline-flex;
  border-radius: 2px;

  &__next,
  &__previous {
    padding: spacing(2);
    svg {
      height: spacing(6);
      width: spacing(6);
    }

    &[href] {
      color: $colour-grey-xxxdark;
    }
  }

  &__previous,
  &__next,
  &__number {
    @include light-font;
    @include font-size($font-size-xxsmall);
    align-items: center;
    background-color: $colour-white;
    color: $colour-grey-xdark;
    display: inline-flex;
    position: relative;
    
    text-decoration: none;
    
    &:not(.is-active):not(#{$this}__number--dots):hover {
      background-color: $colour-grey-light;
    }
  }

  &__number {
  }

  &__previous {
    svg {
      transform: rotate(180deg);
    }
  }

  &__number {
    padding: spacing(2) spacing(4);
    &--first {
  
    }
    &--dots {
      @include respond-upto($media-query-xsmall) {
        display: none;
      }
    }
    &--last {
  
    }

    &--first,
    &--last {
      @include respond-upto($media-query-small) {
        display: none;
      }
    }

    &.is-active {
      background-color: $colour-grey-mid-light;
      color: $colour-grey-xxxdark;
      z-index: 1;
    }
    &:not(.is-active):not(#{$this}__number--dots):hover {
      color: $colour-grey-xxdark;
    }
  }

  &__next {

  }

  &__visually-hide {
    @include u-visually-hide;
  }
}
