.property-detail {

    @include respond-upto($site__desktop-breakpoint) {
        --property-detail-nav-height: #{size(40px)};
    }
    @include respond-from($site__desktop-breakpoint) {
        --property-detail-nav-height: 0px;
    }

    &__nav {
        @include container;
        background-color: $colour-white;
        column-gap: $column-gutter-default;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(size(50px), max-content));
        height: var(--property-detail-nav-height);
        margin-bottom: spacing(5);
        padding-top: spacing(2);
        padding-bottom: spacing(2);
        position: sticky;
        top: var(--site-header-height);
        left: 0;
        right: 0;
        transition: transform 0.3s linear;
        z-index: 2;

        &::before {
            border-top: 1px solid $colour-grey-xdark;
            content: '';
            position: absolute;
            left: var(--container-gutter-width, 0);
            right: var(--container-gutter-width, 0);
            top: 0;
        }
    
        .site-header-hide & {
            transform: translateY(calc(-1 * var(--site-header-height)));
        }
        
        @include respond-from($site__desktop-breakpoint) {
            display: none;
        }
        
        &__link {
            @include default-font;
            @include font-size($font-size-xxsmall);
            text-decoration: none;            
        }
    }

    &__header {
        @include container;
        @include o-scroll-margin-top(calc(var(--site-header-height) + var(--property-detail-nav-height)));
        position: relative;
        
        // Delegate padding to children, so not to intefere with scroll-margin-top fallback
        @include respond-from($site__desktop-breakpoint) {
            .property-detail-enquire,
            &__main {
                margin-top: spacing(12);
                margin-bottom: spacing(32);
            }
        }

        &__main {
            display: grid;
            
            @include respond-upto($site__desktop-breakpoint) {
                grid-template-columns: 1fr;
                
                > *:nth-child(2) {
                    order: -1;
                }
            }
            @include respond-from($site__desktop-breakpoint) {    
                grid-template-columns: repeat(2, minmax(0, 1fr));

                > *:nth-child(1) {
                    grid-column-end: span 2;
                    margin-bottom: spacing(12);
                }
            }
        }
        
        &.is-enquire-open .property-detail__header__main {
            display: none;
        }
    }

    &__content {
        @include container;
        
        @include respond-upto($site__desktop-breakpoint) {
            padding-bottom: spacing(32);
            
            &__text,
            &__nearby {
                margin-top: spacing(16);
                
                &:last-child {
                    margin-bottom: spacing(48);
                }
            }
            &__graphic {
                display: none;
            }
        }

        @include respond-from($site__desktop-breakpoint) {
            align-items: baseline;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: $column-gutter-default * 2;
            row-gap: spacing(8);
            position: relative;
            
            &__text,
            &__nearby {
                grid-row: 1;
            }

            &__text {
                grid-column-start: 2;
            }

            &__graphic {
                grid-column-start: 1;
                grid-row: 2;
                max-width: size(550px);
                pointer-events: none;
                user-select: none;
                width: 60%;
            }
        }
    }
}