﻿@import '_tools.typography';

//*------------------------------------
//  Generic typography resets
//  ------------------------------------*/

h1,
h2,
h3,
h4,
h5,
p {
    margin-bottom: 0;
    margin-top: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

strong {
    @include bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}
