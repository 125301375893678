.form__file-input { 
    display: flex;
    flex-direction: column;   
    
    &__control {
        @include u-visually-hide;
    }
    
    &__label {
        @include form__typography-label;
        color: $colour-black;
        order: -1;
    }

    &__faux {
        @include form__typography-control;
        align-items: baseline;
        border-bottom: 1px solid $form__inactive-colour;
        display: flex;
        order: -1;
        padding-bottom: $form__padding-bottom;
        justify-content: space-between;

        &__placeholder,
        &__selected-files {
            margin-right: $column-gutter-default;
        }
        
        &__placeholder {
            color: $form__inactive-colour;
        }
        &__selected-files {
            @include u-ellipsis;
            
            span:not(:last-child)::after {
                content: ', ';
            }

        }
        &__button {
            @include o-button--ghost-black($defaultTypography: false);
            align-items: center;
            flex-direction: row-reverse;
            
            svg {
                stroke: currentColor;
                height: size(16px);
                margin-left: 1ch;
                transform: scaleY(-1);
                width: size(16px);
            }
        }
    }
    
    &:hover &__label,
    &:focus-within &__label {        
        color: $form__inactive-colour;
    }
    
    &:hover &__control,
    &:focus-within &__control {        
        border-color: $colour-black;
    }
    
    &__control#{$form__error-class} ~ &__faux {
        border-color: $form__error-colour;
    }
    &__control#{$form__error-class} ~ &__label {
        color: $form__error-colour;
    }

    #{$form__error-message-class} {
        @include form__error-label;
    }

    &.is-populated &__faux__placeholder,
    &:not(.is-populated) &__faux__selected-files {
        display: none;
    }
}