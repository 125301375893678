.quote {
    $this: '.quote';
    $background-element-overhang: spacing(12);

    display: flex;
    flex-direction: column;
    max-width: size(1110px);
    margin: 0 auto;
    
    &__main {
        margin: $background-element-overhang;
        margin-right: 0;
        position: relative;
        isolation: isolate;
        
        @include respond-upto($site__tablet-breakpoint) {
            padding: spacing(6) 0 spacing(5) spacing(5);
            margin-left: 0;
        }
        
        @include respond-from($site__tablet-breakpoint) {
            display: flex;
            padding: spacing(12) spacing(12) spacing(8);
            gap: var(--column-gap);
        }
        
        &::before {
            background-color: $colour-red;
            content: '';
            min-height: calc(100% + #{$background-element-overhang * 2});
            top: -$background-element-overhang;
            position: absolute;
            z-index: -1;

            @include respond-upto($media-query-small) {
                left: calc(-1 * var(--container-gutter-width));
                width: 100%;
            }
            @include respond-from($media-query-small) {
                left: -$background-element-overhang;
            }
            @include respond-between($media-query-small, $site__tablet-breakpoint) {
                width: calc(65% + #{$background-element-overhang});
            }
            @include respond-upto($site__tablet-breakpoint) {
                @include o-notched-corner('top-right', $height: spacing(20));
            }
            @include respond-from($site__tablet-breakpoint) {
                @include o-notched-corner('top-right', $height: 18%);
                @supports(aspect-ratio: 16 / 9) {
                    aspect-ratio: 1 / 1;
                }
                @supports not(aspect-ratio: 16 / 9) {
                    height: 100%;
                    width: 50%;
                }
            }
        }

        &::after {            
            background-color: $colour-grey-new-2;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;            
            z-index: -1;
            
            @include respond-upto($site__tablet-breakpoint) {
                right: calc(-1 * var(--container-gutter-width));
            }
            @include respond-from($media-query-small) {
                @include o-notched-corner('top-right', $height: spacing(20));
            }
            @include respond-from($site__tablet-breakpoint) {
                right: 0;
            }
        }
    }

    &__quote-text {
        @include alternate-medium-font;
        @include respond-upto($media-query-small) {
            @include font-size($font-size-xsmall);
            margin-bottom: spacing(6)
        }
        @include respond-between($media-query-small, $site__desktop-breakpoint) {
            @include font-size($font-size-xlarge);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xxlarge);
        }

        p {
            @include respond-from($media-query-smallmedium) {
                margin-left: 1.85em;
            }
            max-width: 28ch;
        }

        svg {
            display: block;
            fill: rgba($colour-black, 0.1);
            height: 1.7em;
            width: 1.85em;

            &:first-of-type {
                margin-bottom: spacing(2);
            }
            &:last-of-type {
                margin-left: auto;
                transform: rotate(180deg);
            }
        }
    }

    &__citation {        
        $logo-overhang: spacing(6);

        align-content: start;
        display: grid;
        gap: spacing(3);
        isolation: isolate;
        width: size(210px) + $logo-overhang;

        @include respond-from($site__tablet-breakpoint) {
            order: -1;
            flex-shrink: 0;
        }
        &__image,
        &__logo {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
        }
        &__image, 
        &__text {
            margin-right: $logo-overhang;
        }
        &__image {
        }
        &__logo {
            @include u-ratio(1, 1);
            position: relative;
            
            svg {
                position: absolute;
                top: $logo-overhang;
                left: $logo-overhang;
                height: calc(100% - #{$logo-overhang});
                fill: rgba($colour-white, 0.25);
                width: calc(100% - #{$logo-overhang});
                z-index: -1;
            }
        }
        &__text {
            @include default-font;
        }
        &__name {
            @include respond-upto($site__desktop-breakpoint) {
                @include font-size($font-size-medium);
            }
            @include respond-from($site__desktop-breakpoint) {
                @include font-size($font-size-large);
            }
        }
        &__meta {
            &__line {
                @include font-size($font-size-xsmall);
                display: block;
            }
        }
    }

    &__cta {
        @include o-button-v2--black;
        margin-left: auto;
        margin-top: $background-element-overhang * -0.5;
        z-index: 1;
    }
}