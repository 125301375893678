.twitter-feed {
    @include backend-preview-padding;
    align-items: start;
    display: grid;
    grid-template-columns: 1fr max-content;

    &__logo {
        fill: $colour-red;
        height: size(25px);
        width: size(30px);
    }

    &__profile-link {
        @include respond-upto($site__desktop-breakpoint) {
            margin-bottom: spacing(10);
        }
        @include respond-from($site__desktop-breakpoint) {
            margin-bottom: size(50px);
        }
        
        &__label {
            @include u-visually-hide;
        }
    }

    &__tweets {
        display: contents;

        .splide {
            &__track {
                grid-column: 1 / -1;
            }

            &__list {
                @include respond-from($site__desktop-breakpoint) {
                    width: 100%;
                }
            }

            &__arrows {
                margin-left: auto;
                display: flex;
                justify-content: space-between;
                pointer-events: none;
                
                &.is-arrows-disabled {
                    display: none;
                }

                @include respond-from($site__desktop-breakpoint) {
                    display: none;
                }
            }

            &__arrow {            
                align-items: center;
                padding: spacing(2);
                position: relative;
                pointer-events: auto;
                
                &--prev {
                    --degrees: 90deg;
                }
                &--next {
                    --degrees: -90deg;
                }
        
                svg {
                    height: spacing(4);
                    stroke-width: 2px;
                    stroke: $colour-grey-xdark;
                    transition: all 0.3s ease-in-out;
                    transform: rotate(var(--degrees));
                    opacity: 1;
                    width: spacing(4);
                }
    
                &[disabled] {
                    cursor: default;
                    
                    svg {
                        opacity: 0.3;
                    }
                }

                @include is-not-touch {
                    &:not([disabled]):hover {
                        svg {
                            stroke: currentColor;
                        }
                    }
                }
            }
        }

        &__tweet {
            @include light-font;

            @include respond-upto($site__desktop-breakpoint) {
                @include font-size($font-size-xxsmall);
            }
            @include respond-from($site__desktop-breakpoint) {
                @include font-size($font-size-small);

                &:not(:first-child) {
                    border-top: 1px solid $colour-grey-xdark;
                    margin-top: spacing(10);
                    padding-top: spacing(6);
                }

                &:not(:nth-child(-n + 2)) {
                    display: none;
                }
            }
        }
    }

    &--theme {
        &-white {
            color: $colour-black;
        }
        &-black {
            color: $colour-white;

            @include backend-specific {
                background-color: $colour-black!important;
            }
        }
    }
}