@mixin o-support-title-animation($isMultiLine: false, $lineCharLength: 20, $isMultiLineAutoAlignment: true) {
    @if $isMultiLine {        
        span {
            display: block;
            max-width: $lineCharLength * 1ch;
            transform: translateX(calc(var(--translate-x-distance, 0) * (1 - (var(--percent, 0) / 100))));
            transition: transform 0.5s $easeOutQuad;

            @include reduced-motion {
                --translate-x-distance: 0!important;
            }

            @if $isMultiLineAutoAlignment {
                &:nth-of-type(odd) {
                    text-align: left;
                }
                &:nth-of-type(even) {
                    text-align: right;
                }
            }
        }
    }
    @else {
        transform: translateX(calc(var(--translate-x-distance, 0) * (1 - (var(--percent, 0) / 100))));
        transition: transform 0.5s $easeOutQuad;
        
        @include reduced-motion {
            --translate-x-distance: 0!important;
        }
    }
}