.form__textarea { 
    display: flex;
    flex-direction: column;   
    
    &__control {
        @include form__typography-control;
        background: none;
        border-bottom: 1px solid $form__inactive-colour;
        padding-bottom: $form__padding-bottom;
        min-height: calc(var(--line-height) * 2em);
        font-style: inherit;

        &::placeholder {
            color: $form__inactive-colour;
        }

        &:focus {
            outline: none;
        }
    }
    
    &__label {
        @include form__typography-label;
        color: $colour-black;
        order: -1;
    }
    
    &:hover &__label,
    &:focus-within &__label {        
        color: $form__inactive-colour;
    }
    
    &:hover &__control,
    &:focus-within &__control {        
        border-color: $colour-black;
    }
    
    &__control#{$form__error-class} {
        border-color: $form__error-colour;
    }
    &__control#{$form__error-class} ~ &__label {
        color: $form__error-colour;
    }

    #{$form__error-message-class} {
        @include form__error-label;
    }
}