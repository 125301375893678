.article-detail {
    color: $colour-black;

    &__content {
        @include container;
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        row-gap: spacing(10);

        @include respond-upto($media-query-medium) {
            padding-top: spacing(6);
            padding-bottom: spacing(8);
        }

        @include respond-from($media-query-medium) {
            column-gap: #{$column-gutter-default * 2};
            padding-top: spacing(20);
            padding-bottom: spacing(24);
        }

        &__main {
            max-width: 100%;
            justify-self: center;

            @include respond-upto($media-query-mediumlarge) {
                grid-column: 1 / -1;
            }

            @include respond-from($media-query-mediumlarge) {
                grid-column: span 6 / span 6;
            }
        }

        &__aside {
            @include respond-upto($media-query-mediumlarge) {
                justify-self: center;
                max-width: size(650px);
                width: 100%;
                grid-column: 1 / -1;
            }

            @include respond-from($media-query-mediumlarge) {
                align-self: start;
                grid-column: span 3 / span 3;
            }
        }
    }
}