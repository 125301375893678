.grid-form {
    background: $colour-grey-new-0;
    padding: size(30px);
    &__form {
        &.is-loading {
            .form__submit {
                &::before {
                    @include o-loading-spinner($centreHorizontally: false, $centreVertically: false);
                    margin-right: 0.5em;
                    margin-right: 0.75ch;
                }
            }
        }
    }
}