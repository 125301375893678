.icon-list {
    $this: '.icon-list';
    
    &__items {
        column-gap: $column-gutter-default * 2;
        display: grid;
        margin-top: spacing(12);
        row-gap: spacing(16);

        @include respond-between($media-query-smallmedium,$media-query-mediumlarge) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include respond-from($media-query-mediumlarge) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        &__feature {
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: spacing(8);
            text-align: center;

            &__icon {
                width: spacing(32);
            }
            &__text {
                display: flex;
                flex-direction: column;
                gap: 1em;
            }
            &__title {
                @include bold-font;
                @include font-size($font-size-small);
            }
            &__bodytext {
                @include default-font;
                @include font-size($font-size-small);

                &:not(:last-child) {
                    margin-bottom: var(--line-height-em);
                }
            }
        }
    }
}
