/*------------------------------------
  #GENERIC : BOX-SIZING
  ------------------------------------*/
///
/// Set the global `box-sizing` state to `border-box`.
///
/// @link https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice
/// @link https://paulirish.com/2012/box-sizing-border-box-ftw
///
html {
    box-sizing: border-box;
}

* {
    &,
    &:before,
    &:after {
        box-sizing: inherit;
    }
}
