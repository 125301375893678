.development-list {
    $this: '.development-list';
    color: $colour-white;
    
    @include respond-from($site__desktop-breakpoint) {
        display: grid;
        grid-column-gap: $column-gutter-default * 2;
        grid-template-columns: repeat(2, 1fr);
    }

    &__content {
        @include respond-from($site__tablet-breakpoint) {
            display: grid;
            grid-auto-rows: max-content;
            grid-column-gap: $column-gutter-default * 2;
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__map {
        min-height: size(500px);
        height: calc((var(--vh, 1vh) * 66));

        @include respond-upto($site__desktop-breakpoint) {
            display: none;
        }
    }

    &__content {
        border-top: 1px solid $colour-grey-xdark;
    }

    &__checkbox-radio-list {
        background-color: rgba($colour-black, 0.4);
        backface-visibility: hidden;
        color: inherit;
        margin-bottom: spacing(20);
        position: relative;
        transition: all 0.3s linear;
        
        @include respond-upto($site__desktop-breakpoint) {
            position: sticky;
            transform: translate3d(0, 0, 1);
            will-change: top;
            z-index: 2; 
                   
            .site-header-hide & {        
                top: 0;
            }
            .site-header-show & {        
                top: var(--site-header-height);
            }
        }
        
        @include respond-from($site__tablet-breakpoint) {
            grid-column: 1 / -1;
            width: calc(50% - #{$column-gutter-default});
        }
        
        &__selected {
            @include alternate-default-font;            
            cursor: pointer;
            padding-top: spacing(4);
            padding-bottom: spacing(4);

            @include respond-upto($site__desktop-breakpoint) {
                @include font-size($font-size-xlarge);
            }
            @include respond-from($site__desktop-breakpoint) {
                @include font-size($font-size-xxlarge);
            }
        }

        &__legend {
            @include u-visually-hide;

            svg {
                display: none;
            }
        }

        &__selected {
            align-items: center;
            display: flex;
            width: 100%;

            svg {
                flex-shrink: 0;
                margin-left: auto;
                float: right;
                stroke: currentColor;
                stroke-width: 0.5px;                
                height: size(42px);
                width: size(42px);
            }
        }

        &__elements {
            z-index: 1;
            
            background-color: $colour-black;
            visibility: hidden;
            max-height: 0;
            overflow: hidden;
            padding-left: inherit;
            padding-right: inherit;
            position: absolute;
            transition: max-height 0.3s $easeInOutQuart, visibility 0s linear 0.3s;
            top: 100%;
            left: 0;
            right: 0;

            &__legend {
                display: none;
            }
        }
        &.is-open {
            background-color: rgba($colour-black, 1);
            border-color: $colour-red;

            #{$this}__checkbox-radio-list {
                &__selected {
                    color: $colour-red;
                }
                &__elements {                        
                    max-height: var(--height);
                    visibility: visible;
                    transition: max-height 0.3s $easeInOutQuart, border-bottom 0.3s $easeInOutQuart;
                }
            }

        }

        @include respond-upto($site__desktop-breakpoint) {
            &.is-open {
                #{$this}__checkbox-radio-list {
                    &__legend {
                        @include default-font;
                        
                        svg {
                            stroke: $colour-red;
                        }
                    }
                }
            }
        }
        @include respond-from($site__desktop-breakpoint) {
            &.is-open {
                #{$this}__checkbox-radio-list {                    
                    &__selected {
                        color: $colour-red;

                        svg {
                            stroke: $colour-red;
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }        
    }

    &__checkbox-radio--radio {
        align-items: center;
        border-bottom: 1px solid $colour-grey-mid-dark;
        display: flex;
        padding-bottom: spacing(2);        

        #{$this}__checkbox-radio {
            &__control {
                @include u-visually-hide;
            }
            &__label {
                @include alternate-default-font;
                @include font-size($font-size-medium);
                margin-right: spacing(3);
            }            
        }

        &:not(:last-child) {
            margin-bottom: spacing(2);
        }

        &:hover {
            #{$this}__checkbox-radio {
                &__label {
                    // color: $colour-black;
                }
            }
        }
    }

    &__property {
        @include alternate-default-font;
        border-top: 1px solid $colour-grey-xdark;
        display: block;
        opacity: 1;
        overflow: hidden;
        padding: spacing(4) 0 spacing(12);
        position: relative;
        text-decoration: none;
        transition: all 0.4s $easeInOutSine;
        z-index: 0;

        &::after {
            background-color: rgba($colour-black, 1);
            content: '';
            opacity: 1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: -1;
            transition: opacity 0.4s $easeInOutSine;
        }

        &__title {
            display: flex;
            align-items: baseline;
            justify-content: space-between;

            @include respond-upto($site__tablet-breakpoint) {
                @include font-size($font-size-medium);
            }
            @include respond-from($site__tablet-breakpoint) {
                @include font-size($font-size-large);
            }
        }

        &__location {
            @include font-size($font-size-xxsmall);
        }

        &__icon-name {}

        &__image {
            img {
                @include u-image-cover;
                z-index: -1;
            }
        }

        &__triangle {
            fill: transparent;
            flex-shrink: 0;
            height: size(10px);
            margin-left: spacing(1);
            stroke: $colour-grey-xdark;
            transition: all 0.4s $easeInOutSine;
            width: size(20px);
        }

        &--select-open {
            opacity: 0.4;
        }

        &:hover,
        &.focus-visible {
            border-color: $colour-red;

            &::after {
                opacity: 0.8;
            }
            
            #{$this}__property {
                &__triangle {
                    fill: $colour-red;
                    stroke: $colour-red;
                }
            }
        }
    }
}