@mixin available-properties__triangle {
    &::before {                  
        background-color: $colour-grey-new-0;
        content: '';
        position: absolute;                    
        z-index: -1;
    }
}
@mixin available-properties__triangle--vertical {
    &::before {               
        @include o-triangle('bottom-left');                    
        aspect-ratio: 1 / 2;
        left: calc(var(--container-gutter-margin) * -1);

        // Unable to get working using usual padding bottom trick: relies on height being set (unlike horizontal triangle), rather than width
        @supports not (aspect-ratio: 1 / 2) {
            padding-bottom: 20%;
            width: 40%;
        }
                    
        @include respond-from($site__tablet-breakpoint) {
            left: (calc(0.35/12)) * 100%;
        }
    }
}
@mixin available-properties__triangle--horizontal {
    &::before {               
        @include o-triangle('bottom-right');
        @include u-ratio(2, 1);
        right: calc(var(--container-gutter-margin) * -1);

        @supports not (aspect-ratio: 1 / 2) {
            width: 80%;
        }

        @include respond-from($site__tablet-breakpoint) {
            right: (calc(0.35/12)) * 100%;
        }
    }
}

.available-properties {
    $this: '.available-properties';

    
    &__cta {
        &:not(:last-child) {
            margin-bottom: spacing(20);
        }
    }

    &--single-section &__cta:last-child {
        margin-top: spacing(20);
    }

    &--single-section,
    &--multiple-sections &__main__section {
        @include available-properties__triangle;
        position: relative;
    }

    &--single-section {
        @include available-properties__triangle--vertical;
        
        &#{$this}--cta {
            &-first {
                padding-bottom: spacing(32);
                &::before {
                    bottom: 0;
                    top: spacing(10);
                }
            }
            &-last {
                padding-top: spacing(32);
                &::before {
                    bottom: spacing(40);
                    top: 0;
                }
            }
        }
    }
    
    &__main {        
        &__section {
            &:not(:only-child) {
                padding-bottom: spacing(24);

                &::before {
                    bottom: 0;
                }

                &:not(:last-child) {
                    margin-bottom: spacing(24);
                }
            }

            &:not(:only-child):nth-child(even) {
                @include available-properties__triangle--vertical;
            }

            &:not(:only-child):nth-child(even) {
                &::before {
                    top: spacing(-20);
                }
            }

            
            &:not(:only-child):nth-child(odd) {
                @include available-properties__triangle--horizontal;
                &::before {
                    top: spacing(40);
                }
            }

            &__inner {
                column-gap: var(--column-gap);
                display: grid;
                grid-template-columns: repeat(12, minmax(0, 1fr));
        
                // For splide arrows
                position: relative;
            }

            &.is-not-first-slide #{$this}__section-tag {
                transform: rotate(180deg)translateY(100%);
                transition: transform 0.5s $easeOutQuad;
            }
        }
    }

    &__section-tag {
        @include o-section-tag($breakpoint: $site__tablet-breakpoint);
        align-self: start;
        grid-column: 1 / span 1;
        transition: transform 0.8s $easeInOutQuad 1s;
        
        @include respond-upto($site__tablet-breakpoint) {
            display: none;
        }
    }

    &__properties {
        column-gap: inherit;
        display: grid;
        grid-template-columns: repeat(11, minmax(0, 1fr));;
        grid-column: 1 / span 11;

        --one-item-breakpoint: $media-query-small;
        --two-item-breakpoint: $media-query-mediumlarge;
        --three-item-breakpoint: $site__desktop-breakpoint;

        @include respond-from($site__tablet-breakpoint) {
            grid-column: 2 / -1;
        }

        &--text &__title {
            @include respond-from($site__tablet-breakpoint) {
                grid-column: 1 / span 3;
            }
        }

        &__title {
            @include alternate-medium-font;
            @include font-size($font-size-xxlarge);
            margin-bottom: spacing(6);
            grid-column: 1 / -1;
        }

        &__text {
            @include default-font;
            @include font-size($font-size-xsmall);
            grid-column: 1 / -1;
            margin-bottom: spacing(10);
            max-width: 50ch;

            @include respond-from($site__tablet-breakpoint) {
                grid-column: 4 / -1;
            }
        }

        .available-properties-slider {
            --column-count: 12;
            grid-column: 1 / span var(--column-count);
            width: 100%;

            @include respond-from($media-query-small) {
                --column-count: 6;
            }

            @include respond-between($media-query-mediumlarge, $media-query-xlarge) {
                --column-count: 4;
            }

            @include respond-from($media-query-xlarge) {
                --column-count: 3;
            }

            &:not(:empty) {
                ~ #{$this}__properties__slider-placeholder {
                    display: none;
                }
            }
        } 

        &__slider-placeholder {
            grid-column: 1 / -1;
            display: grid;
            position: relative;
            margin-right: calc(-1 * var(--container-gutter-margin));
            padding-right: var(--container-gutter-margin);
            isolation: isolate;

            > *,
            &::before {
                grid-column: 1 / -1;
                grid-row: 1 / span 1;
            }

            &::before {
                content: '';
                
                @include respond-upto($media-query-small) {
                    @include u-ratio(410, 230);
                }
                @include respond-between($media-query-small, $media-query-mediumlarge) {
                    @include u-ratio(900, 276);
                }
                @include respond-between( $media-query-mediumlarge, $media-query-xlarge) {
                    @include u-ratio(1570, 320);
                }
                @include respond-from($media-query-xlarge) {
                    @include u-ratio(1640, 228);
                }
            }

            &--no-image {
                background-color: $colour-grey-new-3;
            }

            &--image::after {
                background: linear-gradient(270deg, $colour-black 0%, rgba($colour-black, 0) 95.89%);
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                top: 0;
                z-index: -1;

                @include respond-upto($site__desktop-breakpoint) {
                    left: 0;
                }
                @include respond-from($site__desktop-breakpoint) {
                    left: 30%;
                }
            }

            &__background {
                &__inner {
                    @include u-image-cover;
                    z-index: -1;
                }
            }

            &__text {
                @include alternate-default-font;
                align-self: center;
                color: $colour-white;
                margin-left: auto;
                max-width: 20ch;
                text-align: right;
                padding: 0.75em;
                padding-right: 0;

                @include respond-upto($site__desktop-breakpoint) {
                    @include font-size($font-size-xlarge);
                }
                @include respond-from($site__desktop-breakpoint) {
                    @include font-size($font-size-xxlarge);
                }
            }
        }
    }
}
