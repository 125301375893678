.pricing {
    $this: '.pricing';

    text-align: center;

    &__title {
        @include alternate-medium-font;
        color: $colour-red;
        margin-bottom: calc(var(--line-height-em) * 0.5);

        @include respond-upto($media-query-smallmedium) {
            @include font-size($font-size-large);
        }

        @include respond-between($media-query-smallmedium, $site__desktop-breakpoint) {
            @include font-size($font-size-xlarge);
        }

        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xxlarge);
        }
    }

    &__text {
        @include light-font;
        @include font-size($font-size-large);

        @include respond-upto($site__tablet-breakpoint) {
            @include font-size($font-size-medium);
        }

        p {
            max-width: 65ch;
            margin: auto;

            &:not(:last-child) {
                margin-bottom: var(--line-height-em);
            }
        }
    }

    &__main {
        display: grid;
        flex-grow: 1;
        position: relative;
        isolation: isolate;

        &:not(:first-child) {
            margin-top: spacing(16);
        }

        >* {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
        }
    }

    &__options {
        display: grid;
        gap: $column-gutter-default * 2;
        padding: $column-gutter-default * 2 0;
        margin-top: auto;

        @include respond-from($site__tablet-breakpoint) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    &__option {
        background-color: $colour-grey-new-0;
        display: flex;
        flex-direction: column;

        &__main {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            margin-top: auto;
        }

        &__main,
        &__footer {
            padding: spacing(8);
        }

        &__title {
            @include alternate-medium-font;

            @include respond-upto($site__tablet-breakpoint) {
                @include font-size($font-size-large);
            }

            @include respond-from($site__tablet-breakpoint) {
                @include font-size($font-size-xlarge);
            }
        }

        &__subtitle {
            @include alternate-default-font;

            @include respond-upto($site__tablet-breakpoint) {
                @include font-size($font-size-small);
            }

            @include respond-from($site__tablet-breakpoint) {
                @include font-size($font-size-medium);
            }
        }

        &__features {
            list-style-type: disc;
            padding-left: 1.5em;
            display: flex;
            flex-direction: column;
            gap: spacing(2);
            text-align: left;

            &:not(:first-child) {
                padding-top: spacing(8);
            }

            &:not(:last-child) {
                padding-bottom: spacing(8);
            }

            & ~ #{$this}__option__footer {
                
            }
        }

        &__feature {
            @include default-font;
            @include font-size($font-size-small);
        }

        &__price {
            @include alternate-default-font;
            margin-bottom: spacing(3);

            @include respond-upto($media-query-smallmedium) {
                @include font-size($font-size-medium);
            }
    
            @include respond-from($media-query-smallmedium) {
                @include font-size($font-size-large);
            }
        }

        &__footer {
        }
        
        &__link {
            @include o-button-v2--red;
            @include font-size($font-size-medium);
            margin: 0 auto;
        }
        
        &:last-child:nth-child(odd) {
            grid-column: 1 / -1;
        }
        
        &--features {
            #{$this}__option__footer {
                background-color: $colour-grey-new-1;
            }
        }

        &:not(&--features) {
            #{$this}__option__footer {
                padding-top: 0;
            }
        }
    }

    &__svg {
        right: calc(var(--container-gutter-width) * -1);
        width: calc(100% + (var(--container-gutter-width) * 1.75));
        height: 100%;
        position: absolute;
        top: 0;
        z-index: -1;

        &.is-in-view:not(.is-tab-defocused)>.northern-logo-pattern {
            animation-play-state: running;
        }
    }

    .northern-logo-pattern {
        aspect-ratio: 807.3 / 422.5;
        animation-play-state: paused;
        display: none;
        margin-left: auto;
        max-height: 100%;
        max-width: 100%;

        @include respond-from($media-query-medium) {
            display: block;
        }

        path {
            --filled-colour: #{$colour-black};
            --empty-colour: #{rgba($colour-black, 0)};
            animation-play-state: inherit !important;
            fill: var(--empty-colour);
            stroke: $colour-black;
            stroke-width: 0.23;
            stroke-miterlimit: 10;

            &.is-animating {
                animation: northern-logo-background-fill 8s forwards 1;
            }
        }

        // @include respond-upto($media-query-mediumlarge) {
        //     max-width: calc(100% + #{$column-gutter-default * 2});
        // }

        // @include respond-from($media-query-mediumlarge) {
        //     bottom: size(-100px);
        //     height: calc(100% + #{size(100px)});
        //     margin-left: $column-gutter-default * 3;
        //     max-width: calc(100% + #{$column-gutter-default * 3});
        // }
    }
}