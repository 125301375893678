

.property-detail__modals { 
    /* dialog::backdrop, */
    .dialog-backdrop {
        @include dialog__backdrop;
    }
}

.dialog {
    @include u-fill-viewport(min-height);
    align-items: stretch;
    background-color: $colour-white;
    display: flex;
    flex-direction: column;
    max-width: size($container-width * 0.8);
    padding: spacing(4) spacing(8) spacing(8);
    justify-content: flex-start;
    
    @include respond-from($media-query-small) {
        width: 50%;
        min-width: calc(#{map-get($breakpoints, $media-query-small)} - (#{$column-gutter-default} * 2));  /* == breakpoint - left+right margin */
        min-height: auto;
        box-shadow: 0 19px 38px rgba($colour-black, 0.12), 0 15px 12px rgba($colour-black, 0.22);
    }

    &__header {
        align-items: baseline;
        border-bottom: 1px solid $colour-grey-xdark;
        display: flex;
        justify-content: space-between;
        margin-bottom: spacing(4);
    }
    
    &__close {
        @include dialog__close;
    }

    &__title {
        @include alternate-default-font;
        color: $colour-red;

        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-large);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xxlarge);
        }
    }

    &__image {
        &__inner {
            display: block;
            margin: 0 auto;
            max-height: 82vh;
        }
    }
}