@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

@mixin styleguide-fontsize($size) {
    @include font-size($size);

    &::before {
        @include default-font;
        @include font-size($font-size-xsmall);
        content: 'Font size: #{size(map-deep-get($typographic-scale, 'default', $size, 'font-size'), $unit-px)} Line height: #{map-deep-get($typographic-scale, 'default', $size, 'line-height')}';
        display: inline-block;
        text-transform: capitalize;
        padding: 0.15em 0.4em;
        border-radius: 1000px;
        background-color: $colour-grey-mid;
        margin-bottom: spacing(3);
    }
};

.styleguide {
    --black: #{$colour-black};
    --black-text: '#{$colour-black}';
    --white: #{$colour-white};
    --white-text: '#{$colour-white}';
    --grey-light: #{$colour-grey-light};
    --grey-light-text: '#{$colour-grey-light}';
    --grey-mid-light: #{$colour-grey-mid-light};
    --grey-mid-light-text: '#{$colour-grey-mid-light}';
    --grey-mid: #{$colour-grey-mid};
    --grey-mid-text: '#{$colour-grey-mid}';
    --grey-mid-dark: #{$colour-grey-mid-dark};
    --grey-mid-dark-text: '#{$colour-grey-mid-dark}';
    --grey-dark: #{$colour-grey-dark};
    --grey-dark-text: '#{$colour-grey-dark}';
    --grey-xdark: #{$colour-grey-xdark};
    --grey-xdark-text: '#{$colour-grey-xdark}';
    --grey-xxdark: #{$colour-grey-xxdark};
    --grey-xxdark-text: '#{$colour-grey-xxdark}';
    --grey-xxxdark: #{$colour-grey-xxxdark};
    --grey-xxxdark-text: '#{$colour-grey-xxxdark}';
    --grey-pinkish: #{$colour-grey-pinkish};
    --grey-pinkish-text: '#{$colour-grey-pinkish}';
    --grey-brownish: #{$colour-grey-brownish};
    --grey-brownish-text: '#{$colour-grey-brownish}';
    --red: #{$colour-red};
    --red-text: '#{$colour-red}';

    @include container;
    padding-top: spacing(16);
    padding-bottom: spacing(16);

    span {
        padding: 0.15em 0.4em;
        border-radius: 1000px;
        background-color: $colour-grey-mid;
    }

    h1,h2,h3,h4 {
        @include default-font;
        color: $colour-grey-xxxdark;
        margin-bottom: 0.5em;
    }
    h2 {
        @include font-size($font-size-xxxxlarge);
        text-decoration: underline;
        text-underline-position: under;
    }
    h3 {
        @include bold-font;
        @include font-size($font-size-medium);
    }
    h4 {
        @include font-size($font-size-xsmall);
    }

    &__text {
        font-weight: var(--weight);
    }

    &__font-size {
        margin-bottom: spacing(10);

        div:not(:last-child) {
            margin-bottom: spacing(4);
        }
    }

    &__Avant-Garde {
        @include Avant-Garde;
    }
    &__Neuzeit-Grotesk {
        @include Neuzeit-Grotesk;
    }

    &__xxxsmall {
        @include styleguide-fontsize($font-size-xxxsmall);
        
    }
    &__xxsmall {
        @include styleguide-fontsize($font-size-xxsmall);

    }
    &__xsmall {
        @include styleguide-fontsize($font-size-xsmall);

    }
    &__small {
        @include styleguide-fontsize($font-size-small);

    }
    &__medium {
        @include styleguide-fontsize($font-size-medium);

    }
    &__large {
        @include styleguide-fontsize($font-size-large);

    }
    &__xlarge {
        @include styleguide-fontsize($font-size-xlarge);

    }
    &__xxlarge {
        @include styleguide-fontsize($font-size-xxlarge);

    }
    &__xxxlarge {
        @include styleguide-fontsize($font-size-xxxlarge);

    }
    &__xxxxlarge {
        @include styleguide-fontsize($font-size-xxxxlarge);

    }
    &__xxxxxlarge {
        @include styleguide-fontsize($font-size-xxxxxlarge);

    }

    &__colours {
        display: grid;
        grid-gap: spacing(8);
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }

    &__colour {
        @include default-font;
        @include font-size($font-size-xsmall);
        text-align: center;

        &--new {
            grid-column-start: 1;
        }
        
        &__swatch {
            @include u-ratio(1, 1);
            background-color: var(--colour);
            box-shadow: inset 0px 0px 3px 1px rgba(0,0,0,0.1);
            display: grid;
            place-items: center;
            position: relative;

            &::after {
                position: absolute;
            }
        }

        &:hover &__swatch::after {
            background: inherit;
            background-clip: text;
            color: transparent;
            content: var(--colour-text);
            filter: invert(1) grayscale(1) contrast(9);
        }
    }
}