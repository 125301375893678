.image-and-features {
    $this: '.image-and-features';
    $feature-content-shift: spacing(3);
    $iconSize: size(51px);

    column-gap: var(--column-gap);
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    row-gap: spacing(12);


    &,
    &__svg,
    &__text {
        pointer-events: none!important;
    }

    &,
    &__text {
        > *:not(#{$this}__svg) {
            pointer-events: all;
        }
    }


    &__support-title {
        @include header--support-title;        
        @include o-support-title-animation;

        margin-bottom: spacing(10);
        --translate-x-distance: 20%;
    }
    &__image {
        grid-column: 1 / span 12;

        @include respond-upto($site__tablet-breakpoint) {            
            @include o-notched-corner($corner: 'top-left', $height: size(32px));
        }        
        @include respond-from($site__tablet-breakpoint) {
            @include o-notched-corner($corner: 'top-left', $height: size(64px));
            grid-column: 1 / span 6;
        }
    }
    &__text {

        grid-column: 1 / span 12;
        @include respond-from($site__tablet-breakpoint) {
            grid-column: 8 / span 4;
        }
    }
    &__body-text {
        @include default-font;
        @include font-size($font-size-xsmall);
        margin-bottom: spacing(8);
    }
    &__link {
        @include o-button-v2--black;
    }
    
    &__slider {

        grid-column: 1 / span 12;

        &__slides {
            --gap: #{$column-gutter-default};
            --slider-padding: 0;

            --one-item-breakpoint: 480;
            --two-item-breakpoint: 768;
            --four-item-breakpoint: 1366;

            @include respond-upto($site__tablet-breakpoint) {
                --button-size: #{size(40px)};
                --buttons-margin: #{size(20px)};
            }
    
            @include respond-from($site__tablet-breakpoint) {
                --button-size: #{size(75px)};
                --buttons-margin: #{size(30px)};
            }

            > .splide {                
                &__arrows:not(.is-arrows-disabled) + .splide__track {
                    padding-bottom: calc(var(--button-size) + var(--buttons-margin));
                }
                
                &__track {
                    @include backend-specific {
                        display: grid;
                        grid-template-columns: repeat(4, 1fr);
                    }
                }

                &__arrows.is-arrows-disabled {
                    display: none;
                }

                &__arrows {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                    gap: size(10px);
                    z-index: 3;
                    
                    @include respond-upto($site__desktop-breakpoint) {
                        align-items: flex-end;
                        bottom: 0;
                        position: absolute;
                        right: 0;
                    }

                    @include backend-specific {
                        display: none;
                    }

                    > .splide__arrow {
                        align-items: center;
                        background-color: $colour-black;
                        display: flex;
                        opacity: 1;
                        justify-content: center;
                        transition: opacity 0.3s $easeInOutQuart;
                        z-index: 1;
                        height: var(--button-size);
                        width: var(--button-size);

                        &[disabled] {
                            cursor: default;
                            opacity: 0;
                        }
    
                        &--prev {
                            transform: rotate(90deg);
                        }
                        &--next {
                            transform: rotate(-90deg);
                        }
    
                        svg {
                            stroke: $colour-white;

                            @include respond-upto($site__tablet-breakpoint) {
                                height: size(16px);
                                width: size(16px);
                            }
    
                            @include respond-from($site__tablet-breakpoint) {
                                height: size(22px);
                                width: size(22px);
                            }
                        }
                    }
                }
                &__track {
                    overflow: visible;
                }
            }
        }
    
    }

    &__feature {
        color: $colour-white;
        display: block;
        text-decoration: none;
        
        &__wrapper {
            @include o-notched-corner($corner: 'top-left', $height: size(32px));
            display: grid;
            position: relative;
            min-height: spacing(64);
            transition: transform 0.4s $easeOutQuad;
            z-index: 0;            

            &::after {
                background: $colour-black;
                content: '';
                opacity: 0.5;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
            }
        }

        &__wrapper::after,
        &__label {
            transition: opacity 0.3s $easeOutQuad;
        }

        &__image {
            img {
                @include u-image-cover;
                z-index: -1;
            }
        }

        &__image,
        &__icon,
        &__label {
            grid-row: 1;
            grid-column: 1;
        }

        &__icon {
            align-self: center;
            height: $iconSize;
            margin: 0 auto;
        }
        &__label {
            @include font-size($font-size-xxsmall);
            @include bold-font;
            padding: spacing(4);
            align-self: end;
            height: calc(50% - #{calc($iconSize / 2)});
            text-align: center;
            opacity: 1;
        }        

        @include is-not-touch {
            margin-top: $feature-content-shift;
            &:not(:hover) {
                #{$this}__feature {
                    &__wrapper::after {
                        opacity: 0.2;
                    }
                    &__label {
                        opacity: 0;
                    }
                }
            }
            &:hover {
                #{$this} {
                    &__feature__wrapper {
                        transform: translate3d(0, -#{$feature-content-shift}, 0);
                        transition-timing-function: $easeInOutQuad;
                    }
                }
            }
        }
    }

    .northern-logo-pattern { 
        bottom: size(50px);
        height: 100%;
        left: -92px;
        margin-left: size(38px);
        position: absolute;
        transform: scale(1.5);
        width: 100%;
        z-index: -1;

        @include respond-from($site__tablet-breakpoint) {
            bottom: size(-270px);
            left: 0;
            margin-left: size(150px);
            transform: initial;
        }

        polygon {
            fill: #e7e7e7;
            transform-box: fill-box;
            transition: all 2.5s $easeInOutQuad;
            transition-timing-function: cubic-bezier(1,0,0.86,1);
            transform-origin: center center;

            // Use stroke to effectively increase smaller logos' hit area to match the largest
            stroke-width: calc((var(--index) * 5.8) * 1px);
            stroke: transparent;
            pointer-events: all;
            paint-order: stroke fill;

            &:hover,
            &.is-animating {
                @media (prefers-reduced-motion: no-preference) {
                    fill: $colour-grey-new-2;
                    transform: rotate(var(--rotate));                
                    transition: all 0.4s $easeOutQuad;
                }
            }
        }     
    }
}
