.property-alert-modal {
    $this: '.property-alert-modal';

    --modal-padding-x: #{$column-gutter-default * 2};
    --modal-column-gap: #{$column-gutter-default * 2};

    @include u-fill-viewport(min-height);
    align-items: stretch;
    background-color: $colour-white;
    display: grid;    
    overflow: hidden;
    padding: 0 var(--modal-padding-x);
    position: relative;
    justify-content: flex-start;
    width: 100%;
    
    @include respond-from($media-query-small) {        
        --modal-padding-x: #{$column-gutter-default * 4};
        
        max-width: size(1300px);
        width: 80%;
        min-width: calc(#{map-get($breakpoints, $media-query-small)} - (#{$column-gutter-default} * 2));  /* == breakpoint - left+right margin */
        min-height: auto;
        box-shadow: 0 19px 38px rgba($colour-black, 0.12), 0 15px 12px rgba($colour-black, 0.22);
    }
    @include respond-from($site__desktop-breakpoint) {
        column-gap: var(--modal-column-gap);
        grid-template-columns: repeat(10, minmax(0, 1fr));
    }

    &__content,
    &__form-background,
    &__form,
    &__success {
        @include respond-from($site__desktop-breakpoint) {
            grid-row: 2;
        }
    }

    &__content,
    &__form {
        padding: spacing(4) 0 spacing(8);
    }

    &__form,
    &__form-background::after {
        @include respond-from($site__desktop-breakpoint) {
            grid-column:  span 4 / -1;
        }
    }
    
    &__form-background {
        pointer-events: none;        
        isolation: isolate;
        z-index: 0;
        
        @include respond-from($site__desktop-breakpoint) {
            column-gap: var(--modal-column-gap);
            display: grid;
            grid-column: 1 / -1;
            grid-template-columns: inherit;
            margin-top: size(-50px);
        }

        &::before,
        &::after {
            background-color: $colour-black;
            content: '';
            display: block;
            z-index: -1;
        }

        &::before {            
            @include respond-upto($site__desktop-breakpoint) {
                @include o-triangle('bottom-right');
                @include u-ratio(20,1);
                margin-left: calc(-1 * (var(--modal-padding-x) + 1px));
                margin-right: calc(-1 * (var(--modal-padding-x) + 1px));
                margin-bottom: -1px;
            }
            @include respond-from($site__desktop-breakpoint) {
                @include o-triangle('top-right');
                grid-column: 5 / span 2;            
                margin-right: calc(-1 * (var(--modal-column-gap) + 1px));
            }
        }

        &::after {
            @include respond-upto($site__desktop-breakpoint) {
                position: absolute;
                left: 0;
                right: 0;
                height: 100%;
            }
            @include respond-from($site__desktop-breakpoint) {
                margin-right: calc(-1 * (var(--modal-padding-x) + 1px));
            }
        }
    }

    &__content {
        @include respond-from($site__desktop-breakpoint) {
            align-self: center;
            grid-column: 1 / span 4;
        }
    }

    &__close {
        @include light-font;
        align-items: center;
        margin-top: spacing(4);
        margin-left: auto;
        display: flex;
        grid-column: 1 / -1;
        z-index: 1;
        
        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-xxsmall);
            color: $colour-black;
            &__svg {
                transform: rotate(45deg);
                height: size(18px);
                margin-right: spacing(2);
                width: size(18px);
            }
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xsmall);
            color: $colour-white;
            mix-blend-mode: difference;
            &__svg {
                transform: rotate(45deg);
                height: size(22px);
                margin-right: spacing(4);
                width: size(22px);
            }
        }
    }
    &__image {
        display: block;
        margin-bottom: spacing(10);
        width: 75%;
    }
    &__title {
        @include alternate-medium-font;
        @include font-size($font-size-xlarge);
        margin-bottom: spacing(5);
    }
    &__body-text {
        @include default-font;
        @include font-size($font-size-xsmall);

        p:not(:last-child) {
            margin-bottom: var(--line-height-em);
        }
    }    

    &__success {
        grid-column: 1 / -1;
        visibility: hidden;
        opacity: 0;
        display: grid;
        place-items: center;

        .form {
            &__title {
                @include alternate-medium-font;
                @include font-size($font-size-xxlarge);
                color: $colour-black;
                margin-bottom: spacing(12);
                text-align: center;
            }
            &__success {
                @include default-font;
                @include font-size($font-size-xsmall);
                color: $colour-black;
                text-align: center;
            }
        }
    }
    &__form {                
        isolation: isolate;
        z-index: 1;

        &.is-loading {
            .form__submit {
                svg {
                    display: none;
                }
                &::after {
                    @include o-loading-spinner($centreHorizontally: false, $centreVertically: false);
                    order: -1;
                    margin-right: 0.5em;
                    margin-right: 0.75ch;
                }
            }
        }
    }

    &.loaded {
        #{$this} {
            $contentHideDuration: 1.5s;
            $contentWipeDuration: 1.5s;
            $newContentDuration: 1s;

            &__content,
            &__form {
                pointer-events: none;
                opacity: 0;                
                transition: opacity $contentHideDuration $easeOutQuart, visibility 0s linear $contentHideDuration;
            }
            &__success {
                opacity: 1;
                visibility: visible;
            }

            @include respond-upto($site__desktop-breakpoint) {
                &__content,
                &__form-background,
                &__form {
                    display: none;
                }
                &__form-background {
                    display: none;
                }
            }
            @include respond-from($site__desktop-breakpoint) {
                &__content,
                &__form {
                    visibility: hidden;
                    transition: opacity $contentHideDuration $easeOutQuart, visibility 0s linear $contentHideDuration;
                }                
                &__form-background {
                    transform: translateX(70%);
                    transition: transform $contentWipeDuration $easeOutQuart $contentHideDuration;
                }
                &__success {
                    transition: opacity $newContentDuration $easeOutQuart ($contentHideDuration + $contentWipeDuration), visibility 0s linear ($contentHideDuration + $contentWipeDuration);
                }
            }
        }
    }
}
