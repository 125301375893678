.related-projects {
    $this: '.related-projects';

    &__support-title {
        @include container;
        @include header--support-title;
        @include o-support-title-animation;
        --translate-x-distance: 20%;
        margin-bottom: spacing(24);
    }

    &__projects {

        @include respond-from($site__tablet-breakpoint) {
            display: flex;
            gap: spacing(10);
        }

        &__project {
            position: relative;

            @include respond-from($site__tablet-breakpoint) {
                width: 50%;
            }

            &:first-child {
                @include respond-upto($site__tablet-breakpoint) {
                    margin-bottom: spacing(5);
                }
            }

            &__link {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;

                span {
                    @include u-visually-hide;
                }
            }

            &__image {
                img {
                    width: 100%;
                }
            }

            &__backdrop {
                fill: rgba($colour-black, 0.6);
                min-width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                
                @include respond-upto($media-query-small) {
                    background-color: rgba($colour-black, 0.6);
                    fill: none;
                    min-height: 100%;
                }
                @include respond-from($media-query-small) {
                    @include u-ratio(630, 260);
                }
            }

            &__content {
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                position: absolute;
                bottom:0;
                left: 0;
                width: 100%;

                padding-left: spacing(5);
                padding-bottom: spacing(5);

                color: $colour-white;
            }

            &__project-type {
                flex-shrink: 0;
                height: size(30px);
                width: size(30px);
                
                @include respond-upto($site__desktop-breakpoint) {
                    margin-bottom: spacing(3);
                }
                @include respond-from($site__desktop-breakpoint) {
                    margin-bottom: spacing(5);
                }
            }

            &__project-name,
            &__location {
                @include u-ellipsis;
            }

            &__project-name {
                @include alternate-default-font;

                @include respond-from($media-query-small) {
                    max-width: 52%;
                }
                
                @include respond-upto($site__desktop-breakpoint) {
                    @include font-size($font-size-medium);
                }
                @include respond-from($site__desktop-breakpoint) {
                    @include font-size($font-size-large);
                }
            }
            
            &__location {
                @include default-font;
                @include font-size($font-size-large);
                color: $colour-grey-xdark;
                line-height: size(25px);

                @include respond-from($media-query-small) {
                    max-width: 70%;
                }

                @include respond-upto($site__desktop-breakpoint) {
                    @include font-size($font-size-small);
                }
                @include respond-from($site__desktop-breakpoint) {
                    @include font-size($font-size-large);
                }

            }
        }
    }

    &__cta {
        @include o-button-v2--red;
        margin-left: auto;
        float: right;

        &:not(:first-child) {
            margin-top: spacing(8);
        }
    }
}