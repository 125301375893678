.article-summary {
    box-shadow: $box-shadow;
    
    color: $colour-grey-xxdark;
    overflow: hidden;
    position: relative;
    height: 100%;

    display: flex;
    flex-direction: column;
    
    &__image {
        position: relative;
        flex-shrink: 0;
    }

    &__main {
        padding: spacing(8) spacing(5);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
    
    &__link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        
        span {
            @include u-visually-hide;
        }
    }    
    
    &__title {
        @include alternate-default-font;
        @include u-ellipsis-lines(3);
        margin-bottom: spacing(4);
        color: $colour-grey-xxxdark;
        line-height: 1.25!important;

        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-medium);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-large);
        }
    }
    
    &__teaser-text {
        @include font-size($font-size-xsmall);
        @include default-font;
        @include u-ellipsis-lines(4);
    }    

    &__footer {
        margin-top: auto;
        padding-top: spacing(5)
    }

    &__type,
    &__date,
    &__meta__item {
        @include light-font;
        color: $colour-grey-xdark;

        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-xxxsmall);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xxsmall);
        }
    }

    &__type {
        margin-bottom: spacing(1);
    }
    
    &__date {
        
    }

    &__date,
    &__meta__item {
        display: inline-block;
        &:not(:last-child) {
            &::after {
            content: ' .';
            display: inline-block;
            margin: 0 0.25ch;
            }
        }  
    }

    &__meta {
        display: inline;
        &__item {
            
        }
        &__key {
            @include u-visually-hide;
        }
        &__value {
            display: inline;
        }
    }
}
