@mixin o-triangle($rightAngledCorner: 'top-left') {
    @if ($rightAngledCorner == 'top-left') {
        clip-path: polygon(0 0, 100% 0, 0 100%);
    }
    @else if ($rightAngledCorner == 'top-right') {
        clip-path: polygon(0 0, 100% 0, 100% 100%);
    }
    @else if ($rightAngledCorner == 'bottom-left') {
        clip-path: polygon(0 0, 100% 100%, 0 100%);
    }
    @else if ($rightAngledCorner == 'bottom-right') {
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }
}