.form__checkbox-radio--checkbox { 
    @include property-listing-checkbox($this: '.form__checkbox-radio', $align: 'left', $inactiveLabelColour: $colour-black, $activeLabelColour: $colour-black);
    align-items: baseline;
    display: flex;

    .form__checkbox-radio {
        &__label {
            @include form__typography-control;
            margin-left: spacing(2);
        }

        &__faux {
            order: -1;
        }
    }
}