
// [Settings,][Tools,][Generic,][Base,][Objects,][Utilities,]
@import '../library/_tools.sizing';
@import '../library/_object.buttons';


///////////////////////////////////////
///// Types of button

///
///
///
// Solid buttons
@mixin o-button--default($defaultTypography: 'v1') { @include o-button($button-style: $button-style-default, $defaultTypography: $defaultTypography); }
@mixin o-button--black($defaultTypography: 'v1') { @include o-button($button-style: $button-style-black, $defaultTypography: $defaultTypography); }

// Ghost buttons
@mixin o-button--ghost-default($defaultTypography: 'v1') { @include o-button($button-style: $button-style-ghost-default, $defaultTypography: $defaultTypography); }
@mixin o-button--ghost-white($defaultTypography: 'v1') { @include o-button($button-style: $button-style-ghost-white, $defaultTypography: $defaultTypography); }
@mixin o-button--ghost-black($defaultTypography: 'v1') { @include o-button($button-style: $button-style-ghost-black, $defaultTypography: $defaultTypography); }

// Naked button
@mixin o-button--naked($defaultTypography: 'v1') { @include o-button($button-style: $button-style-naked, $defaultTypography: $defaultTypography); }



// Solid buttons
@mixin o-button-v2--black($defaultTypography: 'v2') { @include o-button($button-style: $button-v2-style-black, $defaultTypography: $defaultTypography); @include button-v2-svg; @include button-v2-hover-effect; }
@mixin o-button-v2--white($defaultTypography: 'v2') { @include o-button($button-style: $button-v2-style-white, $defaultTypography: $defaultTypography); @include button-v2-svg; @include button-v2-hover-effect; }
@mixin o-button-v2--red($defaultTypography: 'v2') { @include o-button($button-style: $button-v2-style-red, $defaultTypography: $defaultTypography); @include button-v2-svg; @include button-v2-hover-effect; }
@mixin o-button-v2--orange($defaultTypography: 'v2') { @include o-button($button-style: $button-v2-style-orange, $defaultTypography: $defaultTypography); @include button-v2-svg; }

// Ghost buttons
@mixin o-button--ghost-default($defaultTypography: 'v1') { @include o-button($button-style: $button-style-ghost-default, $defaultTypography: $defaultTypography); }
@mixin o-button--ghost-white($defaultTypography: 'v1') { @include o-button($button-style: $button-style-ghost-white, $defaultTypography: $defaultTypography); }
@mixin o-button--ghost-black($defaultTypography: 'v1') { @include o-button($button-style: $button-style-ghost-black, $defaultTypography: $defaultTypography); }

// Naked button
@mixin o-button--naked($defaultTypography: 'v1') { @include o-button($button-style: $button-style-naked, $defaultTypography: $defaultTypography); }


@mixin o-button-theme($this: '&', $buttonSelector: '&__button') {
  #{$this}--button-black #{$buttonSelector} {
    @include o-button-v2--black;
  }
  #{$this}--button-white #{$buttonSelector} {
    @include o-button-v2--white;
  }
  #{$this}--button-red #{$buttonSelector} {
    @include o-button-v2--red;
  }
  #{$this}--button-orange #{$buttonSelector} {
    @include o-button-v2--orange;
  }
}

///
///
///
// 100% width
@mixin o-button--full () {
  width: 100%;
  text-align: $button-full-text-align;
}