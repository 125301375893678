.highlighted-team-members {
    $this: '.highlighted-team-members';
    
    &__title {        
        @include o-support-title-animation;
        @include header--support-title;
        --translate-x-distance: -20%;
        margin-bottom: 0.5em;        
        margin-left: auto;
        text-align: right;
    }
    &__members {
        display: grid;
        column-gap: var(--column-gap);
        
        @include respond-upto($site__tablet-breakpoint) {
            align-items: center;
            grid-template-columns: minmax(0, size(420px));
            justify-content: center;
            row-gap: spacing(16);
        }
        
        @include respond-from($site__tablet-breakpoint) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
        
        &__member {
        }
    }
    &__text {
        column-gap: var(--column-gap);
        display: grid;
        row-gap: spacing(8);
        
        @include respond-from($site__tablet-breakpoint) {
            grid-template-columns: repeat(12, minmax(0, 1fr));
        }
    }
    &__body-text {
        @include respond-upto($media-query-mediumlarge) {
            grid-column: 1 / span 8;
        }
        @include respond-between($media-query-mediumlarge, $site__desktop-breakpoint) {
            grid-column: 2 / span 7;
        }
        @include respond-from($site__desktop-breakpoint) {
            grid-column: 3 / span 6;
        }

        p {
            @include default-font;
            @include font-size($font-size-small);
            &:not(:last-child) {
                margin-bottom: var(--line-height-em);
            }
        }
    }
    &__cta {
        @include o-button-v2--red;
        align-self: end;
        grid-column: 9 / -1;
        width: fit-content;
    }

    &--listing {
        #{$this} {
            &__text {
                margin-bottom: spacing(24);
            }
            &__members {
                row-gap: spacing(16);
                
                @include respond-from($site__tablet-breakpoint) {
                    row-gap: spacing(32);
                }
            }
        }
    }

    &--highlighted {
        #{$this} {
            &__text {
                margin-top: spacing(16);
            }
            &__members {
                row-gap: spacing(16);
            }
        }
    }
}
