//*------------------------------------
//  Utility : Ellipsis
//  ------------------------------------*/

////
/// @group -Library-
////

///
/// Makes text add ellipsis on overflow (single line)
///
@mixin u-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

///
/// Makes block of text add ellipsis on overflow (after an amount of lines)
///
@mixin u-ellipsis-lines($lineCount) {
    display: -webkit-box;
    overflow: hidden;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lineCount;
    text-overflow: ellipsis;
}