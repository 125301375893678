.article-aside {
    @include default-font;
    @include font-size($font-size-xsmall);
    color: $colour-grey-new-3;
    display: flex;
    flex-direction: column;
    gap: spacing(5);

    &__back,
    &__social-link {
        transition: color 0.3s $easeOutQuad;

        &:hover,
        &:focus {
            color: $colour-black;
        }
    }

    &__back {
        align-items: center;
        align-self: flex-start;
        display: flex;
        gap: 1ch;
        text-decoration: none;

        svg {
            flex-shrink: 0;
            transform: scaleX(-1);
            height: size(28px);
            width: size(28px);
        }
    }

    &__social-links {
        border-top: 1px solid $colour-grey-new-1;
        display: flex;
        flex-wrap: wrap;
        column-gap: spacing(4);
        row-gap: spacing(2);
        padding-top: spacing(4);

        &__title {
            @include font-size($font-size-xxsmall);
            width: 100%;
        }
    }

    &__social-link {
        width: spacing(5);

        span {
            @include u-visually-hide;
        }
    }
}