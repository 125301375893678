.gallery-grid {
    $this: '.gallery-grid';

    @include respond-from($site__desktop-breakpoint) {
        display: grid;
        grid-template-columns: repeat(12, minmax(0, 1fr));
        column-gap: var(--column-gap);
    }

    &__section-tag {
        @include o-section-tag;
        align-self: start;
    }
    &__support-title {
        @include header--support-title;
        @include o-support-title-animation;
        --translate-x-distance: 20%;
        vertical-align: baseline;
        grid-column: span 11 / -1;
        text-align: center;
        
        @include respond-upto($site__desktop-breakpoint) {
            margin-bottom: spacing(10);            
        }
        @include respond-from($site__desktop-breakpoint) {
            margin-bottom: spacing(24);
        }

        img {
            display: inline-block;
            height: 0.85em;
            transform: translateY(0.04em);
        }
    }

    &__images,
    &__rte {
        max-width: size(1080px);
        margin: 0 auto;
    }

    &__images {
        align-content: center;
        display: grid;
        grid-template-columns: repeat(var(--column-count), minmax(0, 1fr));
        column-gap: var(--column-gap);
        row-gap: var(--column-gap);
        grid-column: 1 / span 8;        
        position: relative;

        @include is-not-touch {
            pointer-events: none;
    
            > * {
                pointer-events: auto;
            }
    
        }
        &:hover:not(.is-image-open) #{$this}__thumbnail-button__image img {
            opacity: 0.8;
        }

        @include respond-upto($site__tablet-breakpoint) {
            --column-count: 2;
        }
        @include respond-from($site__tablet-breakpoint) {
            --column-count: 3;
        }

        &:not(:last-child) {
            @include respond-upto($site__desktop-breakpoint) {
                margin-bottom: spacing(12);
            }
        }
    }

    &__thumbnail-button {
        @include u-reset-button;
        background-color: var(--background-colour);
        overflow: hidden;

        &__label {
            @include u-visually-hide;
        }

        &__image {
            display: block;
        }
        img {
            transition: opacity 0.8s $easeInOutQuart, transform 0.8s $easeInOutSine;
        }

        &:hover img{
            opacity: 1!important;
            transform: scale(1.05);
        }

        &:first-of-type #{$this}__thumbnail-button__image { 
            @include o-notched-corner($corner: 'top-left', $height: spacing(6));
        }

        &:last-of-type #{$this}__thumbnail-button__image { 
            @include o-notched-corner($corner: 'bottom-right', $height: spacing(6));
        }
    }
    
    &__enlarged-image {
        background-color: $colour-grey-xxxdark;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.5s $easeInOutQuart, visibility 0s linear;
        z-index: 1;
        
        &:not(.is-open) {
            opacity: 0;
            transition: opacity 0.3s $easeInOutQuart, visibility 0s linear 0.5s;
            visibility: hidden;

            // Prevent image from pre-loading
            #{$this}__enlarged-image__image {
                display: none;
            }
        }

        &__close {
            align-items: center;
            background-color: $colour-black;
            border-radius: 50%;
            color: $colour-white;
            display: flex;
            padding: spacing(3);
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(50%, -50%);

            span {
                @include u-visually-hide;
            }

            &__svg {
                transform: rotate(45deg);
                height: spacing(4);
                width: spacing(4)
            }
        }

        &__image {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            position: relative;
            overflow: hidden;
            width: 100%;
            z-index: -1;
            
            img {
                @include u-image-cover;
            }
        }
    }

    &__rte {
        align-self: center;
        grid-column: span 4 / -1;

        p {
            max-width: 65ch;
        }
    }

    &__colony {
        filter: brightness(0.3);
        grid-column: 1 / -1;
        z-index: -1;
        margin-left: -4%;
        margin-top: size(-145px);
        width: 45%;
        max-width: size(346px);
    }

    &--colony {
        #{$this} {
        }
    }
}