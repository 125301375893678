// [Settings,][Tools,][Generic,][Base,][Objects,][Utilities,]

// Image cover

@mixin u-image-cover($objectFit: true, $transform: false) { 
  display: block;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 50%;
  @if $transform {
    transform: translate(-50%, -50%)#{$transform};
  }
  @else {
    transform: translate(-50%, -50%);
  }
  pointer-events: none;
  user-select: none;
  width: auto;

  @if $objectFit {
    @supports (object-fit: cover) {
      height: 100%;
      left: 0;
      min-height: auto;
      min-width: auto;
      object-fit: cover;
      @if $transform {
        transform: #{$transform};
      }
      @else {
        transform: none;
      }
      top: 0;
      width: 100%;
    }
  }
}
