// [Settings,][Tools,][Generic,][Base,][Objects,][Utilities,]

////
/// @group -Library-
////

//*------------------------------------
//  Generic: Motion
//  ------------------------------------*/

@mixin reduced-motion {
    @media screen and (prefers-reduced-motion: reduce),
        (update: slow) {
        @content;
    }
}

///
/// Motion preference override, dirty (uses !importants) but means we can set and forget- unlikely to be overriden by a later !important
///
@include reduced-motion {
    * {
        &,
        &::before,
        &::after {
            animation-duration: 0.001ms !important;
            animation-delay: -1ms !important;
            animation-iteration-count: 1 !important;
            background-attachment: initial !important;
            transition-duration: 0.001ms !important;
            transition-delay: 0s !important;
        }
    }
}