.simple-paragraph {
    $this: '.simple-paragraph';

    text-align: center;

    &__title {
        @include alternate-medium-font;
        color: $colour-red;
        margin-bottom: calc(var(--line-height-em) * 0.5);

        @include respond-upto($media-query-smallmedium) {
            @include font-size($font-size-large);
        }

        @include respond-between($media-query-smallmedium, $site__desktop-breakpoint) {
            @include font-size($font-size-xlarge);
        }

        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xxlarge);
        }
    }

    &__text {
        @include light-font;
        @include font-size($font-size-large);

        @include respond-upto($site__tablet-breakpoint) {
            @include font-size($font-size-medium);
        }

        p {
            max-width: 65ch;
            margin: auto;

            &:not(:last-child) {
                margin-bottom: var(--line-height-em);
            }
        }
    }
}