$onward-journeys__journey-content-shift: spacing(5);

.onward-journeys {
    $this: '.onward-journeys';

    @include backend-preview-padding;
    align-items: start;
    display: grid;
    grid-template-columns: 1fr max-content;

    &__title {
        @include alternate-default-font;
        margin-bottom: spacing(8);
        
        @include respond-upto($site__desktop-breakpoint) {
            @include font-size($font-size-medium);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-large);
        }
    }

    &__journeys {
        --one-item-breakpoint: 767;
        --two-item-breakpoint: 1024;
        --three-item-breakpoint: 1550;
        display: contents;

        @include is-not-touch {
            margin-top: -$onward-journeys__journey-content-shift;
        }

        .splide {
            &__track {
                grid-column: 1 / -1;

                @include backend-specific {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                }
            }

            &__arrows {
                margin-left: auto;
                display: flex;
                justify-content: space-between;
                pointer-events: none;
                
                &.is-arrows-disabled {
                    display: none;
                }
            }

            &__arrow {            
                align-items: center;
                padding: spacing(2);
                position: relative;
                pointer-events: auto;
                
                &--prev {
                    --degrees: 90deg;
                }
                &--next {
                    --degrees: -90deg;
                }
        
                svg {
                    height: spacing(4);
                    stroke-width: 2px;
                    stroke: $colour-grey-xdark;
                    transition: all 0.3s ease-in-out;
                    transform: rotate(var(--degrees));
                    opacity: 1;
                    width: spacing(4);
                }
    
                &[disabled] {
                    cursor: default;
                    
                    svg {
                        opacity: 0.3;
                    }
                }

                @include is-not-touch {
                    &:not([disabled]):hover {
                        svg {
                            stroke: currentColor;
                        }
                    }
                }
            }
        }

        &__journey {
            color: $colour-white;
            display: block;
            overflow: hidden;
            position: relative;

            @include backend-specific {
                &:nth-child(n+5) {
                    display: none;
                }
            }
            
            &--no-image {            
                #{$this}__journeys__journey__wrapper {
                    background-color: #1F1F1F;
                    overflow: hidden;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 0;
                }    
                &::before {
                    @include u-ratio(418, 250);
                    content: '';
                    display: block;
                }
            }

            @include is-not-touch {
                padding-top: $onward-journeys__journey-content-shift;

                &__wrapper {
                    position: relative;
                    transition: transform 0.4s $easeOutQuad;
                }
                
                &--no-image &__wrapper {
                    top: $onward-journeys__journey-content-shift;                    
                }

                &:hover {
                    #{$this} {
                        &__journeys__journey__wrapper {
                            transform: translate3d(0, -#{$onward-journeys__journey-content-shift}, 0);
                            transition-timing-function: $easeInOutQuad;
                        }
                    }
                }
            }

            &--image #{$this}__journeys__journey__backdrop {
                fill: rgba($colour-black, 0.6);
                height: calc(100% + 3px);
                width: calc(100% + 3px);
            }
            &--no-image #{$this}__journeys__journey__backdrop {
                fill: transparent;
                stroke: $colour-red;
                left: -1px;
                right: -1px;
                bottom: -1px;
                height: calc(100% + 5px);
                width: calc(100% + 5px);
            }

            &--image #{$this}__journeys__journey__triangle {
                fill: $colour-white;
            }
            &--no-image #{$this}__journeys__journey__triangle {
                fill: $colour-red;
            }

            &__backdrop {
                position: absolute;
                top: 0;
                left: 0;
            }
            
            &__link {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-end;
                padding: size(10px);
                position: absolute;
                text-decoration: none;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
            }

            &__image {
                &__inner {
                    min-height: 1px;
                    width: 100%;
                }
            }

            &__title__text,
            &__subtitle__text {
                @include u-ellipsis;
                display: block;
            }

            &__title {
                @include alternate-default-font;
                @include font-size($font-size-medium);

                &:last-child {
                    line-height: 1;
                }

                &:not(:last-child) {
                    width: 82%;

                    #{$this}__journeys__journey__triangle {
                        display: none;
                    }
                }
            }

            &__title:last-child,
            &__subtitle {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
            }
            
            &__subtitle {
                @include light-font;
                @include font-size($font-size-xsmall);                
            }

            &__triangle {
                fill: $colour-grey-xdark;
                flex-shrink: 0;
                height: size(10px);
                margin-left: size(15px);
                width: size(20px);

                @include respond-upto($site__tablet-breakpoint) {
                    display: none;
                }
            }
        }
    }
    
    &--theme {
        &-white {
            color: $colour-black;
        }
        &-black {
            color: $colour-white;

            @include backend-specific {
                background-color: $colour-black!important;
            }
        }
    }

    &--404 {
        #{$this}__journeys {
            // Never use 3 or 4 item layout
            --one-item-breakpoint: 767;
            --two-item-breakpoint: 9998;
            --three-item-breakpoint: 9999;
        }
    }
}