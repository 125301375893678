.form__select {
    @include respond-upto($site__desktop-breakpoint){
        --iconSize: #{size(14px)};
    }
    @include respond-from($site__desktop-breakpoint) {
        --iconSize: #{size(16px)};
    }

    --horizontalPadding: #{size(8px)};

    display: flex;
    flex-direction: column;
    width: auto;

    &__label {
        @include form__typography-label;
    }
    
    &__input {            
        position: relative;
    }
    
    &__control {
        @include form__typography-control;
        background: none;
        color: $colour-black;
        padding: 0 calc((var(--horizontalPadding) * 3) + var(--iconSize)) $form__padding-bottom 0;
        border-bottom: 1px solid $form__inactive-colour;

        &:active {
            border-color: $form__active-colour;
        }

        &.has-placeholder {
            color: $form__inactive-colour;
        }
    }

    &__option {
        font: inherit;
        color: $form__active-colour;

        // Hide placeholder options
        &[value=""][disabled] {
            display: none;
          }
    }

    &__faux {
        bottom: 0;
        pointer-events: none;
        position: absolute;
        right: var(--horizontalPadding);
        top: 0;
        width: var(--iconSize);

        svg {
            height: 100%;
            stroke: $form__active-colour;
            stroke-width: 1px;
            width: 100%;
        }
    }

    &__errors {
        order: 99;
    }
    
    &:hover &__label,
    &:focus-within &__label {        
        color: $form__inactive-colour;
    }

    &:hover &__control,
    &:focus-within &__control {        
        border-color: $form__active-colour;
        color: $form__active-colour;
    }

    &:focus-within &__faux svg {
        transform: rotate(180deg);
    }

    &__control#{$form__error-class} {
        border-color: $form__error-colour;
    }
    
    &__errors:not(:empty) ~ &__label {
        color: $form__error-colour;
    }

    #{$form__error-message-class} {
        @include form__error-label;
    }
}