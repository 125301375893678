// [Settings,][Tools,][Generic,][Base,][Objects,][Utilities,]
@import '../website/_settings.buttons';
@import '_tools.sizing';


///*------------------------------------
//  #Tools : Buttons
//  ------------------------------------*/


///*------------------------------------
///*------------------------------------
// IMPORTANT: Readme !!
//
// These are core button mixins that are used by the (UI toolkit) buttons and SHOULD NOT be used directly
///*------------------------------------
// ------------------------------------*/


///*------------------------------------
//  @mixin button
//  @description Entry point
//  ------------------------------------*/
@mixin button ($padding: 0,
               $border-style: solid,
               $border-width: 0,
               $hover-border-style: 0,
               $hover-border-width: 0) {
  // If border width > 0 then make a btn-border else create a std button
  @if $border-width > 0 {
    @include button-border($padding, $border-width);
    border: {
      style: $border-style;
      width: $border-width;
    }
  } @else {
    @include button-default($padding);
    border-width: $border-width;
  }

  text-decoration: $button-text-decoration;

  &.focus-visible:not(:disabled),
  &:hover:not(:disabled) {
    // If over border different then re-define
    @if $hover-border-width != $border-width {
      padding: padding-calc($padding, $hover-border-width);
      border-width: $hover-border-width;
    }
    @if $hover-border-style != $border-style {
      border-style: $hover-border-style;
    }
  }

  &:disabled {
    opacity:0.2;
  }
}


///*------------------------------------
//  @mixin button-default
//  ------------------------------------*/
@mixin button-default ($padding: 1em) {
  display: inline-flex;
  padding: $padding;
  text-align: $button-text-align;
}


///**
// * Default button with a border
// * @Description: The button padding is auto corrected (calibrated) to remove the border width so
// * that buttons with & without border are the same size when they have 'natural' (auto) dimensions
// */
@mixin button-border ($padding: 1em, $border-width: 1px) {
  @include button-default(padding-calc($padding, $border-width));
}


///**
// * Update the button padding
// * @Description: Utility mixin for overriding the padding of buttons
// * E.g. if you need to tweak padding on an instance by instance basis
// */
@mixin update-button-padding($padding, $styleSetting) {
  padding: padding-calc($padding, map-get($styleSetting, 'border-width'));

  &.focus-visible:not(:disabled),
  &:hover:not(:disabled) {
    padding: padding-calc($padding, map-get($styleSetting, 'hover-border-width'));
  }
}
