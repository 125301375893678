.related-properties {

    @include respond-from($site__desktop-breakpoint) {
        @include container;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: spacing(48);
        position: relative;
        
        &::before {
            border-top: 1px solid $colour-grey-xdark;
            content: '';
            flex: 1 0 100%;
            margin-bottom: spacing(2);
        }

    }

    &__title,
    &__introduction {
        @include respond-from($site__desktop-breakpoint) {
            align-self: baseline;
        }
    }

    &__title {
        @include alternate-default-font;
        color: $colour-black;

        @include respond-upto($site__desktop-breakpoint) {
            @include column-gutters;
            @include font-size($font-size-large);
        }
        @include respond-from($site__desktop-breakpoint) {
            @include font-size($font-size-xlarge);
        }
    }

    &__introduction {
        @include light-font;
        @include font-size($font-size-xsmall);
        margin-left: spacing(10);

        @include respond-upto($site__desktop-breakpoint) {
            @include u-visually-hide;
        }
    }

    &__properties {
        --slider-padding: #{$column-gutter-default};
        --gap: #{$column-gutter-default * 2};
        --container-breakpoint: #{strip-unit(px-calc($container-width))};
        --one-item-breakpoint: #{strip-unit(px-calc(get-breakpoint($media-query-smallmedium, $breakpoints) - size(1px, 'em')))};
        --two-item-breakpoint: #{strip-unit(px-calc(get-breakpoint($media-query-mediumlarge) - size(1px, 'em')))};
        --three-item-breakpoint: #{strip-unit(px-calc(get-breakpoint($site__desktop-breakpoint) - size(1px, 'em')))};

        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        
        @include respond-from($site__desktop-breakpoint) {
            margin-left: calc(-1 * var(--slider-padding));
            width: calc(100% + (2 * var(--slider-padding)));
        }

        > .splide {
            &__track {
                width: 100%;
            }

            &__arrows {
                margin-left: auto;
                display: flex;
                justify-content: space-between;
                margin-right: var(--slider-padding);
                pointer-events: none;

                &.is-arrows-disabled {
                    display: none;

                    + .splide__track {
                        margin-top: spacing(6);
                    }
                }

                > .splide__arrow {
                    align-items: center;
                    padding: spacing(2);
                    position: relative;
                    pointer-events: auto;
                    
                    &--prev {
                        --degrees: 90deg;
                    }
                    &--next {
                        --degrees: -90deg;
                    }
            
                    svg {
                        height: spacing(4);
                        stroke-width: 2px;
                        stroke: $colour-grey-xdark;
                        transition: all 0.3s ease-in-out;
                        transform: rotate(var(--degrees));
                        opacity: 1;
                        width: spacing(4);
                    }
        
                    &[disabled] {
                        cursor: default;
                        
                        svg {
                            opacity: 0.3;
                        }
                    }
    
                    @include is-not-touch {
                        &:not([disabled]):hover {
                            svg {
                                stroke: currentColor;
                            }
                        }
                    }
                }
            }
        }

        &__property {
            display: flex;
            flex-direction: column;

            @include respond-upto($media-query-smallmedium) {
                width: calc(100vw - (#{$column-gutter-default * 4} + #{size(20px)}))!important;
            }
            @include respond-from($media-query-smallmedium) {
                width: 100%;
            }

            > * {
                display: flex;
                flex-grow: 1;
            }
        }
    }
}