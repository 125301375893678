$breadcrumb__margin-top: 0;

.breadcrumb {
    $this: '.breadcrumb';
    
    &__list {
        display: flex;
        flex-wrap: wrap;
        padding-top: spacing(2);
    }

    &__list__item {
        @include light-font;
        @include font-size($font-size-xsmall);
        &:not(:first-child):before {
            display: inline-block;
            content: '\a0/';
        }
    }
    
    &__link {
        text-decoration: none;        

        &--first {
            
        }
    }

    &--row {
        @include container;    
        margin-top: $breadcrumb__margin-top;
    
        @include respond-upto($site__desktop-breakpoint) {
            @include u-visually-hide;
        }

        #{$this} {
            &__list {
                border-top: 1px solid $colour-grey-xdark;
            }
        }
    }
}