.credit-list {
    $this: '.credit-list';

    &__title {
        @include alternate-medium-font;
        @include font-size($font-size-large);

        margin-bottom: spacing(6);

        @include respond-from($site__tablet-breakpoint) {
            @include font-size($font-size-xxlarge);
        }
    }

    &__credits {
        @include default-font;
        @include font-size($font-size-small);
        columns: 60ch 2;
        column-gap: $column-gutter-default * 2;        
    }

    &__credit {
        break-inside: avoid;
        column-gap: $column-gutter-default * 2;
        display: grid;

        @include respond-upto($media-query-smallmedium) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @include respond-from($media-query-smallmedium) {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }

        &__role {
            @include respond-upto($media-query-smallmedium) {
                grid-column-start: 1;
            }
            @include respond-from($media-query-smallmedium) {
                grid-column: 1 / span 2;
            }
        }

        &__link {
            color: $colour-red;
            text-decoration: underline;
            text-underline-position: under;
            width: fit-content;

            @include respond-upto($media-query-smallmedium) {
                grid-column-start: 2;
            }            
            @include respond-from($media-query-smallmedium) {
                grid-column-start: span 3;                
            }
        }
    }
}