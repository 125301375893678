.property-detail-gallery {
    $this: '.property-detail-gallery';
    position: relative;
    min-width: 100%;
    max-width: 100%;

    @include respond-from($site-header__desktop-breakpoint) {
        @include column-gutter($side: 'right');
        min-height: size(450px);
    }

    .splide {
        &__track {
            @include respond-from($site-header__desktop-breakpoint) {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        &__list {
            min-width: 100%;
            
            @include respond-from($site-header__desktop-breakpoint) {
                height: 100%;
            }
        }

        &__slide {
            @include respond-upto($site-header__desktop-breakpoint) {
                display: grid;
                place-items: center;
            }
            @include respond-from($site-header__desktop-breakpoint) {
                min-height: 100%;
            }
        }

        &__arrows {
            align-items: center;
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            pointer-events: none;        
        }

        &__arrow {
            pointer-events: auto;

            &--prev svg {
                transform: rotate(90deg);
            }
            &--next svg {
                transform: rotate(-90deg);
            }
    
            svg {
                stroke: $colour-white;                
                
                @include respond-upto($site-header__desktop-breakpoint) {
                    stroke-width: 2px;
                    height: spacing(6);
                    width: spacing(6);
                }
                @include respond-from($site-header__desktop-breakpoint) {
                    stroke-width: 0.5px;
                    height: spacing(16);
                    width: spacing(16);
                }
            }

            &[disabled] {
                cursor: default;
                
                svg {
                    opacity: 0;
                }
            }
        }        
    }

    &__promotion-message {
        @include default-font;
        @include font-size($font-size-xxsmall);
        background-color: $colour-red;
        color: $colour-white;
        left: -$property-summary__promotion-overhang;
        padding: spacing(2);
        pointer-events: none;
        position: absolute;
        top: spacing(2);
        z-index: 1;
    }
    
    &__images {
        position: relative;

        @include respond-from($site-header__desktop-breakpoint) {
            height: 100%;
        }
        
        &__video {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            height: 100%;
            width: 100%;   
        }

        &__image {
            @include respond-upto($site-header__desktop-breakpoint) {
                max-height: 100%;
            }
            
            img {
                @include respond-from($site-header__desktop-breakpoint) {
                    @include u-image-cover;
                }
            }
        }
    }

    &__map {
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        
        @include respond-upto($site__desktop-breakpoint) {
            @include container;
            border-top: 1px solid $colour-grey-xdark;
            background-color: $colour-white;
            display: flex;
            flex-direction: column;
            height: calc(calc(var(--vh, 1vh) * 100) - (var(--site-header-height)));
            position: fixed;
            padding-bottom: var(--container-gutter-width);
            top: var(--site-header-height);
        }
        
        @include respond-from($site__desktop-breakpoint) {
            position: absolute;            
            top: 0;
        }
        
        &__close {
            align-items: center;
            display: flex;
            margin-left: auto;            
            
            @include respond-upto($site__desktop-breakpoint){
                @include light-font;
                @include font-size($font-size-xxxsmall);
                padding: spacing(2) 0;
            }
            
            @include respond-from($site__desktop-breakpoint) {
                @include font-size($font-size-xsmall);
                @include default-font;
                position: absolute;
                right: spacing(16);
                top: spacing(4);
                z-index: 1;
            }

            &__svg {
                transform: rotate(45deg);
                height: size(20px);
                margin-right: spacing(3);
                width: size(20px);

                @include respond-upto($site-header__desktop-breakpoint) {
                    display: none;
                }
            }
        }
        
        @include respond-from($site-header__desktop-breakpoint) {
            @include position-to-gutter('right');
        }
    }

    &--promotion {
        &-black {
            #{$this} {
                &__images {
                    &__image {
                        img {
                            filter: grayscale(1);
                        }
                    }
                }
                &__promotion-message {
                    background-color: $colour-black;
                    color: $colour-white;
                }
            }
        }
        &-red { 
            #{$this} {
                &__promotion-message {
                    background-color: $colour-red;
                    color: $colour-white;
                }
            }
        }
    }

    &:not(.is-map-open) &__map,
    &.is-map-open &__images,
    &.is-map-open &__promotion-message {
        visibility: hidden;
    }
}