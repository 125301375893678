//*------------------------------------
//  Utility : Fill viewport
//  ------------------------------------*/

////
/// @group -Library-
////

///
/// Target only iOS to fix 100vh issue (issues caused when the address bar changes between visible/hidden)
///
@mixin u-fill-viewport($property: height) {
    #{$property}: calc((var(--vh, 1vh) * 100));

    @supports(-webkit-touch-callout: none) {
        #{$property}: -webkit-fill-available;
    }
}
