// [Settings,][Tools,][Generic,][Base,][Objects,][Utilities,]

////
/// @group -Project-
////

@mixin form__typography-control {
    @include light-font;
    @include font-size($font-size-xsmall);
}
@mixin form__typography-label {
    @include light-font;
    @include font-size($font-size-xxxsmall);
}
@mixin form__error-label {
    @include light-font;
    @include font-size($font-size-xxxsmall);
    color: $form__error-colour;
    margin-top: spacing(1);
}

@mixin form-v2__typography-control {
    @include default-font;
    @include font-size($font-size-small);
}
@mixin form-v2__typography-label {
    @include default-font;
    @include font-size($font-size-xxxsmall);
}
@mixin form-v2__error-label {
    @include bold-font;
    @include font-size($font-size-xxxsmall);
    color: $form__error-colour;
    margin-top: spacing(2);
}

$form__error-class: '.error';
$form__error-message-class: '.error-message';

$form__padding-bottom: spacing(3);
$form__inactive-colour: $colour-grey-xdark;
$form__active-colour: $colour-black;
$form__error-colour: $colour-red;

@mixin property-listing-checkbox($inactiveColour: $colour-grey-xdark, $inactiveLabelColour: $inactiveColour, $activeLabelColour: $inactiveColour, $borderRadius: 0, $this: '&', $align: 'right') {
    $fauxSize: spacing(4);
    $fauxSizeUnitless: strip-unit(size($fauxSize, $unit-px));

    #{$this}__control {
        @include u-visually-hide;
    }

    #{$this}__faux {
        align-self: baseline;
        align-items: center;
        display: flex;
        flex-shrink: 0;

        @if $align == 'right' {
            margin-left: auto;
        }
        @else if $align == 'left' {
            order: -1;
        }

        &::before {
            background-color: transparent;
            border: 1px solid $inactiveColour;
            content: '';
            height: $fauxSize;
            width: $fauxSize;

            @if $borderRadius != 0 {
                border-radius: $borderRadius;
            }
        }

        &::after {
            content: css-character(zeroWidth);
        }
    }

    #{$this}__label {
        color: $inactiveLabelColour;
    }

    #{$this}__control:checked ~ #{$this}__label {
        @if $inactiveColour != $activeLabelColour {
            color: $activeLabelColour;
        }
    }

    #{$this}__control:checked ~ #{$this}__faux::before {
        $colour: str-replace("" + $colour-red, '#', '%23');
        
        border-color: $colour-red;
        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 #{$fauxSizeUnitless} #{$fauxSizeUnitless}' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3E%3Cline x1='0' y1='#{$fauxSizeUnitless}' x2='#{$fauxSizeUnitless}' y2='0' stroke-width='1' stroke='#{$colour}'/%3E%3Cline x1='0' y1='0' x2='#{$fauxSizeUnitless}' y2='#{$fauxSizeUnitless}' stroke-width='1' stroke='#{$colour}'/%3E%3C/svg%3E");
    }
}